import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DiscountVM } from 'src/app/CommonInterface';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { SharedService } from 'src/app/services/shared.service';
import pdfMake from "pdfmake/build/pdfmake";
import Swal from 'sweetalert2';
import JsBarcode from 'jsbarcode';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplateComponent } from '../email-template/email-template.component';
import { distinctUntilChanged } from 'rxjs';
import { BlobOptions } from 'buffer';
let card: any;

@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.css'],
})
export class SalesOrderComponent implements OnInit {
  DiscountIndex: number = -1;
  start: number = 500;
  searchTerm: string = '';
  loader: boolean = false;
  customerList: any = [];
  SalesOrderListDS: any;
  SalesOrderList: any;
  SalesOrderById: any;
  IsSalesOrderList: boolean = false;
  shipToVal: number = 0;
  IsAddPartFlyOut: boolean = false;
  IsHistoryFlyOut: boolean = false;
  HistoryFlyOutVal: any;
  IsPaymentLogFlyOut: boolean = false;
  companyId: any;
  rolePermissionsList: any;
  permissionMessage = '';
  userId: any;
  StatusList: any = [];
  selectedSearchText: string = 'Customer or Sales Order Number'
  selectedFilter: any = [1, 8, 10, 11];
  BillToDetails: any;
  CompanyWareHouseList: any = [];
  CustomerWithChildList: any = [];
  OrderHistoryList: any = [];
  OrderPartForm: FormGroup;
  BillToForm: FormGroup;
  CustomerForm: FormGroup;
  PaymentForm: FormGroup;
  CCardPaymentForm: FormGroup;
  ShipToForm: FormGroup;
  NetTermsList: any = [];
  PaymentLogList: any = [];
  ShippingTypeList: any = [];
  targetVal: string = 'Summary';
  submitted: boolean = false;
  StateList: any[] = [];
  StateListByCountryId: any[] = [];
  CountryList: any[] = [];
  partList = [];
  _PShipping: boolean = false;
  _PSales: boolean = false;
  _PPayment: boolean = false;
  shiprateListnew: any = [];
  isPay: boolean = false;
  ApprovedamountMoney: any = 0;
  @ViewChild('select') select: MatSelect;
  allSelected = false;
  AddressValidatedAddress: any;
  CompanyDetail: any;
  _IsShippingUpdate: boolean = false;
  base64: string;
  IsValidItem: boolean = true;
  IsShipToItem: boolean = true;
  IsBillToItem: boolean = true;
  messageList: string;
  constructor(
    public sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private constants: ConstantsService,
    public companyService: CompanyService,
    public salesOrderService: SalesOrderService,
    public customerService: CustomerService,
    private globalChangeService: GlobalChangeService,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    public dialog: MatDialog,
  ) {
  }
  async ngOnInit(): Promise<void> {
    this.IsSalesOrderList = localStorage.getItem('IsSalesOrderList') == 'true' ? true : false;
    localStorage.removeItem('IsSalesOrderList');
    this.companyId = localStorage.getItem('GlobalSelectedCompanyId');
    this.Constant();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
        this.Status();
        this.getAllState();
        this.GetCountry();
        this.GetWareHouseWithChild();
        this.GetOrderList(true, false);
        this.GetCompanyById(Number(this.companyId));
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
    }
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      location.reload();
    })
    this.createForm();
    //Card Details
    const payments = window.Square.payments(this.constants.sqAppId, this.constants.sqLocationId);
    card = await this.initializeCard(payments);
    card.attach('#card-container');
    //Card Details End
  }
  async initializeCard(p1: any) {
    return p1.card();
  }
  // All form Creation
  createForm() {
    this.CreateBillToForm();
    this.CreateCustomerForm();
    this.CreateShipToForm();
    this.CreateOrderPartForm();
    this.CreateOrderPaymentForm();
    this.CreditCardPaymentForm();
  }
  ChangePartsStatusList(value: any) {
    this.targetVal = value.target.id;
    this.SubTotal();
  }
  ChangeStatu(value: any) {
    this.GetOrderList(false, false);
  }
  ChangeOrderInfoStatus(status: any) {
    if (status == 8) {
      this.OrderPartForm.patchValue({ holdReason: '', holdUntilDate: new Date(), defQuoteDays: undefined })
    } else if (status == 11) {
      let days = 0;
      if (this.CompanyDetail != undefined && this.CompanyDetail.defQuoteDays != null) {
        days = this.CompanyDetail.defQuoteDays;
      }
      this.OrderPartForm.patchValue({ holdReason: undefined, holdUntilDate: undefined, defQuoteDays: new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000) })
    } else {
      this.OrderPartForm.patchValue({ holdReason: undefined, holdUntilDate: undefined, defQuoteDays: undefined })
    }
  }
  SearchCustomer(value: any) {
    this.loader = false
    this.customerService.GetAllCustomer(this.companyId, value.target.value)
      .subscribe({
        next: (data: any) => {
          this.customerList = data.dataList;
        },
        error: (err) => {
          this.customerList = [];
          this.loader = false
        },
      });
  }
  ChangeSearch() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.select.value.length > 0 ? this.SearchText() : this.selectedSearchText = 'Customer or Sales Order Number';
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.SearchText();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.selectedSearchText = 'Customer or Sales Order Number'
    }
  }
  SearchText() {
    this.selectedSearchText = '';
    this.select.value.forEach((element, index) => {
      this.selectedSearchText = this.selectedSearchText + element + ((index === this.select.value.length - 1) ? '' : ' or ')
    });
  }
  ChangeCustomer(value: any) {
    const BillToDetails = this.customerList.length > 0 ? this.customerList.filter(y => y.customerName.trim().toUpperCase() == value.option.value.toUpperCase())[0] : undefined;
    if (BillToDetails != undefined) {
      this.IsBillToItem = true;
      this.BillToForm.patchValue({
        id: 0,
        name: BillToDetails.billName,
        attention: BillToDetails.billAtten,
        addressLine1: BillToDetails.billAddressLine1,
        addressLine2: BillToDetails.billAddressLine2,
        city: BillToDetails.billCity,
        stateId: BillToDetails.billState,
        countryId: BillToDetails.billCountry,
        zip: BillToDetails.billZipCode,
        phone: BillToDetails.cPhone,
        cName: BillToDetails.cName,
        customerName: BillToDetails.customerName,
        email: BillToDetails.cEmail,
        parentCustomerId: Number(BillToDetails.id),
        customerId: Number(BillToDetails.parentCustomerId),
        companyId: BillToDetails.companyId,
      })
      // if (this.SalesOrderById == undefined) {
      this.OrderPartForm.patchValue({
        netTerms: BillToDetails.netTerms == null || BillToDetails.netTerms == '0' ? this.constants.NetTermsList[0].id : this.constants.NetTermsList.filter(x => x.name == BillToDetails.netTerms)[0].id,
        availableCredit: this.AddCurrency(BillToDetails.creditCardLimit),
        taxable: BillToDetails.taxable == null ? 'Taxable' : BillToDetails.taxable,
        collectAccount: BillToDetails.collectAmount,
        collectAmount: BillToDetails.isCollectAmount ? (BillToDetails.collectAmount == 'Fedex' ? BillToDetails.fedexCollect : BillToDetails.upsCollect) : '',
        isCollectAmount: BillToDetails.isCollectAmount,
        businessChannel: BillToDetails.businessChannels,
        customerRole: BillToDetails.customerRoles,
        customerType: BillToDetails.customerType != null ? Number(BillToDetails.customerType) : 0,
        taxType: BillToDetails.taxable == null ? 1 : 3,
        customerId: Number(BillToDetails.id),
        paymentType: 'NetTerms',
      })
      this.ChangeCollectAccount(this.OrderPartForm.value.collectAccount)
      // }
      this.GetCustomerWithChild(Number(BillToDetails.id), true);
    }
  }
  Status() {
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.StatusList = data.dataList
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  GetCustomerWithChild(CustId: number, IsReset: boolean) {
    this.salesOrderService.GetCustomerWithChild(CustId)
      .subscribe({
        next: (data: any) => {
          this.CustomerWithChildList = data.data;
          if (IsReset) {
            this.ReSetShipToAddress();
          }
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  GetWareHouseWithChild() {
    this.companyService.GetWareHouseWithChild(this.companyId)
      .subscribe({
        next: (data: any) => {
          this.CompanyWareHouseList = data.data;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  ChangeShipTo(value: any) {
    this.IsShipToItem = true;
    this.shipToVal = value;
    this.ReSetShipToAddress();
    if (this.shipToVal == 1) {
      this.ShipToForm.patchValue({
        id: 0,
        parentCustomerId: this.SalesOrderById != undefined ? this.SalesOrderById.salesOrder.customerId : 0,
        countryId: 231,
        companyId: Number(this.companyId),
        shipVal: 1,
      })
      this.GetStateByCountryId(231)
    }
    this.OrderPartForm.patchValue({ isAddressValidate: false })
  }
  ShipToAtten(Atten: string) {
    this.ShipToForm.patchValue({ attention: Atten })
  }
  ReSetShipToAddress() {
    this.ShipToForm.reset();
    this.ShipToForm.patchValue({
      id: 0,
      companyId: Number(this.companyId),
      addressType: 'Ship',
      shipVal: 0,
      parentCustomerId: this.OrderPartForm.value.customerId,
    })
  }
  ChangeWareHouse(row) {
    this.ShipToForm.patchValue({
      name: row.name,
      addressLine1: row.address1,
      addressLine2: row.address2,
      city: row.city,
      stateId: Number(row.stateId),
      countryId: row.countryId,
      zip: row.zipCode,
      companyId: row.companyId,
      shipVal: 2,
      customerName: row.name,
    })
    if (this.SalesOrderById != undefined) {
      this._IsShippingUpdate = true;
    }
  }
  ChangeCustomerDetails(row) {
    this.ShipToForm.patchValue({
      name: row.shipName,
      attention: row.shipAtten,
      addressLine1: row.shipAddressLine1,
      addressLine2: row.shipAddressLine2,
      city: row.shipCity,
      stateId: Number(row.shipState),
      countryId: Number(row.shipCountry),
      companyId: Number(row.companyId),
      zip: row.shipZipCode,
      email: row.shipEmail,
      phone: row.shipPhone,
      parentCustomerId: row.parentCustomerId,
      customerId: row.id,
      shipVal: 3,
      customerName: row.customerName,
      cName: row.cname,
    })
    if (this.SalesOrderById != undefined) {
      this._IsShippingUpdate = true;
    }
  }
  selectedRow(Id: number, CustId: number) {
    this.loader = true;
    this.IsSalesOrderList = true;
    this.GetCustomerWithChild(CustId, false);
    this.GetSalesOrderById(Id);
    this.FormValidationRemoveClr(true);
  }
  AddPartsFlyOut() {
    this.IsAddPartFlyOut = true;
  }
  PartFlyOutClose(value) {
    this.IsAddPartFlyOut = value.IsAddPartFlyOut;
    let partCount = this.partList.length;
    this.partList = this.partList.concat(value.PartsList);
    this._IsShippingUpdate = partCount != this.partList.length ? true : false;;
    this.BindOrderPart();
    this.IsValidItem = this.partList.length > 0 ? true : false;
  }
  AddHistoryFlyOut(salesOrderVm: string, IsPrint: boolean, IsEmail: number, IsOpenFlyOut: boolean) {
    this.IsHistoryFlyOut = false;
    this.HistoryFlyOutVal = {
      "purchaseOrder": this.OrderPartForm.value.purchaseOrder,
      "StateList": this.StateList,
      "CountryList": this.CountryList,
      "BalanceDue": this.GetBalanceDue(),
      "id": this.OrderPartForm.value.id,
      "orderNumber": this.OrderPartForm.value.orderNumber,
      "customerPONumber": this.OrderPartForm.value.customerPONumber,
      "BillToName": this.BillToForm.value.name,
      "statusId": this.OrderPartForm.value.statusId,
      "CompanyId": this.OrderPartForm.value.companyId,
      "IsPrint": IsPrint,
      "IsEmail": IsEmail,
      "salesOrderVm": salesOrderVm,
      "IsOpenFlyOut": IsOpenFlyOut,
      "CompanyDetail": this.CompanyDetail,
    }
    this.IsHistoryFlyOut = true;
  }

  CloseHistoryFlyOut() {
    this.IsHistoryFlyOut = false;
  }
  ClosePaymentLogFlyOut() {
    this.IsPaymentLogFlyOut = false;
  }
  Search(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.GetOrderList(false, false);
  }
  GetOrderList(IsLoader: boolean, isUpdate: boolean) {
    this.loader = IsLoader;
    let orderRequest = {
      companyId: Number(this.companyId),
      isActive: 1,
      statusIds: this.selectedFilter,
      offsetNo: 0,
      pageSize: this.start,
      searchTerm: this.searchTerm != '' ? this.searchTerm.trim() : '',
      Columns: this.select != undefined && this.select.value != undefined ? this.select.value : []
    }
    this.SalesOrderList = [];
    this.salesOrderService.GetSalesOrderList(orderRequest)
      .subscribe({
        next: (data: any) => {
          this.SalesOrderList = data.dataList;
          this.SalesOrderListDS = new MatTableDataSource(data.dataList);
          this.loader = false;
          if (isUpdate) {
            this.updateIndex();
          }
        },
        error: (err) => {
          this.loader = false
        },
      });
  }

  // for lazyload first table
  SalesOrderListScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.GetOrderList(false, true);
    }
  }
  updateIndex() {
    this.start = this.SalesOrderList.length + 500;
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  GetCustomerById(custId) {
    this.loader = true;
    this.SalesOrderList = [];
    this.customerService.GetAllCustomerByCustomerId(custId)
      .subscribe({
        next: (data: any) => {
          this.BillToDetails = data.data;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetSalesOrderById(Id) {
    this.loader = true;
    this.salesOrderService.GetSalesOrderById(Id)
      .subscribe({
        next: (data: any) => {
          this.FormValidationRemoveClr(true);
          this._IsShippingUpdate = false;
          this.SalesOrderById = data;
          this.BindGetDate();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  BindGetDate() {
    if (this.SalesOrderById != undefined) {
      this.OrderPartForm.patchValue(this.SalesOrderById.salesOrder)
      this.BindOrderById();
      this.ShipToForm.patchValue(this.SalesOrderById.shipAddress)
      this.BillToForm.patchValue(this.SalesOrderById.billAddress)
      this.shipToVal = this.ShipToForm.value.shipVal;
      this.PaymentLogList = this.SalesOrderById.orderPaymentLog.length > 0 ? this.SalesOrderById.orderPaymentLog.sort((low, high) => low.id - high.id) : [];
      this.partList = this.SalesOrderById.salesOrder.orderPart
      this.BindOrderPart();
    }
  }
  AddCustomer(type: string) {
    this.submitted = true;
    this.CustomerForm.patchValue({ id: 0, parentCustomerId: type == 'Ship' ? this.SalesOrderById.salesOrder.customerId : 0 })
    if (!this.CustomerForm.invalid) {
      this.loader = true;
      this.salesOrderService.AddBillToCustomer(this.CustomerForm.value, type)
        .subscribe({
          next: (data: any) => {
            if (type == 'Ship') {
              this.GetCustomerWithChild(this.SalesOrderById.salesOrder.customerId, true);
              this.CloseAddShipToAddres();
            } else {
              this.CloseBillToAddres();
            }
            this.submitted = false;
            this.loader = false;
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  PaymentLog() {
    this.loader = true;
    this.PaymentLogList = [];
    this.salesOrderService.PaymentLog(this.OrderPartForm.value.id)
      .subscribe({
        next: (data: any) => {
          this.PaymentLogList = data.length > 0 ? data.sort((low, high) => low.id - high.id) : [];
          this.loader = false;
          this.IsPaymentLogFlyOut = true;
        },
        error: (err) => {
          this.IsPaymentLogFlyOut = false;
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  //Constant value Get
  Constant() {
    this.NetTermsList = this.constants.NetTermsListVal.sort((low, high) => high.id - low.id);
  }
  // Constant Value End
  // Role Permission Start
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End
  DefaultValForCustomerForm() {
    this.GetStateByCountryId(231);
    this.CustomerForm.patchValue({ id: 0, companyId: Number(this.companyId), countryId: 231, stateId: 3923 })
  }
  // Modal Show/Hide Start
  AddShippingServicesModal() {
    $("#updateshipping").show();
  }
  CloseShippingServicesModal() {
    $("#updateshipping").hide();
  }
  AddBillToAddres() {
    this.CustomerForm.reset();
    this.DefaultValForCustomerForm();
    $("#BillToAddressModal").show();
  }
  CloseBillToAddres() {
    this.submitted = false;
    $("#BillToAddressModal").hide();
  }
  AddShipToAddres() {
    this.CustomerForm.reset();
    this.DefaultValForCustomerForm();
    $("#ShipToAddressModal").show();
  }
  CloseAddShipToAddres() {
    this.submitted = false;
    $("#ShipToAddressModal").hide();
  }
  PaymentInfoModal() {
    this.CCardPaymentForm.patchValue({
      CCAmount: this.GetBalanceDue(), name: this.BillToForm.value.name,
      addressLine1: this.BillToForm.value.addressLine1, zip: this.BillToForm.value.zip
    })
    $("#PaymentInfoModal").show();
  }
  ClosePaymentInfoModal() {
    $("#PaymentInfoModal").hide();
  }
  async CardInitialization() {
    //Card Details
    const payments = window.Square.payments(this.constants.sqAppId, this.constants.sqLocationId);
    card = await this.initializeCard(payments);
    card.attach('#card-container');
    //Card Details End
  }
  PaymentModal() {
    if (this.OrderPartForm.value.paymentStatus == '') {
      this.PaymentInfoModal();
    } else {
      this.PaymentForm.patchValue({ PaymentAmount: this.GetBalanceDue() })
      $("#PaymentModal").show();
    }
  }
  ClosePaymentModal() {
    this.PaymentForm.get('PaymentAmount').clearValidators();
    this.PaymentForm.get('PaymentAmount').updateValueAndValidity();
    this.PaymentForm.patchValue({ PaymentAmount: '' })
    $("#PaymentModal").hide();
  }
  SalesDiscountModal(i: number) {
    this.DiscountIndex = i;
    $("#SalesDiscountModal").show();
  }
  CloseSalesDiscountModal() {
    this.DiscountIndex = -1;
    $("#SalesDiscountModal").hide();
  }
  closevalidaddress() {
    $("#validAddress").hide();
  }
  closeValidationModal() {
    $("#SOFormValidationModal").hide();
  }
  // Modal Show/Hide End
  // Swal Msg Start
  EmailSuccess() {
    Swal.fire({
      titleText: this.constants.Success,
      text: 'Your email has been sent.',
      confirmButtonText: 'Ok',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  CancelStatusModal() {
    Swal.fire({
      titleText: 'Confirm Canceled Status!',
      text: 'Click the Cancel button to update the status of this order to Canceled or Click the Close button to keep the current status.',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'close',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  MissingAnswerMsg() {
    Swal.fire({
      titleText: 'Missing Answer',
      text: 'Please answer all required Sales Questions before updating the Sales Order Status from Quote.',
      confirmButtonText: 'Close',
      showCancelButton: false,
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          this.OrderPart.value.forEach(ql => {
            ql.Question.filter(x => x.IsRequired).forEach(IsShow => {
              IsShow.IsShow = true
            });
          });
          this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value });
        }
      }
    });
  }
  // Swal Msg End
  // Form defination start
  get CustomerFormError() {
    return this.CustomerForm.controls;
  }
  get ShipToFormError() {
    return this.ShipToForm.controls;
  }
  get OrderPart() {
    return (<FormArray>this.OrderPartForm.get('OrderPart'));
  }
  RemoveCurrency(value): number {
    return typeof (value) == 'string' ? value.replaceAll('$', '').replaceAll(',', '') : value;
  }
  AddCurrency(value): string {
    return value != '' ? this.currencyPipe.transform(value, '$', 'symbol', '1.0-2') : '';
  }
  AcceptNumberOnly(event, type: string) {
    let Amt: any = 0;
    if (event.target.value != '') {
      if (!isNaN(event.target.value)) {
        Amt = event.target.value;
      } else {
        const regex = /[-+]?[0-9]*\.?[0-9]+/g;
        const matches = event.target.value.match(regex);
        Amt = matches ? matches.map(num => parseFloat(num)) : 0;
      }
    } else {
      Amt = 0;
    }
    if (type == 'CCAmount') {
      this.CCardPaymentForm.patchValue({ CCAmount: Amt })
      if (this.CCardPaymentForm.value.CCAmount <= this.GetBalanceDue()) {
        this.CCardPaymentForm.get('CCAmount').setErrors(null);
      } else {
        this.CCardPaymentForm.get('CCAmount').setErrors({ 'Exceed': true });
      }
    } else if (type == 'PaymentAmount') {
      this.PaymentForm.patchValue({ PaymentAmount: Amt })
      if (this.PaymentForm.value.PaymentAmount <= this.GetBalanceDue()) {
        this.PaymentForm.get('PaymentAmount').setErrors(null);
      } else {
        this.PaymentForm.get('PaymentAmount').setErrors({ 'Exceed': true });
      }
    }
  }
  GetBalanceDue(): number {
    let amt = 0;
    amt = (this.RemoveDecimal((this.OrderPartForm.value.subTotalSummaryExt +
      (this.OrderPartForm.value.partTaxRateAmt + this.OrderPartForm.value.laborTaxRateAmt + this.OrderPartForm.value.shippingTaxRateAmt)
      + this.OrderPartForm.value.shippingAmount), 2) - this.RemoveDecimal(this.OrderPartForm.value.paymentAmt, 2))
    return amt > 0 ? amt : 0;
  }
  IsPayNow(): boolean {
    let disbl = false;
    if (this.OrderPartForm.value.paymentType == 'NetTerms') {
      disbl = true;
    } else if (this.GetBalanceDue() <= 0) {
      disbl = true;
    }
    return disbl;
  }
  CreateOrderPaymentForm() {
    this.PaymentForm = this.fb.group({
      'id': new FormControl(0),
      'CompanyId': new FormControl(Number(this.companyId)),
      'PaymentAmount': new FormControl(''),
    });
  }
  CreditCardPaymentForm() {
    this.CCardPaymentForm = this.fb.group({
      'id': new FormControl(0),
      'CompanyId': new FormControl(Number(this.companyId)),
      'CCAmount': new FormControl(''),
      'name': new FormControl(''),
      'addressLine1': new FormControl('', Validators.required),
      'zip': new FormControl('', [Validators.minLength(5)]),
    },);
  }
  CreateCustomerForm() {
    this.CustomerForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'parentCustomerId': new FormControl(0),
      'attention': new FormControl(''),
      'name': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'addressLine1': new FormControl('', Validators.required),
      'addressLine2': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'zip': new FormControl('', [Validators.minLength(5), Validators.required]),
      'city': new FormControl('', Validators.required),
      'stateId': new FormControl(3923, Validators.required),
      'phone': new FormControl('', [Validators.minLength(10)]),
      'countryId': new FormControl(231, Validators.required),
    });
  }
  CreateBillToForm() {
    this.BillToForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'customerId': new FormControl(0),
      'parentCustomerId': new FormControl(0),
      'attention': new FormControl(''),
      'name': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'addressLine1': new FormControl('', Validators.required),
      'addressLine2': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'zip': new FormControl('', [Validators.minLength(5), Validators.required]),
      'city': new FormControl('', Validators.required),
      'stateId': new FormControl(0, Validators.required),
      'phone': new FormControl('', [Validators.minLength(10)]),
      'countryId': new FormControl(0, Validators.required),
      'shipVal': new FormControl(0),
      'addressType': new FormControl('Bill'),
      'cName': new FormControl(''),
      'customerName': new FormControl(''),
    });
  }
  CreateShipToForm() {
    this.ShipToForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'parentCustomerId': new FormControl(this.SalesOrderById != undefined ? this.SalesOrderById.salesOrder.customerId : 0),
      'name': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'attention': new FormControl(''),
      'addressLine1': new FormControl('', Validators.required),
      'addressLine2': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'zip': new FormControl('', [Validators.minLength(5), Validators.required]),
      'countryId': new FormControl(0, Validators.required),
      'city': new FormControl('', Validators.required),
      'stateId': new FormControl(0, Validators.required),
      'shipVal': new FormControl(0),
      'phone': new FormControl('', [Validators.minLength(11)]),
      'addressType': new FormControl('Ship'),
      'cName': new FormControl(''),
      'customerName': new FormControl(''),
    });
  }
  CreateOrderPartForm() {
    this.OrderPartForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'customerId': new FormControl(0),

      //OrderInformation section start
      'statusId': new FormControl(10),
      'fulfilmentType': new FormControl('Auto'),
      'orderNumber': new FormControl(''),
      'holdReason': new FormControl(''),
      'holdUntilDate': new FormControl(),
      'defQuoteDays': new FormControl(),
      //OrderInformation Section End

      //Middle Section Start
      'businessChannel': new FormControl(''),
      'customerRole': new FormControl(''),
      'netTerms': new FormControl(0),
      'availableCredit': new FormControl({ value: '', disabled: true }),
      'taxable': new FormControl(''),
      'purchaseOrder': new FormControl(''),
      'customerPONumber': new FormControl(''),
      'isPartialShipment': new FormControl(false),
      'collectAccount': new FormControl(''),
      'collectAmount': new FormControl(''),
      'isCollectAmount': new FormControl(false),
      'customerNotes': new FormControl(''),
      'wareHouseNotes': new FormControl(''),
      'eOrderNo': new FormControl(''),
      'source': new FormControl('Internal'),
      //Middle Section End

      // OrderPart Start
      'OrderPart': new FormArray([]),
      // Order Part End
      //Shipping Rate Start
      "shippingOption": new FormControl(0),
      "shippingAmount": new FormControl(0.0),
      "partTaxRateAmt": new FormControl(0.0),
      "partTaxRate": new FormControl({ value: 0, disabled: true }),
      "laborTaxRate": new FormControl({ value: 0, disabled: true }),
      "shippingTaxRate": new FormControl({ value: 0, disabled: true }),
      "laborTaxRateAmt": new FormControl(0.0),
      "shippingTaxRateAmt": new FormControl(0.0),
      "shippingType": new FormControl('Select One'),
      "rate_id": new FormControl(''),
      "serviceRateId": new FormControl(''),
      "shippingServices": new FormControl(''),
      "packageType": new FormControl('YOUR_PACKAGING'),
      //Shipping Rate End
      //Sales Tax Start
      "taxType": new FormControl(0),
      "isTaxPart": new FormControl(false),
      "isTaxLabor": new FormControl(false),
      "isTaxShipping": new FormControl(false),
      //Sales Tax End
      //Payment Start
      "paymentStatus": new FormControl(''),
      "paymentType": new FormControl(''),
      "paymentAmt": new FormControl(0.0),
      "BalanceDue": new FormControl(0.0),
      //Payment End
      'createdBy': new FormControl(localStorage.getItem('userId')),
      'orderDate': new FormControl(new Date()),
      'customerType': new FormControl(),
      //SubTotal Start
      'subTotalSummaryExt': new FormControl(0.0),
      'subTotalDetExt': new FormControl(0.0),
      'subTotalPartsMargin': new FormControl(0.0),
      'subTotalLaborMargin': new FormControl(0.0),
      'subTotalPartsExt': new FormControl(0.0),
      //SubTotal End
      //Shipping Start
      'quotedShippingWeight': new FormControl(0.0),
      'quotedShippingPackage': new FormControl(0),
      //Shipping End
      'isAddressValidate': new FormControl(false),
      'isPO': new FormControl(this.CompanyDetail != undefined ? this.CompanyDetail.isPo : false)
    });
  }
  // Form defination End
  //Bind Order Start
  BindOrderById() {
    this.shiprateListnew = [];
    if (this.SalesOrderById != undefined) {
      this.OrderPartForm.patchValue({
        createdBy: localStorage.getItem('userId'),
        collectAccount: !this.OrderPartForm.value.isCollectAmount ? '' : this.OrderPartForm.value.collectAccount,
        availableCredit: this.AddCurrency(this.SalesOrderById.salesOrder.availableCredit),
        paymentType: this.ISNullOrEmpty(this.SalesOrderById.salesOrder.paymentType) ? 'NetTerms' : this.SalesOrderById.salesOrder.paymentType,
      })
      this.IsTaxRateChecked('Parts');
      this.IsTaxRateChecked('Labor');
      this.IsTaxRateChecked('Shipping');
      this.DisableNetTerms();
      this.shiprateListnew = this.ISNullOrEmpty(this.OrderPartForm.value.shippingServices) ? [] : JSON.parse(this.OrderPartForm.value.shippingServices);
      // OrderPart Start
      //OrderPart End
    }
  }
  // Bind Order End

  DisableNetTerms(): Number {
    let val = 0;
    if (this.NetTermsList.filter(x => x.name == this.SalesOrderById.salesOrder.netTerms).length) {
      this.NetTermsList.filter(x => x.name == this.SalesOrderById.salesOrder.netTerms)[0].IsDisable = false;
      val = this.NetTermsList.filter(x => x.name == this.SalesOrderById.salesOrder.netTerms)[0].id;
      this.NetTermsList.filter(x => x.name > this.SalesOrderById.salesOrder.netTerms).forEach(element => {
        element.IsDisable = true;
      });
    } else {
      val = 0;
    }
    return val;
  }
  GetNetTermsVal(): string {
    let val = '';
    if (this.OrderPartForm.value.netTerms != undefined && this.NetTermsList.filter(x => x.id == this.OrderPartForm.value.netTerms).length > 0) {
      let result = this.NetTermsList.filter(x => x.id == this.OrderPartForm.value.netTerms)[0];
      val = (result.id == 0) ? result.name : 'Net ' + result.name;
    }
    return val;
  }

  GetCustomerTypeForRolePricing(Id: any): string {
    let val = '';
    if (this.constants.CustomerType != undefined && this.constants.CustomerType.length > 0 && Id != undefined) {
      let result = this.constants.CustomerType.filter(x => x.id == Id)[0].name;
      val = result;
    }
    return val;
  }
  ChangeTaxable(value: any) {
    let TaxType = 0;
    if (value == 'Taxable') {
      TaxType = 1;
      this.OrderPart.value.forEach((v, index) => {
        this.OrderPart.controls[index].get('IsTaxable').disable({ onlySelf: true, emitEvent: false })
        this.OrderPart.value[index].IsTaxable = true;
      });
      this.loader = true;
      this.addedTaxesAPI();
    } else {
      TaxType = value == 'NonTaxable' ? 3 : 2;
      this.OrderPart.value.forEach((v, index) => {
        this.OrderPart.controls[index].get('IsTaxable').enable();
        this.OrderPart.value[index].IsTaxable = false;
      });
    }
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value, taxType: TaxType, laborTaxRate: 0, laborTaxRateAmt: 0, partTaxRate: 0, partTaxRateAmt: 0, shippingTaxRate: 0, shippingTaxRateAmt: 0 })
  }
  ChangeCollectAccount(value: any) {
    if (value == '') {
      this.OrderPartForm.patchValue({ isCollectAmount: false, shippingOption: 0, collectAccount: '', CollectAmount: '', shippingType: 'Select One', shippingServices: '', packageType: '' })
    } else {
      this.OrderPartForm.patchValue({ isCollectAmount: true, shippingAmount: 0, shippingOption: 5, shippingType: 'Collect Account', shippingServices: '', packageType: '' })
    }
    this.shiprateListnew = [];
  }
  BindOrderPart() {
    this.OrderPart.clear();
    this.partList.forEach((element, index) => {
      this.OrderPart.push(new FormGroup({
        Id: new FormControl(element.id),
        PartId: new FormControl(element.partId),
        PartNumber: new FormControl(element.partNumber),
        PartName: new FormControl(element.partName),
        Quantity: new FormControl(element.quantity),
        // Discount Section Start
        CustDiscountType: new FormControl(''),
        CustDiscountCost: new FormControl(0),
        _CustomerDiscount: new FormControl(element._CustomerDiscount),
        RoleBasePricingDiscount: new FormControl(element.roleBasePricingDiscount),
        DimentionType: new FormControl(element.dimentionType),
        // Discount Section End
        // Summary Section Start
        IsTaxable: new FormControl({ value: true, disabled: this.OrderPartForm.value.taxable == 'Taxable' ? true : false }),
        Cost: new FormControl(this.RemoveDecimal(element.cost, 2)),
        SExtension: new FormControl(this.RemoveDecimal(element.sExtension, 2)),
        // Summary Section Start
        // Detail Section Start
        Hours: new FormControl(element.hours),
        LaborCost: new FormControl(element.laborCost),
        TotalLobor: new FormControl(this.RemoveDecimal(element.hours * element.laborCost, 2)),
        DExtension: new FormControl(element.DExtension),
        // Detail Section End
        // Profitability Section Start
        UnitCost: new FormControl(0),
        PartsMargin: new FormControl(0),
        LaborLoadedCost: new FormControl(element.laborLoadedCost),
        LaborMargin: new FormControl(0),
        PExtension: new FormControl(0),
        Notes: new FormControl(element.notes),
        IsNotes: new FormControl(this.ISNullOrEmpty(element.notes) ? false : true),
        Question: new FormArray([]),
        // Profitability Section End
        //Dimention Start
        width: new FormControl(Number(element.width).toString()),
        height: new FormControl(Number(element.height).toString()),
        length: new FormControl(Number(element.length).toString()),
        weightType: new FormControl(element.weightType),
        weight: new FormControl(element.weight),
        //Dimention End
        //Vendor Start
        customPartId: new FormControl(element.customPartId),
        vendorId: new FormControl(element.vendorId),
        vendorPartCost: new FormControl(element.vendorPartCost),
        //Vendor End
        //Shipment
        shipedQty: new FormControl(element.shipedQty)
      }))
      this.BindQuestions(element.question, index);
    });
    this.SubTotal();
    this.loader = false;
  }
  Question(index: number): FormArray {
    return this.OrderPart.at(index).get('Question') as FormArray
  }
  IsMissingAnswer(): boolean {
    let IsMissingAns = false;
    this.OrderPart.value.forEach(ql => {
      if (ql.Question.filter(x => x.IsRequired && (x.Answer == '' || x.Answer == null)).length > 0) {
        this.MissingAnswerMsg()
        IsMissingAns = true;
      }
    });
    return IsMissingAns;
  }
  Cancel() {
    this.BindGetDate();
    this._IsShippingUpdate = false;
    this._PShipping = false;
    this._PSales = false;
    this._PPayment = false;
    this.base64 = undefined;
  }
  IsUpdatedShipping(IsSkipShipping: boolean) {
    let result = false;
    if (this.SalesOrderById != undefined && !IsSkipShipping && this._IsShippingUpdate) {
      result = true
    } else {
      result = false
    }
    return result;
  }
  Save(IsApprove, IsComplete, IsSkipShipping, IsFinalPayment) {
    this.CloseShippingServicesModal();
    if (!this.IsUpdatedShipping(IsSkipShipping)) {
      this.loader = true;
      if (!this.IsMissingAnswer()) {
        this.loader = true;
        this.OrderPartForm.patchValue({ BalanceDue: this.GetBalanceDue(), availableCredit: Number(this.RemoveCurrency(this.OrderPartForm.getRawValue().availableCredit)) })
        let salesOrderVm = {
          salesOrder: this.OrderPartForm.getRawValue(),
          ShipAddress: this.ShipToForm.value,
          BillAddress: this.BillToForm.value
        }
        salesOrderVm.salesOrder.customerType = 0;
        this.salesOrderService.AddUpdSalesOrder(salesOrderVm)
          .subscribe({
            next: (data: any) => {
              if (!IsApprove && IsComplete) {
                IsFinalPayment ? this.CompletePayment(Number(this.PaymentForm.value.PaymentAmount)) : this.CompletePayment(Number(this.CCardPaymentForm.value.CCAmount));
              } else if (IsApprove && IsComplete) {
                this.Pay(IsFinalPayment, Number(this.CCardPaymentForm.value.CCAmount));
              } else {
                this.loader = false;
                this.GetSalesOrderById(data);
              }
              if (this.OrderPartForm.value.statusId != 10 && this.OrderPartForm.value.statusId != 11 && this.OrderPartForm.value.fulfilmentType == 'Auto' && this.OrderPartForm.value.isPO) {
                this.loader = (!IsApprove && !IsComplete) ? false : true;
                this.FulfillmentAutomatic(data);
              }
            },
            error: (err) => {
              this.loader = false
              this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
            },
          });
      } else {
        this.loader = false;
      }
    } else {
      this.loader = false;
      this.AddShippingServicesModal();
    }
  }
  FulfillmentAutomatic(Id: number) {
    if (Id > 0) {
      this.salesOrderService.FulfillmentAutomatic(Id)
        .subscribe({
          next: (data: any) => {
          },
          error: (err) => {
          },
        });
    }
  }
  BindQuestions(Question: any, i: number) {
    Question.forEach((q) => {
      this.Question(i).push(new FormGroup({
        Id: new FormControl(q.id),
        PartId: new FormControl(q.partId),
        QuestionId: new FormControl(q.questionId),
        QuestionTitle: new FormControl(q.questionTitle),
        Answer: new FormControl(q.answer, q.isRequired ? Validators.required : []),
        AnswerTypeId: new FormControl(q.answerTypeId),
        IsRequired: new FormControl(q.isRequired),
        IsShow: new FormControl(false)
      }))
    });
  }
  CalculateListDiscount(value: any, i: number): DiscountVM {
    const disCost = new DiscountVM();
    if (value._CustomerDiscount != null && value._CustomerDiscount.length > 0 && value._CustomerDiscount.filter(x => x.minQty <= value.Quantity && x.qty >= (x.qty != 0 ? value.Quantity : 0)).length > 0) {
      disCost.CustDiscountCost = Math.min(...value._CustomerDiscount.filter(x => x.minQty <= value.Quantity && x.qty >= (x.qty != 0 ? value.Quantity : 0)).map(item => item.customerCost))
      disCost.CustomerType = 'vol';
    }
    if (value.roleBasePricingDiscount != null && value.roleBasePricingDiscount.length > 0 && value.roleBasePricingDiscount.filter(x => x.customerValue == this.OrderPartForm.value.customerType).length > 0) {
      let cost = 0;
      cost = Math.min(...value.roleBasePricingDiscount.filter(x => x.customerValue == this.OrderPartForm.value.customerType).map(item => item.cost))
      disCost.CustomerType = disCost.CustDiscountCost > cost ? 'role' : 'vol';
      disCost.CustDiscountCost = disCost.CustDiscountCost > cost ? cost : disCost.CustDiscountCost;
    }
    return disCost;
  }

  CalculateProfatibility() {
    this.OrderPartForm.value.subTotalPartsMargin = 0
    this.OrderPartForm.value.subTotalLaborMargin = 0
    this.OrderPartForm.value.subTotalPartsExt = 0
    this.OrderPart.value.forEach(v => {
      v.UnitCost = this.RemoveDecimal(v.CustDiscountType == '' ? v.vendorPartCost : v.CustDiscountCost, 2)
      v.SExtension = this.RemoveDecimal(v.CustDiscountType == '' ? (v.Cost * v.Quantity) : (v.CustDiscountCost * v.Quantity), 2);
      v.PartsMargin = this.RemoveDecimal(1 - ((v.vendorPartCost / v.Cost) == Infinity ? 0 : (v.vendorPartCost / v.Cost)), 2);
      v.LaborMargin = this.RemoveDecimal(1 - ((v.LaborCost / v.LaborLoadedCost) == Infinity ? 0 : (v.LaborCost / v.LaborLoadedCost)), 2);
      v.PExtension = this.RemoveDecimal(((v.PartsMargin + v.LaborMargin) / 2), 2);
      this.OrderPartForm.value.subTotalPartsMargin = this.OrderPartForm.value.subTotalPartsMargin + v.PartsMargin;
      this.OrderPartForm.value.subTotalLaborMargin = this.OrderPartForm.value.subTotalLaborMargin + v.LaborMargin;
      this.OrderPartForm.value.subTotalPartsExt = this.OrderPartForm.value.subTotalPartsExt + v.PExtension;
    });

    this.OrderPartForm.value.subTotalPartsMargin = this.RemoveDecimal(this.OrderPartForm.value.subTotalPartsMargin / this.OrderPart.value.length, 2)
    this.OrderPartForm.value.subTotalLaborMargin = this.RemoveDecimal(this.OrderPartForm.value.subTotalLaborMargin / this.OrderPart.value.length, 2)
    this.OrderPartForm.value.subTotalPartsExt = this.RemoveDecimal((this.OrderPartForm.value.subTotalPartsExt) / this.OrderPart.value.length, 2)
    this.OrderPartForm.patchValue({
      OrderPart: this.OrderPart.value, subTotalPartsMargin: this.OrderPartForm.value.subTotalPartsMargin,
      subTotalLaborMargin: this.OrderPartForm.value.subTotalLaborMargin, subTotalPartsExt: this.OrderPartForm.value.subTotalPartsExt
    })
  }
  SubTotal() {
    this.OrderPartForm.value.subTotalDetExt = 0;
    this.OrderPartForm.value.subTotalSummaryExt = 0;
    this.OrderPart.value.forEach((v, index) => {
      let dis = this.CalculateListDiscount(v, index);
      //Summary Section
      v.CustDiscountType = dis.CustomerType;
      v.CustDiscountCost = dis.CustDiscountCost;
      v.SExtension = this.RemoveDecimal(dis.CustomerType == '' ? (v.Cost * v.Quantity) : (v.CustDiscountCost * v.Quantity), 2)
      this.OrderPartForm.value.subTotalSummaryExt = this.OrderPartForm.value.subTotalSummaryExt + v.SExtension;
      //Detail Section
      v.SExtension = this.RemoveDecimal(v.CustDiscountType == '' ? (v.Cost * v.Quantity) : (v.CustDiscountCost * v.Quantity), 2)
      v.TotalLobor = this.RemoveDecimal(v.Hours * v.LaborCost, 2);
      v.DExtension = v.SExtension + v.TotalLobor;
      this.OrderPartForm.value.subTotalDetExt = this.OrderPartForm.value.subTotalDetExt + v.SExtension + v.TotalLobor;
    });
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value, subTotalSummaryExt: this.OrderPartForm.value.subTotalSummaryExt, subTotalDetExt: this.OrderPartForm.value.subTotalDetExt })
    this.CalculateTaxRate('Parts')
    this.CalculateProfatibility();
    this.CalculateDimension();
    this.OrderPartForm.get('OrderPart')['controls'].forEach((control, index) => {
      control.get('Quantity').valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
        if (value !== this.OrderPart.value[index].Quantity) {
          this._IsShippingUpdate = true;
        }
      });
    });
  }
  changeTax(value: boolean) {
    if (this.OrderPart.value.filter(x => x.IsTaxable).length > 0) {
      this.OrderPartForm.patchValue({ TaxType: 1 })
    } else {
      this.OrderPartForm.patchValue({ TaxType: 2 })
    }
    this.addedTaxesAPI()
  }
  IsTaxable(taxType: string): number {
    let taxAmt = 0;
    if (this.OrderPartForm.value.taxable == 'Taxable') {
      this.OrderPart.value.forEach(t => {
        if (taxType == 'Parts') {
          taxAmt = taxAmt + t.SExtension;
        } else if (taxType == 'Labor') {
          taxAmt = taxAmt + t.LaborCost * t.Hours;
        }
      });
    } else {
      this.OrderPart.value.filter(x => x.IsTaxable).forEach(t => {
        if (taxType == 'Parts') {
          taxAmt = taxAmt + t.SExtension;
        } else if (taxType == 'Labor') {
          taxAmt = taxAmt + t.LaborCost * t.Hours;
        }
      });
    }
    return this.RemoveDecimal(taxAmt, 2);
  }
  CalculateTaxRate(type: string) {
    if (type == 'Labor' && this.OrderPartForm.value.laborTaxRate != undefined) {
      this.OrderPartForm.patchValue({ laborTaxRateAmt: this.RemoveDecimal(((this.IsTaxable('Labor') * this.OrderPartForm.value.laborTaxRate) / 100), 2) })
    } else if (type == 'Parts' && this.OrderPartForm.value.partTaxRate != undefined) {
      this.OrderPartForm.patchValue({ partTaxRateAmt: this.RemoveDecimal(((this.IsTaxable('Parts') * this.OrderPartForm.value.partTaxRate) / 100), 2) })
    } else if (type == 'Shipping' && this.OrderPartForm.value.shippingTaxRate != undefined) {
      this.OrderPartForm.patchValue({ shippingTaxRateAmt: this.RemoveDecimal(((this.OrderPartForm.value.shippingAmount * this.OrderPartForm.value.shippingTaxRate) / 100), 2) })
    }
  }
  IsTaxRateChecked(type: string) {
    if (type == 'Labor' && !this.OrderPartForm.value.isTaxLabor) {
      this.OrderPartForm.get('laborTaxRate').disable();
      this.OrderPartForm.patchValue({ laborTaxRate: 0, laborTaxRateAmt: 0 })
    } else if (type == 'Labor' && this.OrderPartForm.value.isTaxLabor) {
      this.OrderPartForm.get('laborTaxRate').enable();
    } else if (type == 'Parts' && !this.OrderPartForm.value.isTaxPart) {
      this.OrderPartForm.get('partTaxRate').disable();
      this.OrderPartForm.patchValue({ partTaxRate: 0, partTaxRateAmt: 0 })
    } else if (type == 'Parts' && this.OrderPartForm.value.isTaxPart) {
      this.OrderPartForm.get('partTaxRate').enable();
    } else if (type == 'Shipping' && !this.OrderPartForm.value.isTaxShipping) {
      this.OrderPartForm.get('shippingTaxRate').disable();
      this.OrderPartForm.patchValue({ shippingTaxRate: 0, shippingTaxRateAmt: 0 })
    } else if (type == 'Shipping' && this.OrderPartForm.value.isTaxShipping) {
      this.OrderPartForm.get('shippingTaxRate').enable();
    }
  }
  ChangeTaxType(value: any) {
    if (value == '1') {
      this.OrderPartForm.patchValue({
        laborTaxRate: 0.0,
        laborTaxRateAmt: 0.0,
        shippingTaxRate: 0.0,
        shippingTaxRateAmt: 0.0,
        taxable: 'Taxable'
      })
      this.addedTaxesAPI();
    } else {
      this.OrderPartForm.value.taxable = value == 3 ? 'NonTaxable' : 'Exempt';
      this.OrderPartForm.patchValue({ laborTaxRate: 0, laborTaxRateAmt: 0.0, partTaxRate: 0, partTaxRateAmt: 0, shippingTaxRate: 0, shippingTaxRateAmt: 0, taxable: this.OrderPartForm.value.taxable })
    }
    //this.ChangeTaxable(this.OrderPartForm.value.Taxable);
  }
  RemoveDecimal(str, val) {
    if (!isNaN(str)) {
      str = String(str)
      if (str.indexOf(".") === -1) {
        str = String(str)
      }
      else {
        str = str.slice(0, (str.indexOf(".")) + val + 1);
      }
    } else {
      str = '';
    }
    return Number(str);
  }
  //Get All State
  getAllState() {
    this.salesOrderService.getAllState()
      .subscribe({
        next: (data: any) => {
          this.StateList = data;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetStateByCountryId(Id) {
    this.sharedService.getAllState(Id).subscribe((data: any) => {
      this.StateListByCountryId = data.dataList;
    })
  }
  //Get All Country
  GetCountry() {
    this.sharedService.GetCountry()
      .subscribe({
        next: (data: any) => {
          this.CountryList = data.dataList;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  BackToList() {
    this.OrderPart.clear();
    this.SalesOrderById = undefined;
    this.IsSalesOrderList = false;
    this.targetVal = 'Summary';
    this._PShipping = false;
    this._PSales = false;
    this._PPayment = false;
    this.base64 = undefined;
    this.searchTerm = '';
    this.GetOrderList(true, false);
  }
  setPShipping(value: BlobOptions) {
    this._PShipping = !value;
  }
  setPSales(value: BlobOptions) {
    this._PSales = !value;
  }
  setPPayment(value: BlobOptions) {
    this._PPayment = !value;
  }
  AddNotesRow(i: number) {
    this.OrderPart.value[i].IsNotes = true;
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value })
  }
  DelNotesRow(i: number) {
    this.OrderPart.value[i].IsNotes = false;
    this.OrderPart.value[i].Notes = '';
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value })
  }
  DeleteOrderPart(partId: number) {
    this.partList = this.partList.filter(p => p.partId != partId);
    this.BindOrderPart();
  }
  AddQuestionAnsRow(i: number, value: boolean) {
    this.Question(i).value.forEach(IsShow => {
      IsShow.IsShow = value;
    });
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value })
  }
  //Shipping Rate Start
  getshipValue(rate) {
    this.OrderPartForm.patchValue({ shippingAmount: rate.amount, shippingType: rate.service_type, rate_id: rate.rate_id, serviceRateId: rate.serviceRateId, packageType: rate.package_type });
    this.CalculateTaxRate('Shipping');
  }
  changeShippingServices(value: any) {
    if (value != 5 && value != 6) {
      this.OrderPartForm.patchValue({ isCollectAmount: false, shippingOption: value, collectAccount: '', shippingType: this.constants.ShippingType.filter(x => x.id == value)[0].name, isAddressValidate: false })
      if (this.OrderPart.value.length > 0 && this.OrderPartForm.value.quotedShippingWeight > 0) {
        this.shiprateListnew = [];
        this.RateAPI();
        //this.GetShippingCharge(value);
      }
    } else if (value == 6) {
      this.OrderPartForm.patchValue({ isCollectAmount: false, shippingAmount: 0, collectAccount: '', shippingOption: value, shippingType: this.constants.ShippingType.filter(x => x.id == value)[0].name })
      this.shiprateListnew = [];
    } else if (value == 5) {
      this.OrderPartForm.patchValue({ isCollectAmount: true, shippingAmount: 0, collectAccount: 'UPS', shippingOption: value, shippingType: this.constants.ShippingType.filter(x => x.id == value)[0].name })
      this.shiprateListnew = [];
    }
  }
  GetPackageType(serviceRateId: string) {
    let pacInfo = undefined;
    if (this.shiprateListnew.length && this.shiprateListnew.filter(r => r.serviceRateId == serviceRateId).length > 0) {
      pacInfo = this.shiprateListnew.filter(r => r.serviceRateId == this.OrderPartForm.value.serviceRateId)[0]
    }
    return pacInfo;
  }
  PackageRequest(): any[] {
    let packages = [];
    this.OrderPart.value.forEach(element => {
      packages.push(
        {
          "groupPackageCount": 1,
          "packageType": "",
          "packageSubType": 0,
          "signatureOptionType": 0,
          "declaredValue": {
            "amount": Number(element.CustDiscountType == '' ? element.Cost : element.CustDiscountCost),
            "type": 67
          },
          "dimensions": {
            "height": element.height == undefined ? 0 : Number(element.height),
            "length": element.length == undefined ? 0 : Number(element.length),
            "width": element.width == undefined ? 0 : Number(element.width),
            "unitOfMeasurement": element.DimentionType
          },
          "weight": { "unit": element.weightType, "value": element.weight }
        }
      )
    });
    return packages;
  }
  ship_to() {
    let ship_to = {
      "addressLineOne": this.ShipToForm.value.addressLine1,
      "addressLineTwo": this.ISNullOrEmpty(this.ShipToForm.value.addressLine2) ? '' : this.ShipToForm.value.addressLine2,
      "addressType": 0,
      "cityName": this.ShipToForm.value.city,
      "countryCode": this.GetCountryCode(this.ShipToForm.value.countryId),
      "stateCode": this.GetStateCode(this.ShipToForm.value.stateId),
      "postalCode": this.ShipToForm.value.zip
    }
    return ship_to;
  }
  RateAPI() {
    this.CloseShippingServicesModal();
    this._IsShippingUpdate = false;
    this.loader = true;
    let estimateRequestModels = {
      "documentIndicator": false,
      "packageType": "",
      "pickupType": 4,
      "preferredCurrency": 67,
      "providers": [this.OrderPartForm.value.shippingOption],
      "rateTypes": [1],
      "shipDate": new Date(),
      "totalPackageCount": this.OrderPart.value.length,
      "origin": this.constants.originAddress,
      "recipient": { "address": this.ship_to() },
      "totalWeight": { "unit": 5, "value": this.OrderPartForm.value.quotedShippingWeight },
      "packages": this.PackageRequest()
    }
    this.sharedService.RateAPI(Number(localStorage.getItem('GlobalSelectedCompanyId')), estimateRequestModels)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          this.shiprateListnew = [];
          data.providerRates[0].rates.forEach((element, index) => {
            var obj = {};
            obj["serviceRateId"] = String(index + 1);
            obj["rate_id"] = element.serviceType;
            obj["package_type"] = element.packageType;
            obj["service_type"] = element.serviceName;
            obj["carrier_delivery_days"] = element.operationalDetail.deliveryDay;
            obj["amount"] = element.rateDetails[0].totalNetCharge;
            this.shiprateListnew.push(obj);
          });
          this.shiprateListnew = this.shiprateListnew.sort((a, b) => (a.amount - b.amount));
          if (this.shiprateListnew.length > 0) {
            this.OrderPartForm.patchValue({ shippingAmount: this.shiprateListnew[0].amount, shippingType: this.shiprateListnew[0].service_type, rate_id: this.shiprateListnew[0].rate_id, serviceRateId: this.shiprateListnew[0].serviceRateId, packageType: this.shiprateListnew[0].package_type, shippingServices: JSON.stringify(this.shiprateListnew) });
            if (this.OrderPartForm.value.taxable == 'Taxable') {
              this.addedTaxesAPI();
            }
          }
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  //Shipping Rate End
  //Calculate Dimension Start
  CalculateDimension() {
    if (this.OrderPart.value != null && this.OrderPart.value.length > 0) {
      this.OrderPartForm.value.quotedShippingWeight = 0;
      this.OrderPart.value.forEach(op => {
        if (op.PartId != 0) {
          this.OrderPartForm.value.quotedShippingWeight += Number((op.weight * parseInt(op.Quantity)));
        }
      });
      this.OrderPartForm.patchValue({ quotedShippingWeight: this.OrderPartForm.value.quotedShippingWeight, quotedShippingPackage: this.OrderPart.value.length })
    }
  }
  //Calculate Dimension End
  //Create Object  for Taxes
  addedTaxesAPI() {
    if (this.ShipToForm.valid) {
      let objTaxes = {
        "to_country": this.GetCountryCode(this.ShipToForm.value.countryId),
        "to_zip": this.ShipToForm.value.zip,
        "to_state": this.GetStateCode(this.ShipToForm.value.stateId),
        "to_city": this.ShipToForm.value.city,
        "amount": this.IsTaxable('Parts'),
        "shipping": this.OrderPartForm.value.shippingAmount
      }
      if (this.GetStateCode(this.ShipToForm.value.stateId) == 'CA') {
        this.sharedService.TaxesAPIPost(objTaxes)
          .subscribe({
            next: (data) => {
              if (data.tax != null) {
                this.OrderPartForm.patchValue({ partTaxRateAmt: Number(data.tax.amount_to_collect), partTaxRate: this.RemoveDecimal(Number(data.tax.rate * 100), 2), isTaxPart: true });
              }
              this.loader = false;
            },
            error: (err) => {
              this.loader = false
              this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
            },
          });
      } else {
        this.OrderPartForm.patchValue({ partTaxRateAmt: 0, partTaxRate: 0 });
        this.loader = false;
      }
      this.CalculateTaxRate('Parts');
      this.CalculateTaxRate('Labor');
      this.CalculateTaxRate('Shipping');
    }
    this.loader = false;
  }
  // Address Validation Start
  ShippingAddressValidationApi() {
    if (!this.ShipToForm.invalid) {
      this.loader = true;
      let objShipping = {
        "shippingAddress": {
          "attentionName": this.ShipToForm.value.attention,
          "address": this.ship_to()
        }
      }
      this.salesOrderService.ValidateAddress(this.OrderPartForm.value.companyId, this.OrderPartForm.value.shippingOption == 1 ? 1 : 8, objShipping)
        .subscribe({
          next: (data: any) => {
            this.loader = false;
            this.OrderPartForm.patchValue({ isAddressValidate: false })
            if (data != null && data.verifiedAddresses != null) {

              this.AddressValidatedAddress = data.verifiedAddresses[0].address
              this.OrderPartForm.value.isAddressValidate == true ? $("#validAddress").hide() : $("#validAddress").show();
            } else {
              this.constants.SwalModal(data[0].messages[0].message,  data[0].status);
            }
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  // Address Validation Start
  SuggestedShipToAddress() {
    if (this.OrderPartForm.value.isAddressValidate) {
      this.ShipToForm.patchValue({
        addressLine1: this.AddressValidatedAddress.addressLineOne,
        addressLine2: this.AddressValidatedAddress.addressLineTwo,
        city: this.AddressValidatedAddress.cityName,
        stateId: this.StateList.filter(s => s.abbreviation == this.AddressValidatedAddress.stateCode)[0].id,
        zip: this.AddressValidatedAddress.postalCode,
      })
    } else {
      this.OrderPartForm.patchValue({ isAddressValidate: true })
    }
    $("#validAddress").hide();
  }
  DefaultAddress(value) {
    this.OrderPartForm.patchValue({ isAddressValidate: value })
  }
  // Payment Section Start
  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      this.loader = false;
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }
  async Pay(IsComplete, AMT) {
    this.loader = true;
    const token = await this.tokenize(card);
    this.ApprovePayment(token, AMT, IsComplete);
  }
  async ApprovePayment(token, AMT, IsComplete) {
    const random_uuid = uuidv4();
    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
          const r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }
    let obj = {
      idempotency_key: random_uuid, source_id: token, amount_money: { amount: parseFloat(((AMT) * 100).toFixed(2)), currency: "USD" },
      "autocomplete": false, "paymentType": this.OrderPartForm.value.paymentType
    };
    this.loader = true;
    this.salesOrderService.ApprovePayment(obj, this.OrderPartForm.value.id)
      .subscribe({
        next: (data: any) => {
          if (data.errors == null) {
            if (data.payment.status === this.constants.Approved || data.payment.status === this.constants.Completed) {
              this.OrderPartForm.patchValue({ paymentStatus: this.constants.Approved })
              this.PaymentLogList = data.salesOrderPaymentLog.length > 0 ? data.salesOrderPaymentLog.sort((low, high) => low.id - high.id) : [];
            }
            if (IsComplete) {
              this.CompletePayment(AMT);
            } else {
              this.ClosePaymentInfoModal()
              this.ClosePaymentModal();
              this.loader = false;
              this.FormValidationRemoveClr(true);
              this.constants.SwalModal(this.constants.Approved, this.constants.paymentApproved);
            }
          } else {
            this.loader = false;
            this.constants.SwalModal(data.errors[0].code, data.errors[0].detail);
          }

        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.APIError, this.constants.SomethingWentWrong);
        },
      });
  }

  //Payment Complete
  CompletePayment(Amount) {
    if (this.GetBalanceDue() >= Amount) {
      this.loader = true;
      const payment = {
        Type: this.OrderPartForm.value.paymentType,
        orderid: this.OrderPartForm.value.id,
        customerid: this.OrderPartForm.value.customerId,
        paymentType: this.OrderPartForm.value.paymentType,
        amount: Amount * 100
      }
      this.salesOrderService.CompletePayment(payment)
        .subscribe({
          next: (data: any) => {
            this.ClosePaymentInfoModal()
            this.ClosePaymentModal()
            data.data != null ? this.PaymentTypeResponse(data.data) : this.loader = false;
          },
          error: (err) => {
            this.loader = false
            this.ClosePaymentInfoModal()
            this.ClosePaymentModal()
            this.constants.SwalModal(this.constants.APIError, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  PaymentTypeResponse(value: any) {
    if (value.captureAuthorisedPaymentResponse != null && this.OrderPartForm.value.paymentType == 'PayPal') {
      if (value.captureAuthorisedPaymentResponse.status === this.constants.Completed && value.salesOrderPaymentLog != null) {
        this.PaymentLogList = value.salesOrderPaymentLog.length > 0 ? value.salesOrderPaymentLog.sort((low, high) => low.id - high.id) : [];
        this.constants.SwalModal(this.constants.Success, this.constants.paymentSuccess);
      }
      if (value.captureAuthorisedPaymentResponse.status === this.constants.Failed || value.captureAuthorisedPaymentResponse.status === this.constants.Canceled) {
        this.constants.SwalModal('Payment', value.payment.status);
      }
    } else if (value.payment != null && this.OrderPartForm.value.paymentType == 'Credit Card') {
      if (value.payment.status === this.constants.Completed && value.salesOrderPaymentLog != null) {
        this.PaymentLogList = value.salesOrderPaymentLog.length > 0 ? value.salesOrderPaymentLog.sort((low, high) => low.id - high.id) : [];
        this.constants.SwalModal(this.constants.Success, this.constants.paymentSuccess);
      }
      if (value.payment.status === this.constants.Failed || value.payment.status === this.constants.Canceled) {
        this.constants.SwalModal('Payment', value.payment.status);
      }
    }
    this.selectedRow(this.OrderPartForm.value.id, this.OrderPartForm.value.customerId);
  }
  TotalCompletedAmount(logList): number {
    let amt = 0;
    if (logList.filter(x => x.status == this.constants.Completed).length > 0) {
      amt = logList.filter(x => x.status == this.constants.Completed).reduce((acc, val) => acc += val.amountMoney, 0) / 100;
    }
    return amt;
  }
  GetCountryCode(countryId: number) {
    return (this.CountryList.length > 0 && countryId != null && this.CountryList.filter(x => x.id == countryId).length > 0) ? this.CountryList.filter(x => x.id == countryId)[0].countryCode.trim() : ''
  }
  GetStateCode(stateId: number) {
    return (this.StateList.length > 0 && Number(stateId) > 0 && this.StateList.filter(x => x.id == stateId).length > 0) ? this.StateList.filter(x => x.id == stateId)[0].abbreviation.trim() : ''
  }
  //Payment Section End
  IsCollapseAll(value: boolean) {
    this.OrderPart.value.forEach(ql => {
      ql.Question.forEach(IsShow => {
        IsShow.IsShow = value;
      });
    });
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value });
  }
  GetUOM(Id: number) {
    return this.constants.UnitLength.filter(x => x.id == Id)[0].name;
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value === null || value === undefined || value === '') {
      result = true;
    }
    return result;
  }
  // Pdf Generated for LOG Start
  DownloadPDF(IsPrint: boolean, IsEmail: number) {
    let salesOrderVm = {
      salesOrder: this.OrderPartForm.getRawValue(),
      ShipAddress: this.ShipToForm.value,
      BillAddress: this.BillToForm.value
    }
    this.AddHistoryFlyOut(JSON.stringify(salesOrderVm), IsPrint, IsEmail, false);
  }

  GetCompanyById(compId: number) {
    this.companyService.GetCompanyById(compId).subscribe(
      (data: any) => {
        this.CompanyDetail = data.data.company;
      }
    ), (err) => {
      this.loader = false;
    };
  }
  validateForm() {
    if (this.OrderPartForm.value.statusId == 10) {
      this.Save(false, false, false, false);
    } else {
      this.ValidationMsgList();
    }
  }
  ValidateFormAddress(Controls: any, type: string) {
    const messages: string[] = [];
    messages.push('<li style="list-style: block; list-style-type: disc;">Missing ' + type + ' Information</li>');
    messages.push(`<ul>`);
    Object.keys(Controls.controls).forEach(controlName => {
      const control = Controls.get(controlName);
      if (control && control.invalid) {
        messages.push(`<li style="list-style: block; list-style-type: disc;">${controlName}</li>`);
      }
    });
    messages.push(`</ul>`);
    return messages;
  }
  ValidationMsgList() {
    let BillMsg = [];
    let ShipMsg = [];
    if (this.BillToForm.invalid) {
      this.IsBillToItem = false;
      BillMsg = this.ValidateFormAddress(this.BillToForm, 'Bill To')
    }
    if (this.ShipToForm.invalid) {
      this.IsShipToItem = false;
      ShipMsg = this.ValidateFormAddress(this.ShipToForm, 'Ship To')
    }
    const messages = [...BillMsg, ...ShipMsg];
    if (this.OrderPart.value.length == 0) {
      this.IsValidItem = false;
      messages.push('<li style="list-style: block; list-style-type: disc;">No Items on the List</li>');
    }
    if (messages.length > 0) {
      this.messageList = messages.join('');
      $("#SOFormValidationModal").show();
    } else {
      this.Save(false, false, false, false);
    }
  }
  SaveAsdraft() {
    this.OrderPartForm.patchValue({ statusId: 10 })
    this.FormValidationRemoveClr(true);
    this.Save(false, false, false, false);
  }
  FormValidationRemoveClr(result) {
    this.IsValidItem = result;
    this.IsBillToItem = result;
    this.IsShipToItem = result;
  }
}

