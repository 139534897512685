<div class="overcstfixedsidebar active" *ngIf="HistoryFlyOutVal.IsOpenFlyOut || HistoryFlyOutVal.IsEmail==1"></div>
<div class="sidebar_trackingnum active left-sidebar" *ngIf="HistoryFlyOutVal.IsOpenFlyOut">
    <div class="loading lodingtext" *ngIf="loader">
        <!-- <p>{{lodingtext}}</p> -->
    </div>
    <!-- -=------------------------------- title of order history --------------- -->
    <div class="titlecstmsidebar">
        <h2 class="top-heading">Order History</h2>
        <h4>Scroll to view the Sales Order History.</h4>
    </div>
    <!-- -=------------------------------- title of order history --------------- -->
    <div class="table-order-history">
        <table class="table-material border-0">
            <thead class="table-responsive-header">
                <tr>
                    <th>Document</th>
                    <th>Order Number</th>
                    <th>Order Status</th>
                    <th>Expiration</th>
                    <th>Hold Reason</th>
                    <th>Hold Until Date</th>
                    <th>Modified Date</th>
                    <th>Changed By</th>
                </tr>
            </thead>
            <tbody class="table-responsive-body" *ngIf="OrderHistoryList.length>0">
                <tr *ngFor="let h of OrderHistoryList; let i = index;">
                    <td data-label="Document">
                        <span *ngIf="h.status != 'Draft'">
                            <mat-icon (click)=" BarcodeGenerate(h.document,h.orderNumber,true,false,h.status,false,2)">
                                insert_drive_file</mat-icon>
                        </span>
                    </td>
                    <td data-label="Order Number">{{h.orderNumber}}</td>
                    <td data-label="Order Status">{{h.status}}</td>
                    <td data-label="Expiration">{{h.expiration | date :'M/d/yy, h:mm a'}}</td>
                    <td data-label="Hold Reason">{{h.holdReason}}</td>
                    <td data-label="Hold Until Date">{{h.holdUntilDate | date :'M/d/yy, h:mm a'}}</td>
                    <td data-label="Modified Date">{{h.createdDate | date :'M/d/yy, h:mm a'}}</td>
                    <td data-label="Changed By">{{h.createdBy}}</td>
            </tbody>
        </table>
    </div>
    <div class="footer-btn">
        <button type="button" class="save-btn btn update-btn" (click)="CloseFlyOut()">Close</button>
    </div>
</div>
<!-- BarCode work -->
<div hidden>
    <svg #barcode id="barcode"></svg>
</div>