<section class="content-section-2" id="new-layout">
    <app-loader [loader]="loader"></app-loader>
    <app-breadcrumb [previousPage]="previousPage" [currentPage]="currentPage"></app-breadcrumb>

    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section">

            </div>
        </div>
    </div>
</section>
