<section class="content-section-2" id="new-layout">
    <div class="loading lodingtext" *ngIf="loader">
    </div>
    <div class="top-bredcumps">
        <div class="cstmbreadcum paddingmng-right">
            <div class="page_title_cstms">
                <li *ngIf="IsLocationList">
                    <a (click)="BackToList()" class="backbtns">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <span>Back to List</span>
                    </a>
                </li>
                <li>
                    <span *ngIf="!IsLocationList">WareHouse Location List</span>
                    <span *ngIf="IsLocationList">WareHouse Location Details</span>
                </li>
            </div>
            <div class="top-button" *ngIf="IsLocationList">
                <button class="cancel-button" (click)="Cancel()">
                    <span>Cancel</span>
                </button>
                <button class="save-button" (click)="Save()">
                    <span>Save</span>
                </button>
            </div>
        </div>
    </div>
    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section">
                <div class="white-box1 cust-label" *ngIf="!IsLocationList">
                    <div class="card-desc1">
                        <div class="row detailSection">
                            <div class="col-md-12">
                                <div class="row ">
                                    <div class="col-xl-4 col-lg-4 col-sm-8 col-8">

                                    </div>
                                    <div class="col-xl-6 col-lg-5 col-sm-12 col-12">

                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-sm-4 col-4 new-company-button">
                                        <button class="save-btn btn update-btn">Create Location</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sales-order-table">
                    <!-- -----------------table section --- -->
                    <div class="heading-section-1" *ngIf="!IsLocationList">
                        <mat-table [dataSource]="LocationDS" class="table-material table-responsive">
                            <ng-container matColumnDef="menu">
                                <mat-header-cell *matHeaderCellDef> Menu </mat-header-cell>
                                <mat-cell *matCellDef="let element;" (click)="$event.stopPropagation()"
                                    data-label="Menu">
                                    <button mat-icon-button [matMenuTriggerFor]="DltLocFor" #DltLoc="matMenuTrigger"
                                        (click)="DltLoc.openMenu()">
                                        <mat-icon class="icon_fix">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #DltLocFor="matMenu">
                                        <span (mouseleave)="DltLoc.closeMenu()">
                                            <button mat-menu-item (click)="DeleteWareHouseLocation(element)">
                                                Delete Location
                                            </button>
                                        </span>
                                    </mat-menu>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="item">
                                <mat-header-cell *matHeaderCellDef> Item </mat-header-cell>
                                <mat-cell *matCellDef="let element;let i = index;" data-label="Item"> {{i+1}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="locationName">
                                <mat-header-cell *matHeaderCellDef> Location Name </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Location Name">
                                    {{element.locationName}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="locationType">
                                <mat-header-cell *matHeaderCellDef>Location Type </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Location Type">{{element.locationType}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="locations">
                                <mat-header-cell *matHeaderCellDef> Locations </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Locations">
                                    {{element.noOfLocations}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Status"> Active
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="constants.WareHouseLocationColumn sticky: true"
                                class="header-style table-responsive-header"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: constants.WareHouseLocationColumn;let i= index;"
                                (click)="selectedRow(row.id)"></mat-row>
                        </mat-table>
                    </div>
                </div>
                <!-- ---------saleds order details page start here------------------------------- -->
                <div class="content-section-2" *ngIf="IsLocationList">
                    <div class="container-fluid">
                        <div class="main-section">
                            <div class="section-2 details-section">
                                <div class="top-heading d-flex align-items-center gap-3 mb-3">
                                    <h1>Location Information</h1>
                                </div>
                                <div class="row cards-padding">
                                    <div class="col-xl-6">
                                        <div class="row">
                                            <div class="col-md-12 col-12 first-card mb-sm-12">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Location Name</mat-label>
                                                    <input matInput>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="row">
                                            <div class="col-md-6 col-6 first-card mb-sm-6 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Location Type</mat-label>
                                                    <mat-select>
                                                        <mat-option *ngFor="let lt of LocationType"
                                                            [value]="lt.id">{{lt.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-6 second-card mb-sm-6 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Status</mat-label>
                                                    <mat-select>
                                                        <mat-option *ngFor="let statusList of StatusList"
                                                            [value]="statusList.value">
                                                            {{statusList.type}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="row">
                                            <div class="col-12 first-card md-mb-3">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Description</mat-label>
                                                    <input matInput>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ---------------third  section------------------ -->
                            <div class="third-section mb-2">
                                <div class="tabs-section d-flex justify-content-between">
                                    <div class="tab-section">
                                        <div class="cstm-radiosearch">
                                            <div class="filter_cstm_radio" (change)="ChangeType($event.target.id)">
                                                <input type="radio" name="status_filter" id="LocationDetails">
                                                <label for="LocationDetails">
                                                    <mat-icon>check</mat-icon>
                                                    <span>Location Details</span>
                                                </label>
                                                <input type="radio" name="status_filter" id="LocationGenerator" checked>
                                                <label for="LocationGenerator">
                                                    <mat-icon>check</mat-icon>
                                                    <span>Location Generator</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- -----------------Location Details Start--------------- -->
                            <div class="section-2 details-section" *ngIf="FilterType == 'LocationDetails'">
                                <div class="card-desc example-container cstm_scrollbar_theme company-table">
                                    <div class="top-heading d-flex align-items-center gap-3 mb-3">
                                        <h1>Location Details</h1>
                                    </div>
                                    <div class="sales-order-table">
                                        <mat-table [dataSource]="LocationDetails">
                                            <ng-container matColumnDef="address">
                                                <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                                                <mat-cell *matCellDef="let element;let i = index;" data-label="Item">
                                                    {{element.name}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="locationDescription">
                                                <mat-header-cell *matHeaderCellDef> Location Description
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" data-label="Location Description">
                                                    {{element.description}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="lastUpdated">
                                                <mat-header-cell *matHeaderCellDef>Last Updated </mat-header-cell>
                                                <mat-cell *matCellDef="let element" data-label="Location Type">
                                                    {{element.createdDate | date : 'MM/dd/yyyy'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="status">
                                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                                <mat-cell *matCellDef="let element" data-label="Status">
                                                    <mat-slide-toggle color="primary" [checked]="element.isActive">
                                                    </mat-slide-toggle>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="constants.WareHouseLocationDetailColumn sticky: true"
                                                class="header-style table-responsive-header"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: constants.WareHouseLocationDetailColumn;let i= index;"></mat-row>
                                        </mat-table>
                                    </div>
                                </div>
                            </div>
                            <!-- -----------------Location Details End--------------- -->

                            <!-- -----------------Location Generoator Start--------------- -->
                            <div class="section-2 details-section" *ngIf="FilterType != 'LocationDetails'">
                                <div class="card-desc example-container">
                                    <div class="row cards-padding">
                                        <div class="top-heading d-flex align-items-center gap-3 mb-3">
                                            <h1>Location Generator</h1>
                                        </div>
                                        <div class="col-xl-12">
                                            <div class="row">
                                                <div class="col-md-4 col-4 first-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label> Level 1 Name</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Number of Locations</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Starting Letter or Number</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 col-4 first-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Level 2 Name</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Number of Locations</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Starting Letter or Number</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 col-4 first-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Level 3 Name</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Number of Locations</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Starting Letter or Number</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 col-4 first-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Level 4 Name</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Number of Locations</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-md-4 col-4 second-card mb-sm-4 select-arrow-change-icon">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Starting Letter or Number</mat-label>
                                                        <input matInput>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer border-0">
                                        <button type="button" class="save-btn btn update-btn"
                                            (click)="GenerateLocation()">Generate Location</button>
                                    </div>
                                </div>
                            </div>
                            <!-- -----------------Location Generoator End--------------- -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>