import { Component } from '@angular/core';

@Component({
  selector: 'app-vendors-reportrt',
  templateUrl: './vendors-reportrt.component.html',
  styleUrls: ['./vendors-reportrt.component.css']
})
export class VendorsReportrtComponent {

}
