<!--Staging -->
<!-- <div class="container" style="margin-top:90px;max-width:1252px"> 
    <iframe title="911Vehicle-TRPT_BI_reports_v6" width="1550" height="750" src="https://app.powerbi.com/reportEmbed?reportId=41f28a2e-6500-4818-ba74-9150f70b941c&autoAuth=true&ctid=116a7504-a959-4b83-871f-7084cf3b4ee9" frameborder="0" allowFullScreen="true"></iframe>
</div> -->

<!--Production -->
<!-- <div class="container" style="margin-top:90px;max-width:1252px"> 
    <iframe title="911Vehicle-TRPT_BI_reports_v6" width="1550" height="750" src="https://app.powerbi.com/reportEmbed?reportId=70275134-5ed7-466d-989c-12b9a5bd3c66&autoAuth=true&ctid=116a7504-a959-4b83-871f-7084cf3b4ee9" frameborder="0" allowFullScreen="true"></iframe>
</div> -->
<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<section class="r_report content-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <form [formGroup]="reportFormforSO">
                    <div class="p-2 blue-txt text-center shadow" style="border-radius: 10px;">
                        <p>Total Profit</p>
                        <p style="font-weight: bold">{{(this.totalPartProfit==0?'':this.totalPartProfit)|currency}}</p>
                    </div>
                    <div class="mt-2">
                        <mat-card class="example-card map-box-custom" appearance="outlined" style="height: 154px;">
                            <mat-card-content style="padding: 0px 5px;">
                                <div class="form-group dflex">
                                    <input class="form-control-input" type="radio" name="allDateRange" checked="checked"
                                        formControlName="allDateRange" (change)="changeAllDateOrder($event)"
                                        id=dateRange1 value="">
                                    <label class="form-control-label" for="dateRange1">All Order</label>
                                </div>
                                <div class="form-group dflex mb-0">
                                    <input class="form-control-input" type="radio" name="allDateRange"
                                        formControlName="allDateRange" (change)="changeAllDateOrder($event)"
                                        id=dateRange value="allDateRange">
                                    <label class="form-control-label" for="dateRange">Order Date Range</label>
                                </div>
                                <div class="d-flex mt-2 flex-column" *ngIf="IsDateRange==true">
                                    <input type="date" style="margin: 4px;" matInput class="customInput mr-1"
                                        [(ngModel)]="fromDate" formControlName="fromDate">
                                    <input type="date" style="margin: 4px;" matInput class="customInput mt-1"
                                        [(ngModel)]="toDate" formControlName="toDate">
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <mat-card class="example-card" style="height: 72px; padding-top: 7px;">
                        <mat-card-content style="padding: 0 10px">
                            <mat-form-field appearance="outline">
                                <mat-label>Order Status</mat-label>
                                <mat-select name='statusId' id="statusvalues" [(ngModel)]="statusIdVal"
                                    formControlName="statusIdVal" (change)="statusvalues($event)">
                                    <mat-option *ngFor="let item of ShippingStatus" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="example-card map-box-custom shadow" appearance="outlined"
                        style="height: 57px; padding-top: 8px;">
                        <mat-card-content style="padding: 0;">
                            <button class="form-control" (click)="GetSalesOrderReport()">Search <span> <i
                                        class="fas fa-search cpointer"></i></span> </button>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
            <div class="col-md-10 right-blk">
                <div class="row three-map-blk">
                    <div class="col-md-4">
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>Labor Revenue and Part Revenue</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-md-4">
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>GrossProfit</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-md-4">
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>Count Of</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <!--New Material Table-->

                    <label style="margin-left: 500px;">Sales Order Report</label>
                    <div class="col-12 example-container company-table">
                        <table mat-table [dataSource]="dataSource" class="cstm_scrollbar_theme">

                            <!-- SO Column -->
                            <ng-container matColumnDef="so">
                                <th mat-header-cell *matHeaderCellDef> SO# </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <!-- Labor Revenue Column -->
                            <ng-container matColumnDef="lbrRevenue">
                                <th mat-header-cell *matHeaderCellDef> Labor Revenue </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalLaborPriceExt==0?'':element.totalLaborPriceExt|currency}} </td>
                            </ng-container>

                            <!-- Labor Cost Column -->
                            <ng-container matColumnDef="lbrCost">
                                <th mat-header-cell *matHeaderCellDef> Labor Cost </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalLaborProfit==0?'':element.totalLaborProfit|currency}} </td>
                            </ng-container>

                            <!-- Part Revenue Column -->
                            <ng-container matColumnDef="prtRevenue">
                                <th mat-header-cell *matHeaderCellDef> Parts Revenue</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalPartCostExt==0?'':element.totalPartCostExt|currency}} </td>
                            </ng-container>

                            <!-- Parts Cost Column -->
                            <ng-container matColumnDef="partCost">
                                <th mat-header-cell *matHeaderCellDef> Parts Cost </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalPartPriceExt==0?'':element.totalPartPriceExt|currency}} </td>
                            </ng-container>

                            <!-- Labor Profit  Column -->
                            <ng-container matColumnDef="lbrProfit">
                                <th mat-header-cell *matHeaderCellDef> Labor Profit </th>
                                <td mat-cell *matCellDef="let element"> {{element.laborProfit==0?'':element.laborProfit
                                    |currency}} </td>
                            </ng-container>

                            <!-- Labor Margin Column -->
                            <ng-container matColumnDef="lbrMargin">
                                <th mat-header-cell *matHeaderCellDef> Labor Margin </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.laborProfitMarkUp==0?'':element.laborProfitMarkUp |currency|currency}}
                                </td>
                            </ng-container>

                            <!--Parts profit-->
                            <ng-container matColumnDef="prtProfit">
                                <th mat-header-cell *matHeaderCellDef> Parts Profit </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalPartProfit==0?'':element.totalPartProfit|currency}} </td>
                            </ng-container>

                            <!--Parts Margin-->
                            <ng-container matColumnDef="partMargin">
                                <th mat-header-cell *matHeaderCellDef> Parts Margin </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.totalPartProfitMarkUp==0?'':element.totalPartProfitMarkUp|currency}} </td>
                            </ng-container>

                            <!--Total Revenue-->
                            <ng-container matColumnDef="totalRevenue">
                                <th mat-header-cell *matHeaderCellDef> Total Revenue </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.partPriceExt==0?'':element.partPriceExt +
                                    element.laborPriceExt|currency}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="10" class="error text-center" style="height: 47px">
                                    No data found !
                                </td>
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                    <div *ngIf="orderList!=null">
                        <tr>
                            <td><span style="margin-left: 20px; margin-top: 4px; font-size: 13px;">Total:</span></td>
                            <td><span
                                    style="margin-left: 70px; font-size: 13px;">{{(totalLaborPriceExt==0?'':totalLaborPriceExt)|currency}}</span>
                            </td>
                            <td><span
                                    style="margin-left: 83px; font-size: 13px;">{{(totalLaborCostExt==0?'':totalLaborCostExt)|currency}}</span>
                            </td>
                            <td><span
                                    style="margin-left: 51px; font-size: 13px;">{{(totalPartPriceExt==0?'':totalPartPriceExt)|currency}}</span>
                            </td>
                            <td><span
                                    style="margin-left: 96px; font-size: 13px;">{{(totalPartCostExt==0?'':totalPartCostExt)|currency}}</span>
                            </td>
                            <td><span>{{(totalLaborProfit==0?'':totalLaborProfit)|currency}}</span></td>
                            <td><span>{{(totalLaborProfitMarkUp==0?'':totalLaborProfitMarkUp)|currency}}</span></td>
                            <td><span
                                    style="margin-left: 323px;font-size: 13px; ">{{(totalPartProfit==0?'':totalPartProfit)|currency}}</span>
                            </td>
                            <td><span
                                    style="margin-left: 64px; font-size: 13px;">{{(totalPartProfitMarkUp==0?'':totalPartProfitMarkUp)|currency}}</span>
                            </td>
                            <td class="text-right"><span
                                    style="margin-left: 87px; font-size: 13px;">{{(totalRevenue==0?'':totalRevenue)|currency}}</span>
                            </td>
                        </tr>
                    </div>

                    <!--Table End-->
                </div>
            </div>
        </div>
    </div>
</section>