<div class="row mb-3 inventory-row">
    <div class="col-xl-11 col-12">
        <div class="row">
            <div class="col-md-3 col-6 first-card">
                <mat-card class="example-card p-0">
                    <mat-card-content>
                        <div class="col-lg-12 main-card">
                            <span>On Hand</span>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{OnHand}}</div>
                                <div class="count-subtitle"><span>Total Quantity</span></div>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{OnHandTotal | currency}}</div>
                                <div class="count-subtitle"><span>Total Cost</span></div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-md-3 col-6 mb-3 second-card">
                <mat-card class="example-card p-0">
                    <mat-card-content>
                        <div class="col-lg-12 main-card">
                            <span>Available</span>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{Available}}</div>
                                <div class="count-subtitle"><span>Total Quantity</span></div>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{AvailableTotal | currency}}</div>
                                <div class="count-subtitle"><span>Total Cost</span></div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-md-3 col-6 mb-3 third-card">
                <mat-card class="example-card p-0">
                    <mat-card-content>
                        <div class="col-lg-12 main-card">
                            <span>Committed</span>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{Committed}}</div>
                                <div class="count-subtitle"><span>Total Quantity</span></div>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{CommittedTotal | currency}}</div>
                                <div class="count-subtitle"><span>Total Cost</span></div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-md-3 col-6 mb-3 fourth-card">
                <mat-card class="example-card p-0">
                    <mat-card-content>
                        <div class="col-lg-12 main-card">
                            <span>Back Ordered</span>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{BackOrdered}}</div>
                                <div class="count-subtitle"><span>Total Quantity</span></div>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                <div class="count-title">{{BackOrderedTotal | currency}}</div>
                                <div class="count-subtitle"><span>Total Cost</span></div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>