import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.css']
})
export class ServicesListComponent implements OnInit {
  loader: boolean = false;
  previousPage: string = '';
  currentPage: string = 'Services List';

  constructor() {

  }
  ngOnInit(): void {

  }
}
