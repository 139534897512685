<div class="overcstfixedsidebar active"></div>
<div class="sidebar_trackingnum active">
    <div class="loading lodingtext" *ngIf="loader">
    </div>
    <div class="titlecstmsidebar">
        <h2 class="top-heading">Add Parts</h2>
        <h4>Search for parts to add your sales order.</h4>
    </div>
    <div class="selected-section d-flex gap-3">
        <div class="checkbox-section">
            <mat-checkbox (change)="ViewSelected($event.checked)">
            </mat-checkbox>
            <span> View Selected</span>
        </div>
        <div class="search-tab-section">
            <div class="search_filter-alltype select-arrow-change-icon">
                <div><app-category-layout (CategoryIds)="CategoryIds($event)"
                        [CompanyId]="CompanyId"></app-category-layout>
                </div>
                <input type="text" class="searchalltype" (input)="Search($event.target.value)" placeholder="Search">
                <button class="btnsearch_alltype" type="button">
                    <mat-icon>search111</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <!-- --------------------Add Parts table section -------------------- -->
    <div class="table-order-part">
        <form [formGroup]="partsForm" (scroll)="SOPartListScroll($event)">
            <table class="table-material border-0">
                <thead class="table-responsive-header">
                    <tr>
                        <th>Select</th>
                        <th>Quantity</th>
                        <th>Part Number</th>
                        <th class="text-start">Description</th>
                        <th>On Hand</th>
                        <th>Available</th>
                        <th>B/O</th>
                        <th>UOM</th>
                        <th>
                            <span *ngIf="this.pageName == 'SalesOrder'">
                                Price
                            </span>
                            <span *ngIf="this.pageName == 'PurchaseOrder'">
                                Unit Cost
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody class="table-responsive-body" formArrayName="parts"
                    *ngFor="let p of parts.controls; let i = index;">
                    <tr [formGroupName]="i">
                        <td data-label="Select">
                            <input matCheckbox type="checkbox" formControlName='Select'
                                (change)="ChangePartQunty($event,i)" style="width:55%;height:34%;" />
                        </td>
                        <td data-label="Quantity" class="change-width">
                            <mat-form-field appearance="outline" class="custom-filed">
                                <input matInput type="number"
                                    [ngClass]="(partsForm.value.parts[i].Select && !this.parts.controls[i].touched) ? 'textHilight' : 'textHilightNone'"
                                    (input)="ChangeQnty($event.target,i)"
                                    [readonly]="partsForm.value.parts[i].Select==undefined" formControlName='Quantity'>
                            </mat-form-field>
                        </td>
                        <td data-label="Part Number">{{partsForm.value.parts[i].partNumber}}</td>
                        <td data-label="Description" class="text-start">{{partsForm.value.parts[i].partName}}</td>
                        <td data-label="On Hand">{{partsForm.value.parts[i].onHand}}</td>
                        <td data-label="Available">{{partsForm.value.parts[i].available}}</td>
                        <td data-label="B/O">{{partsForm.value.parts[i].bo}}</td>
                        <td data-label="UOM">{{GetUOM(partsForm.value.parts[i].uom)}}</td>
                        <td data-label="Unit Price"> {{partsForm.value.parts[i].unitPrice | currency}}</td>
                    </tr>
                </tbody>

            </table>
        </form>
    </div>
    <div class="footer-btn">
        <button type="button" class="save-btn btn update-btn" (click)="SaveFlyOut()">
            Save and Close
        </button>
        <button type="button" class="cancel-btn btn update-btn" (click)="ClosePartsFlyOut()">
            Cancel
        </button>
    </div>
</div>