import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
  @Input() previousPage: string = '';
  @Input() currentPage: string = '';
  @Input() isCancel: boolean = false;
  @Input() isSave: boolean = false;
  @Output() backbtnClick = new EventEmitter<any>();
  @Output() cancelbtnClick = new EventEmitter<any>();
  @Output() savebtnClick = new EventEmitter<any>();


  back() {
    this.backbtnClick.emit();
  }
  cancel() {
    this.cancelbtnClick.emit();
  }
  save() {
    this.savebtnClick.emit();
  }
}
