<section class="content-section-2" id="new-layout">
    <app-loader [loader]="loader"></app-loader>
    <app-breadcrumb [previousPage]="previousPage" [currentPage]="currentPage" [isCancel]="true" [isSave]="true"
        (backbtnClick)="Back()" (cancelbtnClick)="Cancel()" (savebtnClick)="Save()"></app-breadcrumb>

    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section">
                <form [formGroup]="form">
                    <div class="parent">
                        <div class="partinfo border-backgroud">
                            <div class="div1 part-heading">
                                <span>Part Information</span>
                            </div>
                            <div class="div2">
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Part Number</mat-label>
                                        <input matInput formControlName="partNo" type="text" placeholder="Part Number">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>GTIN/UPC</mat-label>
                                        <input matInput formControlName="partNo" type="text" placeholder="GTIN/UPC">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Inventory Type</mat-label>
                                        <mat-select formControlName="inventoryType" class="select-arrow-change-icon"
                                            placeholder="Inventory Type">
                                            <mat-option *ngFor="let item of invTypeList" [value]="item.typeId">
                                                {{item.typeValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Unit of Measure</mat-label>
                                        <mat-select formControlName="unitofMeasureId" class="select-arrow-change-icon"
                                            placeholder="Unit of Measure">
                                            <mat-option></mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Status</mat-label>
                                        <mat-select formControlName="isActive" class="select-arrow-change-icon"
                                            placeholder="Status">
                                            <mat-option *ngFor="let x of statusList" [value]="x.value">
                                                {{x.type}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div3">
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Part Description</mat-label>
                                        <input matInput formControlName="name" type="text"
                                            placeholder="Part Description">
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Purpose</mat-label>
                                        <mat-select formControlName="purpose" class="select-arrow-change-icon"
                                            placeholder="Purpose">
                                            <mat-option [value]="0">Select One</mat-option>
                                            <mat-option *ngFor="let x of purposeList" [value]="x.key">
                                                {{x.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div4">
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Part Category</mat-label>
                                        <mat-select [(value)]="selectedMulti" class="select-arrow-change-icon"
                                            (selectionChange)="OnParentCatChange($event)" multiple
                                            disableOptionCentering>
                                            <ng-container *ngTemplateOutlet="
                                                  recursiveOptionsMulti;
                                                  context: { $implicit: programs, depth: 1 } ">
                                            </ng-container>

                                            <ng-template #recursiveOptionsMulti let-list let-depth="depth">
                                                <ng-container *ngFor="let program of list">
                                                    <mat-option [value]="program.value" [ngStyle]="{
                                                      'padding-left.px': depth <= 1 ? null : 16 * depth
                                                    }">
                                                        {{ program.text }}
                                                    </mat-option>

                                                    <ng-container
                                                        *ngIf="!!program.children && program.children.length > 0">
                                                        <ng-container *ngTemplateOutlet="
                                                        recursiveOptionsMulti;
                                                        context: { $implicit: program.children, depth: depth + 1 }
                                                      ">
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-template>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Source of Part</mat-label>
                                        <mat-select formControlName="source" class="select-arrow-change-icon"
                                            placeholder="Source of Part">
                                            <mat-option *ngFor="let x of sourceList" [value]="x.key">
                                                {{x.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tracking Type</mat-label>
                                        <mat-select formControlName="partTracking" class="select-arrow-change-icon"
                                            placeholder="Tracking Type">
                                            <mat-option *ngFor="let x of trackingList" [value]="x.id">
                                                {{x.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Bussiness Channels</mat-label>
                                        <mat-select formControlName="businessChannel" class="select-arrow-change-icon"
                                            placeholder="Bussiness Channels">
                                            <mat-option [value]="">Select One</mat-option>
                                            <mat-option *ngFor="let x of trackingList" [value]="x.id">
                                                {{x.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="vendorinfo border-backgroud">
                            <div class="div5 part-heading">
                                <span>
                                    Vendor Information
                                </span>
                                <span class="links border-bottom-blue ms-3">
                                    {{form.value.vendorId > 0 ? form.value.vendorName : 'Vendor Name'}}
                                </span>
                                <span class="links ms-3" (click)="AddVendor()">
                                    <a type="button" (click)="AddVendor()">+ Add Vendor</a>
                                </span>
                            </div>
                            <div class="div6">
                                <div class="div6left">
                                    <div class="left6">

                                    </div>
                                    <div class="left6a">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor Name</mat-label>
                                                <input matInput placeholder="Search Vendors" [matAutocomplete]="auto"
                                                    (input)="onVendorInput($event.target.value)">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option *ngFor="let item of filteredVendors"
                                                        [value]="item.vendorName" (click)="getVendor(item)">
                                                        {{item.vendorName}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <span class="material-icons">
                                                    search
                                                </span>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor List Price</mat-label>
                                                <input matInput formControlName="vendorPartCost" type="text"
                                                    placeholder="Vendor List Price">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="left6b">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor Part Number/SKU</mat-label>
                                                <input matInput formControlName="vendorPartNumber" type="text"
                                                    placeholder="Vendor Part Number/SKU">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Unit Cost</mat-label>
                                                <input matInput type="text" placeholder="Unit Cost">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="left6c">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vendor Part Description</mat-label>
                                                <input matInput formControlName="vendorPartName" type="text"
                                                    placeholder="Vendor Part Description">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="left6d">
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Lead Time</mat-label>
                                                <input matInput formControlName="partLeadTime" type="text"
                                                    placeholder="Lead Time">
                                                <mat-select formControlName="leadUnit" class="select-arrow-change-icon">
                                                    <mat-option [value]="0">Days</mat-option>
                                                    <mat-option [value]="1">Weeks</mat-option>
                                                    <mat-option [value]="2">Months</mat-option>
                                                    <mat-option [value]="3">Years</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Reorder Quantity</mat-label>
                                                <input matInput formControlName="reorderAmount" type="text"
                                                    placeholder="Reorder Quantity">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Reorder Point</mat-label>
                                                <input matInput formControlName="reorderQty" type="text"
                                                    placeholder="Reorder Point">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <img src="../../../../../assets/image/CalculatorIcon.svg">
                                        </div>
                                    </div>
                                </div>
                                <div class="div6right">
                                    <div>
                                        Volumne Discount Pricing
                                    </div>
                                    <div>
                                        <div class="row top-heading">
                                            <div class="col-2">
                                                <span class="table-heading">Min Qty</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Max Qty</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Discount Type</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Discount Value</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">Unit Cost</span>
                                            </div>
                                            <div class="col-2">
                                                <span class="table-heading">&nbsp;</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="row" formArrayName="vendorDiscount"
                                                *ngFor="let item of vendorDiscountArray.controls; let i = index;">
                                                <ng-container [formGroupName]="i">
                                                    <div class="col-2">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput type="text" formControlName="minQty">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput type="text" formControlName="qty">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field class="w-100 icon-change-input"
                                                            appearance="outline">
                                                            <mat-select formControlName="discountType">
                                                                <mat-option value="percentage">Percent
                                                                    Discount</mat-option>
                                                                <mat-option value="flat">Fixed Amount
                                                                    Discount</mat-option>
                                                                <mat-option value="fixed_price">Fixed Price for Item
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput type="text" formControlName="discount">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput type="text" formControlName="vendorCost">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-2">
                                                        <mat-icon>
                                                            <mat-icon>cancel</mat-icon>
                                                        </mat-icon>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="text-start">
                                            <span class="links ms-3">
                                                + Add Row
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pricinginfo border-backgroud">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        Customer Pricing Information
                                    </span>
                                    <span class="ms-3">

                                    </span>
                                </div>
                                <div class="pricing">
                                    <div>
                                        <div>
                                            Volumne Pricing Discounts
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="customerDiscount"
                                                    *ngFor="let item of customerDiscountArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="minQty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="qty">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="discount">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="customerCost">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-icon>
                                                                <mat-icon>cancel</mat-icon>
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Role Based Pricing
                                        </div>
                                        <div>
                                            <div class="row top-heading">
                                                <div class="col-2">
                                                    <span class="table-heading">Min Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Max Qty</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Type</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Discount Value</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">Unit Cost</span>
                                                </div>
                                                <div class="col-2">
                                                    <span class="table-heading">&nbsp;</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row" formArrayName="roleBasePricingDiscount"
                                                    *ngFor="let item of roleDiscountArray.controls; let i = index;">
                                                    <ng-container [formGroupName]="i">
                                                        <div class="col-2">
                                                            <mat-form-field class="w-100 icon-change-input"
                                                                appearance="outline">
                                                                <mat-select formControlName="discountType">
                                                                    <mat-option value="percentage">Percent
                                                                        Discount</mat-option>
                                                                    <mat-option value="flat">Fixed Amount
                                                                        Discount</mat-option>
                                                                    <mat-option value="fixed_price">Fixed Price for Item
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="discountValue">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="col-2">
                                                                <mat-form-field class="w-100 icon-change-input"
                                                                    appearance="outline">
                                                                    <mat-select formControlName="discountType">
                                                                        <mat-option value="percentage">Percent
                                                                            Discount</mat-option>
                                                                        <mat-option value="flat">Fixed Amount
                                                                            Discount</mat-option>
                                                                        <mat-option value="fixed_price">Fixed Price for
                                                                            Item
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text"
                                                                    formControlName="customerValue">
                                                            </mat-form-field>
                                                        </div>


                                                        <div class="col-2">
                                                            <mat-form-field appearance="outline">
                                                                <input matInput type="text" formControlName="cost">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-2">
                                                            <mat-icon>
                                                                <mat-icon>cancel</mat-icon>
                                                            </mat-icon>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-start">
                                                <span class="links ms-3">
                                                    + Add Row
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ecominfo border-backgroud">
                            <div>
                                <div class="part-heading">
                                    <span>
                                        eCommerce Information
                                    </span>
                                </div>
                                <div class="ecom1">
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Product Name</mat-label>
                                            <input matInput formControlName="name" type="text"
                                                placeholder="Product Name">
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>eCommerce Status</mat-label>
                                            <mat-select formControlName="eCommerceStatus" class="select-arrow-change-icon"
                                                placeholder="eCommerce Status">
                                                <mat-option value=''>Not Exported</mat-option>
                                                <mat-option value='draft'>Draft</mat-option>
                                                <mat-option value='pending'>Pending Review</mat-option>
                                                <mat-option value='private'>Privately Published</mat-option>
                                                <mat-option value='publish'>Published</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Sync With Wordpress</mat-label>
                                            <mat-select formControlName="wpData" class="select-arrow-change-icon"
                                                placeholder="Sync With Wordpress">
                                                <mat-option value="1">Export § fields to WordPress</mat-option>
                                                <mat-option value="2">Do not Sync § fields</mat-option>
                                                <mat-option value="3">Import § fields from WordPress</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Wordpress ID</mat-label>
                                            <input matInput formControlName="wordpressId" type="text"
                                                placeholder="Wordpress ID">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="ecom2">
                                    <div>
                                        <label class="customLabel">Short
                                            Description</label><sup>§</sup>
                                        <editor apiKey="constants.EditorKeyTinyMCE" formControlName="shortDescription"
                                            [init]="{   
                                            height: 250,
                                            width: auto,
                                            menubar: false,
                                            plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                            toolbar:
                                            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                        }"></editor>
                                    </div>
                                    <div>
                                        <label class="customLabel">Detail
                                            Description</label><sup>§</sup>
                                        <editor apiKey="constants.EditorKeyTinyMCE" formControlName="description"
                                            [init]="{
                                            height: 250,
                                            width: auto,
                                            menubar: false,
                                            plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                            toolbar:
                                            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                        }"></editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>



            </div>
        </div>
    </div>
</section>


<!-- Dialog to Add Vendor  -->
<form [formGroup]="vendorFormCreate">
    <ng-template #vendorDialog>
        <h2 matDialogTitle class="p-0 vendor-header">New Vendor</h2>
        <mat-dialog-content class="mat-content">
            <div class="row">
                <div class="col-12 first-card-1 mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Vendor Name</mat-label>
                        <input matInput type="text" placeholder="Name" formControlName="vendorName" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-12 first-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Address Line</mat-label>
                        <input matInput type="text" placeholder="Address" formControlName="addressLine1" required>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-12 fourth-card select-arrow-change-icon mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Country</mat-label>
                        <mat-select placeholder="Country" formControlName="CountryId"
                            (selectionChange)="GetCountryId($event)" required>
                            <mat-option *ngFor="let country of countryList" [value]="country.id">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 col-12 first-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput type="text" placeholder="City" formControlName="cityId" required>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-12 second-card select-arrow-change-icon mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>State</mat-label>
                        <mat-select placeholder="State" formControlName="stateId" required>
                            <mat-option *ngFor="let state of getStateFromCountry" [value]="state.id">
                                <span *ngIf="state.abbreviation.length != 0">{{state.abbreviation}}</span>
                                <span *ngIf="state.abbreviation.length == 0">{{state.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-12 fourth-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>ZipCode</mat-label>
                        <input matInput type="text" placeholder="ZipCode" formControlName="zipCode" mask="00000-0000"
                            required>
                    </mat-form-field>
                    <mat-error *ngIf="vendorFormCreate.get('zipCode').hasError('minlength')">
                        Enter a valid ZipCode!
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-12 first-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" placeholder="Email" formControlName="email" required>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="vendorFormCreate.get('phoneNumber').hasError('email') || vendorFormCreate.get('phoneNumber').hasError('pattern')">
                        Enter a valid email address
                    </mat-error>
                </div>
                <div class="col-md-4 col-12 fourth-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="text" placeholder="Phone Number" formControlName="phoneNumber"
                            mask="0 (000) 000 0000" required>
                    </mat-form-field>
                    <mat-error *ngIf="vendorFormCreate.get('phoneNumber').hasError('minlength')">
                        Enter a valid Phone Number!
                    </mat-error>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-dialog-close class="btn cancel-btn update-btn mx-2">Cancel</button>
            <button mat-dialog-close class="save-btn btn update-btn" [disabled]="!vendorFormCreate.valid"
                (click)="AddNewVendor()">Save</button>
        </mat-dialog-actions>
    </ng-template>
</form>