import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PartService } from 'src/app/services/part/part.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receiving-details',
  templateUrl: './receiving-details.component.html',
  styleUrls: ['./receiving-details.component.css']
})
export class ReceivingDetailsComponent implements OnInit {
  seletedLocation: any = {};
  loader: boolean = false;
  companyId: number = 0;
  statuslist: any = [];
  poPartStatusList: any = [];
  masterForm: FormGroup;
  userId: string = '';
  countryList: any = [];
  stateList: any = [];
  PartTrackingList: any = [];
  isSerialFlyout: boolean = false;
  flyoutIndex: number = 0;
  // isRecieveShipment: boolean = false;
  poSavedObj: Object = null;
  CompanyWareHouseList: any = [];
  RecLocationList: any = [];
  level1List: any = [];
  level2List: any = [];
  level3List: any = [];
  level4List: any = [];
  seriallotflyoutObj: any = {};
  TrackingObj: any = [];
  logList: any;
  IsHistoryFlyOut: boolean = false;

  constructor(
    private POService: PurchaseOrderService,
    private formBuilder: FormBuilder,
    private constants: ConstantsService,
    private sharedService: SharedService,
    private inventoryService: InventoryService,
    private globalChangeService: GlobalChangeService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private partService: PartService,
    private router: Router
  ) {
    this.userId = localStorage.getItem('userId');
    this.createMasterForm();
    this.getTrackingList();
    this.getStatusList();
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    //this.pdfId = id;
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      if (id > 0) {
        this.getPObyId(this.companyId, Number(id));
      }
      this.getWareHouseWithChild(this.companyId);
      this.getLocationName(this.companyId);
    } else {
      this.popupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.router.navigate(['/receiving'])
    })
  }

  createMasterForm() {
    this.masterForm = this.formBuilder.group({
      id: new FormControl(0),
      companyId: new FormControl(this.companyId),
      vendor: new FormGroup({
        id: new FormControl(0),
        vendorId: new FormControl(0),
        vendorName: new FormControl(''),
        add1: new FormControl(''),
        add2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
        contactName: new FormControl(''),
        phone: new FormControl(''),
        email: new FormControl('')
      }),
      shipToType: new FormControl(''),
      selectedShipTo: new FormControl(''),
      ship: new FormGroup({
        id: new FormControl(0),
        customerId: new FormControl(0),
        wareHouseId: new FormControl(0),
        name: new FormControl(''),
        contactName: new FormControl(''),
        add1: new FormControl(''),
        add2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
        attention: new FormControl({ value: '', disabled: false })
      }),
      statusOld: new FormControl(10),
      status: new FormControl({ value: 10, disabled: false }, Validators.required),
      customerId: new FormControl(''),
      vendorId: new FormControl(''),
      accNo: new FormControl({ value: '', disabled: false }),
      poNumber: new FormControl({ value: '', disabled: true }),
      orderDate: new FormControl({ value: new Date(), disabled: false }),
      confirmationNo: new FormControl({ value: '', disabled: true }),
      confirmationDate: new FormControl({ value: '', disabled: true }),
      buyerId: new FormControl(''),
      zip: new FormControl(''),
      file: new FormArray([]),
      paymentTerm: new FormControl(''),
      shippingTerm: new FormControl(''),
      salesTax: new FormControl(''),
      fobPoint: new FormControl(''),
      carrier: new FormControl(''),
      estShipDate: new FormControl(''),
      vendorNote: new FormControl(''),
      internalNote: new FormControl(''),
      summaryItems: new FormArray([]),
      subTotal: new FormControl(0),
      shipTaxTotal: new FormControl(0),
      salesTaxTotal: new FormControl(0),
      total: new FormControl(0),
      shipCostDetails: new FormArray([]),
      wareHouse: new FormControl(0),
      locationName: new FormControl(0),
      aisle: new FormControl(''),
      section: new FormControl(''),
      shelf: new FormControl(''),
      bin: new FormControl(''),
      userId: this.userId
    });
  }

  getTrackingList() {
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.PartTrackingList = data;
        }
      }
    )
  }

  get filesGet(): FormArray {
    return this.masterForm.controls.file as FormArray
  }

  backToList() {
    this.router.navigate(['/receiving'])
  }

  getStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x => x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
        this.poPartStatusList = data.filter(x => x.isPOPart == true).sort((a, b) => a.poPartOrdering - b.poPartOrdering);
      }
    )
  }

  getStatusKey(id: any) {
    if (this.statuslist.length > 0 && id > 0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  getWareHouseWithChild(companyId: Number) {
    this.companyService.GetWareHouseWithChild(companyId).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.CompanyWareHouseList = data.data;
        }
      }
    )
  }

  getWareHouseValue(id): String {
    if (this.CompanyWareHouseList.length > 0) {
      const wareHouseList = this.CompanyWareHouseList.filter(x => x.id == Number(id));
      if (wareHouseList.length > 0) {
        return wareHouseList[0].name;
      }
    }
    return 'N/A';
  }

  getPOPartStatusKey(id: any) {
    if (this.poPartStatusList.length > 0 && id > 0) {
      const list = this.poPartStatusList.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return 'N/A';
  }

  getPObyId(companyId:number, id: number) {
    this.loader = true;
    this.POService.GetPObyId(companyId, id).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.poSavedObj = data;
        switch(data.statusCode)
        {
          case 200:
            if (this.RecLocationList.length <= 0) {
              this.getLocationName(data.data.companyId);
            }
            if (data.data.locationName > 0) {
              this.setLocationLabels(data.data.locationName);
              this.getLocationLevels(data.data.companyId, data.data.locationName);
            }
            this.setPOData(data);
            this.disableFields();
            break;
          case 404:
            this.popupModal(this.constants.Alert, this.constants.SomethingWentWrong);
            this.router.navigate(['/receiving'])
            break;
        }
      },
      error: (error: any) => {
        this.loader = false;
        this.popupModal(this.constants.APIError, this.constants.APIErrorMsg);
      }
    });
  }

  disableFields() {
    this.masterForm.controls['confirmationNo'].disable();
    this.masterForm.controls['poNumber'].disable();
    this.masterForm.controls['status'].disable();
    this.masterForm.controls['accNo'].disable();
    this.masterForm.controls['orderDate'].disable();
    this.masterForm.controls['confirmationDate'].disable();
  }

  setPOData(data: any) {
    if (data.data != null) {
      this.createMasterForm();
      this.masterForm.patchValue(data.data);

      if (data.data.file.length > 0) {
        data.data.file.forEach((element) => {
          let x = {
            id: element.id,
            poId: element.poId,
            name: element.name,
            type: element.type,
            size: element.size,
            base64: element.base64,
            docType: element.docType,
            isActive: element.isActive
          };
          this.filesGet.push(this.formBuilder.group(x));
        });
      }
      this.masterForm.patchValue({
        statusOld: data.data.status,
        orderDate: data.data.orderDate == '' ? data.data.orderDate : new Date(data.data.orderDate),
        confirmationDate: data.data.confirmationDate == '' ? data.data.confirmationDate : new Date(data.data.confirmationDate),
        estShipDate: data.data.estShipDate == '' ? data.data.estShipDate : new Date(data.data.estShipDate),
      })
      if (data.data.summaryItems.length > 0) {
        data.data.summaryItems.forEach((element, index) => {
          this.getItem(element, element.id);
          this.onRecieving(index);
        });
      }
    }
  }

  getItem(Object: any, id: number): void {
    let summaryItem = this.masterForm.controls.summaryItems as FormArray;
    let disountList: FormArray = new FormArray([]);
    let serialLotList: FormArray = new FormArray([]);
    let receivedetailList: FormArray = new FormArray([]);
    if (Object.vendorDiscount.length > 0) {
      Object.vendorDiscount.forEach(element => {
        disountList.push(this.formBuilder.group({
          id: element.id,
          min: element.min,
          max: element.max,
          discountedCost: element.discountedCost
        }))
      });
    }
    if (Object.receivedetailList.length > 0) {
      Object.receivedetailList.forEach(element => {
        receivedetailList.push(this.formBuilder.group({
          id: new FormControl(id),
          received: new FormControl(element.received),
          tracking: new FormControl(element.tracking),
          serialLot: new FormControl(element.serialLot),
          receiver: new FormControl(element.receiver),
          receivedDate: new FormControl(element.receivedDate),
        }))
      });
    }
    if (Object.serialLotList != null) {
      if (Object.serialLotList.length > 0) {
        Object.serialLotList.forEach(element => {
          serialLotList.push(this.formBuilder.group({
            id: element.id,
            serialNo: element.serialNo,
            inventoryId: element.inventoryId,
            receivedBy: element.receivedBy,
            receivedDate: element.receivedDate,
          }))
        });
      }
    }
    summaryItem.push(this.formBuilder.group({
      id: new FormControl(id),
      isShow: false,
      PartId: Object.partId,
      vendorPartNumber: Object.vendorPartNumber,
      partNo: Object.partNo,
      vendorPartName: Object.vendorPartName,
      unitofMeasureId: Object.unitofMeasureId,
      quantity: Object.quantity == null ? 0 : Object.quantity,
      received: Object.received == null ? 0 : Object.received,
      receiving: Object.receiving == null ? 0 : Object.receiving,
      originalCost: Object.cost,
      cost: Object.cost,
      isUpdateUnitCost: Object.isUpdateUnitCost,
      isAllRecieved: Object.isAllRecieved,
      tracking: Object.tracking,
      inventoryType: Object.inventoryType,
      status: Object.status,
      vendorDiscount: disountList,
      extension: Object.cost * (Object.quantity == null ? 0 : Object.quantity),
      serialLotList: serialLotList,
      receivedetailList: receivedetailList
    }))
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  GetTrackingLabel(id): String {
    if (this.PartTrackingList.length > 0) {
      const trackingList = this.PartTrackingList.filter(x => x.id == Number(id));
      if (trackingList.length > 0) {
        return trackingList[0].name;
      }
    }
    return '';
  }

  setLocationLabels(locationId: number) {
    this.seletedLocation = {};
    this.seletedLocation = this.RecLocationList.length > 0 ?this.RecLocationList.filter(x => x.id == locationId)[0]:{}
  }

  get summaryItemsGet(): FormArray {
    return this.masterForm.controls.summaryItems as FormArray
  }

  showHistoryFlyOut() {
    this.POService.GetPOLog(this.masterForm.value.id).subscribe((data: any) => {
      this.logList = data.data;
      this.IsHistoryFlyOut = true;
    });
  }

  hideHistoryFlyOut(data) {
    this.IsHistoryFlyOut = data;
  }

  GeneratePOPdf(data: any) {
  }

  AddUpdatePO(flag: boolean) {
    let saveObj = this.masterForm.getRawValue();
    saveObj.companyId = this.companyId;
    saveObj.orderDate = saveObj.orderDate == 'Invalid Date' ? '' : this.datepipe.transform(saveObj.orderDate, 'MM/dd/yyyy');
    saveObj.confirmationDate = saveObj.confirmationDate == 'Invalid Date' ? '' : this.datepipe.transform(saveObj.confirmationDate, 'MM/dd/yyyy');
    saveObj.estShipDate = saveObj.estShipDate == 'Invalid Date' ? '' : this.datepipe.transform(saveObj.estShipDate, 'MM/dd/yyyy');
    saveObj.userId = localStorage.getItem('userId');

    this.loader = true;
    if(this.companyId>0){
      this.POService.UpdateReceiving(saveObj, false).subscribe((data: any) => {
        this.loader = false;
        if (Number(data.data) > 0) {
          if (flag) {
            this.onReceiveShipment(Number(data.data))
          } else {
            this.getPObyId(this.companyId, Number(data.data));
          }
        } else {
          this.popupModal(this.constants.ResponseMsg, this.constants.ActionError);
        }
      },
        (error: any) => {
          this.loader = false;
          this.popupModal(this.constants.APIError, this.constants.APIErrorMsg);
        }
      );
    }
  }

  Cancel() {
    if (this.poSavedObj != null) {
      this.setPOData(this.poSavedObj);
    }
  }

  onIsRecieved(summaryItem: any) {
    summaryItem.get('receiving').setValue(summaryItem.value.isAllRecieved ? Number(summaryItem.get('quantity').value) - Number(summaryItem.get('received').value) : 0);
  }

  onRecieveAll() {
    this.summaryItemsGet.value.forEach((element, index) => {
      this.summaryItemsGet.at(index).get('receiving').setValue(!element.isAllRecieved ? Number(element.quantity) - Number(element.received) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(!element.isAllRecieved ? true : false);
    });
  }

  onRecieving(index: number) {
    const receiving = this.summaryItemsGet.at(index).get('receiving').value;
    const quantity = this.summaryItemsGet.at(index).get('quantity').value - this.summaryItemsGet.at(index).get('received').value;
    if (receiving == quantity) {
      this.summaryItemsGet.at(index).get('receiving').setValue(this.isWholeNumber(receiving) ? Number(receiving) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(true);
    } else if (receiving > quantity) {
      this.summaryItemsGet.at(index).get('receiving').setValue(this.isWholeNumber(receiving) ? Number(quantity) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(true);
    } else if (receiving < quantity) {
      this.summaryItemsGet.at(index).get('receiving').setValue(this.isWholeNumber(receiving) ? Number(receiving) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(false);
    } else {
      this.summaryItemsGet.at(index).get('receiving').setValue(0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(false);
    }
  }

  isWholeNumber(input: any) {
    const value = Number(input);
    if (!isNaN(value) && value > 0) {
      return true;
    }
    return false;
  }

  onReceiveShipment(poid: number) {
    if (this.isPartsToReceive()) {
      const isValid = this.validateReceiveParts().every((x: boolean) => x == true);
      if (isValid) {
        if (this.masterForm.value.satus != 24) {
          this.router.navigate(['/receiving-congratulations', poid])
        }
      } else {
        this.popupModal(this.constants.Alert, "If Part Tracking is lot then it must have atleast one Lot count and if it is Serial then Serial's count should match with the receiving quantity.")
      }
    } else {
      this.popupModal(this.constants.Alert, "Atleast one part to receive is required.")
    }
  }

  isPartsToReceive(): boolean {
    var x = this.summaryItemsGet.value.map(x => x.receiving);
    if (x.length > 0) {
      const sum = x.reduce((a, b) => a + b, 0);
      if (sum <= 0 && (this.masterForm.value.statusOld == 24)) {
        return false;
      }
    }
    return true;
  }

  validateReceiveParts(): any {

    const arr = [];
    this.summaryItemsGet.value.forEach((element: any) => {
      const receiving = element.receiving;
      switch (element.tracking) {
        case 1:
          const serialCount = element.serialLotList.filter(x => x.inventoryId == 0 || x.inventoryId == null).length;
          if (receiving > 0 && serialCount != receiving) {
            arr.push(false);
          } else {
            arr.push(true);
          }
          break;
        case 2:
          const lotCount = element.serialLotList.length;
          if ((receiving > 0 && lotCount > 0) || (receiving == 0 && lotCount >= 0)) {
            arr.push(true);
          } else {
            arr.push(false);
          }
          break;
        default:
          arr.push(true)
          break;
      }
    });
    return arr;
  }

  onToggleAll(value: boolean) {
    this.summaryItemsGet.value.forEach((element, index) => {
      this.summaryItemsGet.at(index).get('isShow').setValue(value);
    });
  }

  getLocationName(companyId: number) {
    this.sharedService.getLocationLevel(companyId, '').subscribe(
      (data: any) => {
        this.RecLocationList = data.dataList;
        // if (this.RecLocationList.length > 0) {
        //   this.RecLocationList = this.RecLocationList.filter(x => x.locationTypeId == 3);
        // }
      },
      (error) => {
        this.popupModal(this.constants.APIError, error);
      }
    )
  }

  getLocationValue(id): String {
    if (this.RecLocationList.length > 0) {
      const locationList = this.RecLocationList.filter(x => x.id == Number(id));
      if (locationList.length > 0) {
        return locationList[0].locationName;
      }
    }
    return 'N/A';
  }

  locNameChange(value) {
    this.masterForm.patchValue({ locationName: value });
    this.getLocationLevels(this.companyId, value); // 1 is for inventoy type location
    this.setLocationLabels(value);
  }

  getLocationLevels(companyId: number, locationId: number) {
    this.loader = true;
    this.inventoryService.GetLocationLevelsPositions(companyId, locationId)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          if (data.dataList.length > 0) {
            const level1 = data.dataList.map(function (item) { return item['aileName']; });
            const level2 = data.dataList.map(function (item) { return item['sectionName']; });
            const level3 = data.dataList.map(function (item) { return item['shelfName']; });
            const level4 = data.dataList.map(function (item) { return item['positionName']; });
            this.level1List = [];
            this.level2List = [];
            this.level3List = [];
            this.level4List = [];
            this.level1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            this.level2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            this.level3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            this.level4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.popupModal(this.constants.APIError, error);
        },
        complete: () => {

        }
      });
  }

  onloclevelChange(level: Number, value: any) {
    switch (level) {
      case 1:
        this.masterForm.patchValue({ aisle: value });
        break;
      case 2:
        this.masterForm.patchValue({ section: value });
        break;
      case 3:
        this.masterForm.patchValue({ shelf: value });
        break;
      case 4:
        this.masterForm.patchValue({ bin: value });
        break;
    }
  }

  onWareHouseChange(value) {
    this.masterForm.patchValue({ wareHouse: Number(value) });
  }

  openLocationModel() {
    $("#LocationModal").show();
  }

  closeLocationModel() {
    $("#LocationModal").hide();
  }

  getSerialLotFlyout(summaryItem: any, index: number) {
    this.flyoutIndex = index;
    if (Number(summaryItem.get('tracking').value) == 2 || Number(summaryItem.get('tracking').value) == 1) {
      this.TrackingObj = [];
      const serialLotList = summaryItem.get('serialLotList').value;
      Number(summaryItem.get('tracking').value) == 1 ? this.forSerial(serialLotList, summaryItem.get('quantity').value) : this.forLot(serialLotList, summaryItem.get('quantity').value);
      this.seriallotflyoutObj = {
        page: 'receiving',
        companyId: this.companyId,
        partId: summaryItem.get('PartId').value,
        SKU: summaryItem.get('vendorPartNumber').value,
        partName: summaryItem.get('vendorPartName').value,
        inventoryId: 0,
        trackingId: summaryItem.get('tracking').value,
        balance: summaryItem.get('quantity').value,
        trackingObj: this.TrackingObj
      }
      this.isSerialFlyout = true;
    }
    else {
      this.isSerialFlyout = false;
    }
  }

  forSerial(serialLotList: any, balance: number) {
    if (serialLotList != null) {
      if (serialLotList.length > 0) {
        serialLotList.forEach(element => {
          this.pushSerialLot(element.id, element.serialNo, element.inventoryId);
        });
        for (let x = 0; x < balance - serialLotList.length; x++) {
          this.AddSerialLotRow();
        }
      } else {
        for (let x = 0; x < balance; x++) {
          this.AddSerialLotRow();
        }
      }
    } else {
      for (let x = 0; x < balance; x++) {
        this.AddSerialLotRow();
      }
    }
  }

  forLot(serialLotList: any, balance: number) {
    if (serialLotList != null) {
      if (serialLotList.length > 0) {
        serialLotList.forEach(element => {
          this.pushSerialLot(element.id, element.serialNo, element.inventoryId);
        });
        if (serialLotList.length < balance) {
          this.AddSerialLotRow();
        }
      } else {
        this.AddSerialLotRow();
      }
    } else {
      this.AddSerialLotRow();
    }
  }

  AddSerialLotRow() {
    this.pushSerialLot(0, '', 0);
  }

  pushSerialLot(serialLotId: number, serialNo: any, inventoryId: number) {
    this.TrackingObj.push({
      id: serialLotId,
      //part: this.SKU,
      serialNo: serialNo,
      inventoryId: inventoryId,
      //partId: Number(this.partId),
      //trackingType: this.inventoryTracking,
      companyId: Number(this.companyId),
      isActive: true,
    })
  }

  closeSerialLotFlyout(object) {
    // this.isSerialFlyout = isShow;
    this.TrackingObj = object.trackingObj;
    this.isSerialFlyout = object.isShow;
    this.serialLotFormSubmit(this.TrackingObj);
  }

  serialLotFormSubmit(object: any) {
    const serialArr = object.filter(x => x.serialNo.length > 0);
    const x = this.summaryItemsGet.at(this.flyoutIndex).get('serialLotList') as FormArray;
    if (x.length > 0) {
      x.clear();
    }
    serialArr.forEach(element => {
      x.push(this.formBuilder.group({
        id: new FormControl(element.id),
        serialNo: new FormControl(element.serialNo),
        inventoryId: new FormControl(element.inventoryId)
      }))
    });
    if (this.summaryItemsGet.at(this.flyoutIndex).get('tracking').value == 1 || this.summaryItemsGet.at(this.flyoutIndex).get('tracking').value == 2) {
      // this.summaryItemsGet.at(this.flyoutIndex).patchValue({ receiving: (x.value.filter(x=>(x.inventoryId == 0 || x.inventoryId == null) && (x.id == 0 || x.id == null)).length) });
      this.summaryItemsGet.at(this.flyoutIndex).patchValue({ receiving: (serialArr.length) });
      this.onRecieving(this.flyoutIndex);
    }
  }
}

