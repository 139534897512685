import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';

interface Program1 {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program1[];
}


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements AfterViewInit, OnDestroy, OnInit {
  IsEcommerce: boolean = false;
  IsEcommerceShow: boolean = false;
  IsDisable: boolean = false;
  catagoryDataList: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  category: string;
  @ViewChild('Image') myInputVariable: ElementRef;
  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  recordAlreadywordpresssiteExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  fileToUpload: File = null;
  formSearch: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  userGroupObjects1 = new Array();
  companyList: any;
  value = 0;
  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();
  EcommerceObjects = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;
  wordpressId: number = 0;
  description: any;
  ecommerceDataList: any;
  ecommerceObjects: any = [];
  isActiveCat: boolean;
  websiteName: any = '';
  isParent : any = '';
  MarkupMargin : any = 0;
  eCommerce: boolean = false;
  ecommerceList: any = [];
  PictureValue: any = '';
  deletedItemImage: any;
  imageUrl: any = "/assets/image/add-part.png";
  MultipleImage: any = [];
  viewimagesshow: any;
  showImage: boolean = false;
  CategortTypeList= [];
  categoryType : any = '';
  notResale : any;

  configCategoriesObj = new Array();
  // items_searchnew: TreeviewItem[];
  categoriesList_search: any;
  items_search: any;
  programs1: Program1[] = [];
  websiteNameArray: unknown[];
  WPID: any;
  
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,    
    private _sanitizer: DomSanitizer,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering:true
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;

        this.getWebsiteCompany(company == undefined ? 0 : company);
        this.getEcoomerceCategory(company == undefined ? 0 : company);
        this.getAllCategories_search(company);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })

    }
    this.form = new FormGroup({
      'category': new FormControl(),
      'description': new FormControl(),
      'isActiveCat': new FormControl(),
      'websiteName': new FormControl('', Validators.compose([Validators.required])),
      'eCommerce': new FormControl(),
      'MarkupMargin': new FormControl(0),
      'isParent' : new FormControl('', Validators.compose([Validators.required])),
      'notResale' : new FormControl(),
      'categoryType' : new FormControl(),
      'WPId' : new FormControl()
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 13);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);
    this.CategortTypeList = this.constants.CategoryTypeList;
  }

  ngAfterViewInit() {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }

  getAllCatagory(companyId, SearchItem) {
    this.loader = true;
    if(companyId == undefined){companyId =0;}
    this.sharedService.getAllCatogaryWithProduction(companyId, SearchItem).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.catagoryDataList = this.data.dataList;
        this.rerender();
      });
  }

  addNewCategory() {
    this.validation = true;
    this.clearbutton = true;
    if (this.IsEcommerce == true) {
      if (this.form.controls.websiteName.status === this.constants.Invalid || this.form.controls.MarkupMargin.status === this.constants.Invalid) {
        return;
      }
    }
    if (this.form.controls.category.status === this.constants.Invalid || this.value == 0) {
      return;
    }

    // this.loader = true;
    const obj = {};
    obj['Name'] = this.category;
    obj['Description'] = this.description;
    obj['IsActiveCat'] = this.isActiveCat;
    obj['WebsiteName'] = this.websiteName;
    obj['parentId'] = parseInt(this.isParent);
    obj['markupMargin'] = Number(this.MarkupMargin);
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;
    obj['WordpressId'] = this.wordpressId == undefined ? 0 : Number(this.wordpressId);
     
    if(this.IsEcommerce == true){
      if(this.MultipleImage.length == 0){
        obj['CategoryImage'] = '';
      }
      else{
        obj['CategoryImage'] = this.MultipleImage[0].imageUrl;
      }
    }
    else{
      obj['CategoryImage'] = '';
    }
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      obj['WordpressId'] = Number(!this.wordpressId) ? 0 : Number(this.wordpressId);

      this.sharedService.updateCategory(obj)
        .subscribe((data:any) => {
          this.loader = false;
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                this.category = '';
                this.validation = false;
                this.id = '';
                this.description = '';
                this.isActiveCat = false;
                this.websiteName = '';
                this.isParent = '';
                this.eCommerce = false;
                this.showImage = false;
                this.resetCategory();
                this.getAllCatagory(this.value_search, this.userSearch);
                this.configCategoriesObj = [];
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  this.recordAlreadywordpresssiteExist = false;
                } else {
                  this.recordAlreadywordpresssiteExist = true;
                  this.recordAlreadyExist = false;
                }
                break;
              case 401:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              case 500:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg2").show()
                this.resetCategory();

                break;
              case 404:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              default:
                this.loader = false;
                $("#wrong_msg").show()
            }
          });
    } else {
        this.sharedService.addCategory(obj)
        .subscribe((data:any) => {
            this.loader = false;
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.recordAlreadyExist = false;
                this.recordAlreadywordpresssiteExist = false;
                $("#add_success").show();
                this.validation = false;
                this.showImage = false;
                this.resetCategory();
                this.getAllCatagory(this.value_search, this.userSearch);
                this.configCategoriesObj = [];
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  this.recordAlreadywordpresssiteExist = false;
                } else {
                  this.recordAlreadywordpresssiteExist = true;
                  this.recordAlreadyExist = false;
                }
                break;
              case 500:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg2").show()
                this.resetCategory();

                break;
              case 401:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              case 404:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              case 0:
                this.loader = false;
                this.recordAlreadyExist = true;
                break;
              default:
                $("#wrong_msg").show()
            }
          }
        );
    }
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }

  deleteUserCategory(item: any) {
    this.loader = true;
    this.sharedService.deleteCategory(item).subscribe(
      data => {

        this.data = data;

        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.getAllCatagory(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.loader = false;
            break;

          case 400:
            this.loader = false;
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);

            break;
          case 401:
            this.loader = false;
            $("#wrong_msg").show()


            break;
          case 404:
            this.loader = false;
            $("#wrong_msg").show()


            break;
          case 500:
            this.loader = false;
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);

        }
      });
  }

  toggleEdit(item: any) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.ListAngle = this.constants.DownArrow;
    this.ListDiv = false;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.value = item.companyId;
    this.id = item.id;
    this.sharedService.GetCategoryById(item.id).subscribe(
      (data: any) => {
        const categoryData = data.dataList;
        this.category = categoryData[0].name;
        this.recordAlreadyExist = false;
        this.description = categoryData[0].description;
        this.wordpressId = categoryData[0].wordpressId;
        this.isActiveCat = categoryData[0].isActiveCat;
        this.recordAlreadyExist = false;
        this.description = categoryData[0].description;
        this.MarkupMargin = Number(categoryData[0].markupMargin);
        this.isParent = categoryData[0].parentId;
        if (categoryData[0].isActiveCat == true) {
          this.IsEcommerce = true;
          this.eCommerce = true;
          this.websiteName = categoryData[0].websiteName;
          this.isParent = categoryData[0].parentId == 0 ? '' : categoryData[0].parentId;
          this.IsDisable = true;
          this.IsEcommerceShow = true;
          this.MultipleImage = [];
          if(categoryData[0].categoryImage == ""){
            categoryData[0].categoryImage = null;
          }
          let ojtmultiplepart = {
            imageUrl:  categoryData[0].categoryImage == null ? '' : this._sanitizer.bypassSecurityTrustUrl(categoryData[0].categoryImage),
          }
          this.MultipleImage.push(ojtmultiplepart);
          if(categoryData[0].categoryImage == null){
            this.MultipleImage = [];
            this.showImage = false;
          }
          else{
            this.showImage = true;
          }
        }
        else {
          this.IsEcommerce = false;
          this.eCommerce = false;
          this.websiteName = '';
          this.IsDisable = false;
          this.IsEcommerceShow = false;
          this.websiteName = '';
          //this.isParent = '';
        }
        this.getWebsiteCompany(categoryData[0].companyId);
        this.getEcoomerceCategory(categoryData[0].companyId);
        this.createdDateEdit = categoryData[0].createdDate == null ? '' : categoryData[0].createdDate == undefined ? '' : categoryData[0].createdDate;
      });
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  CategoryBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetCategory();
  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));
          // });
        }
        this.value = Number(companyId);
        if(this.value != 0){
          this.getWebsiteCompany(companyId);
        }

        //this.getAllCustomer(this.companyLoginId);
      });
  }

  onValueChange(value: any): void {
    this.value = value == undefined ? 0 : value;
    this.getWebsiteCompany(value == undefined ? 0 : value);
    this.getEcoomerceCategory(value == undefined ? 0 : value);
    this.getAllCategories_search(value);
  }

  getWebsiteCompany(companyId) {
    this.sharedService.GetAllEcommerceDetails(companyId, '').subscribe(
      data => {
        this.data = data;
        //this.ecommerceObjects = [];
        if (this.data.statusCode != "400") {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.companyList = this.data.dataList;
            this.companyList.forEach((x) => {
              this.ecommerceObjects.push((x));
            });
          }
          this.websiteNameArray = Object.values(this.ecommerceObjects.reduce((acc, obj) => {
            if (!acc[obj.id]) {
                acc[obj.id] = obj;
            }
            return acc;
        }, {}));
          this.value = Number(companyId);
        }
        else {
          this.ecommerceObjects = [];
          this.websiteName = '';
        }
      });
  }

  getEcoomerceCategory(companyId) {

    this.sharedService.GetEcommerceCategory(companyId).subscribe(
      data => {
        this.data = data;
        this.ecommerceList = [];
        if (this.data.statusCode != "400") {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.ecommerceList = this.data.dataList;
          
          }
          this.value = Number(companyId);
        }
        else {
          this.ecommerceList = [];
          this.isParent = '';
        }
      });
  }


  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetCategory();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchUser() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllCatagory(this.value_search, this.userSearch);
  }

  AllUser() {

    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllCatagory(this.value_search, this.userSearch);
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = this.constants.UpArrow;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    this.getAllCategories_search(value);
  } 

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  msgitem1() {
    $("#wrong_msg1").hide();
  }
  msgitem2() {
    $("#wrong_msg2").hide();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  resetCategory() {
    this.validation = false;
    this.id = null;
    this.category = '';
    this.isParent = '';
    this.description = '';
    this.isActiveCat = false;
    this.websiteName = '';
    this.eCommerce = false;
    this.IsEcommerceShow = false;
    this.IsDisable = false;
    this.IsEcommerce = false;
    this.MarkupMargin = '';
    this.MultipleImage = [];
    this.isParent = '';
    this.wordpressId = 0;
    this.getAllCategories_search(this.value);
  }

  getAllEcommerceDetails(companyId, SearchItem): void {
    this.sharedService.GetAllEcommerceDetails(companyId, SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.ecommerceDataList = this.data.dataList;
        this.loader = false;
        this.rerender();
        // this.ListShow = true;
        // this.ListDiv = true;
        // this.ListAngle = "up";

      });
  }

  checkValue(event: any) {
    if (event.target.checked == true) {
      this.IsEcommerce = true;
    }
    else {
      this.IsEcommerce = false;
      this.websiteName = '';
    }
  }

  checkValue1(event: any) {
    if (event.target.checked == true) {
      this.IsEcommerceShow = true;
      this.IsEcommerce = true;
      this.eCommerce = true;
      this.IsDisable = true;
    }
    else {
      this.IsEcommerceShow = false;
      this.IsEcommerce = false;
      this.eCommerce = false;
      this.websiteName = '';
    }
  }
 
  keyPressNumbers(event) {
    var character = String.fromCharCode(event.keyCode);
    var newValue = this.MarkupMargin + character;
    if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 4)) {
      event.preventDefault();
      return false;
    }
  } 


  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return  pointIndex >= 0 && pointIndex < value.length - x;
  }
  AddMultiplePartImage() {
    
    this.MultipleImage = [];
    this.PictureValue = this.myInputVariable.nativeElement.value;
    if (this.PictureValue == "") {
      return;
    }
    let ojtmultiplepart = {
      imageUrl: this.imageUrl,
    }
    this.MultipleImage.push(ojtmultiplepart);
    if(this.MultipleImage.Length == 0){
      this.showImage = false;
    }
    else{
      this.showImage = true;
    }
    this.imageUrl = '';
    this.myInputVariable.nativeElement.value = "";
  }
  ChngSettingImage(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  DeleteImageModalpopup() {
  
    $('#delete_model').show();
  }
  deleteImage(){
    this.MultipleImage = [];
    $('#delete_model').hide();
  }
  deleteImageModel(){
    $('#delete_model').hide();
  }
  viewImage(value, documenttype) {
    this.viewimagesshow = value;
    $("#viewImage").show();
  }
  HideImage(){
    $("#viewImage").hide();
  }

  getAllCategories_search(companyId) {
    this.sharedService.GetDropdownCategoryHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        this.categoriesList_search = this.data.dataList;
        this.programs1 = this.categoriesList_search;
      });
  }

  OnChangeParentCategory(value: any): void {
    this.isParent = value;
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}

