import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import JsBarcode from 'jsbarcode';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import Swal from 'sweetalert2';
import { EmailTemplateComponent } from '../email-template/email-template.component';
import pdfMake from "pdfmake/build/pdfmake";

@Component({
  selector: 'app-sales-order-history-fly-out',
  templateUrl: './sales-order-history-fly-out.component.html',
  styleUrls: ['./sales-order-history-fly-out.component.css']
})
export class SalesOrderHistoryFlyOutComponent implements OnInit {

  constructor(
    private salesOrderService: SalesOrderService,
    private currencyPipe: CurrencyPipe,
    private constants: ConstantsService,
    public companyService: CompanyService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
  ) { }
  OrderHistoryList: any = [];
  loader: boolean = false;
  SObarcodeImg: any;
  PObarcodeImg: any;
  base64: string;
  @Input() HistoryFlyOutVal: any;
  @Output() CloseHistoryFlyOut = new EventEmitter<any>();
  @Output() OpenHistoryFlyOut = new EventEmitter<any>();

  ngOnInit(): void {
    debugger
    this.PObarcodeImg = !this.ISNullOrEmpty(this.HistoryFlyOutVal.purchaseOrder) ? this.BarcodeGenerate('', '', true, false, '', true, 0) : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="';
    if (this.HistoryFlyOutVal.IsOpenFlyOut) {
      this.AddHistoryFlyOut();
    } else {
      this.BarcodeGenerate(this.HistoryFlyOutVal.salesOrderVm, this.HistoryFlyOutVal.orderNumber, false, this.HistoryFlyOutVal.IsPrint, '', false, this.HistoryFlyOutVal.IsEmail)
    }
  }
  AddHistoryFlyOut() {
    this.loader = true;
    this.OrderHistoryList = [];
    this.salesOrderService.GetSalesOrderLog(this.HistoryFlyOutVal.id)
      .subscribe({
        next: (data: any) => {
          this.OrderHistoryList = data;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  BarcodeGenerate(value: any, orderNumber: string, IsHis: boolean, IsPrint: boolean, Status: string, IsPOBarcode: boolean, IsEmail: number) {
    this.SObarcodeImg = undefined;
    JsBarcode(document.getElementById("barcode"), IsPOBarcode ? this.HistoryFlyOutVal.purchaseOrder : (IsHis ? orderNumber.substring(2) : orderNumber), {
      height: 90,
      width: 2.5,
      displayValue: false
    });
    var s = new XMLSerializer().serializeToString(document.getElementById("barcode"));
    this.ImgConvertor(s, value, orderNumber, IsHis, IsPrint, Status, IsPOBarcode, IsEmail);
  }
  ImgConvertor(s, value: any, orderNumber: string, IsHis: boolean, IsPrint: boolean, Status: string, IsPOBarcode: boolean, IsEmail: number): any {
    this.SVGToImage({
      svg: s,
      mimetype: "image/png",
      width: 500,
      quality: 1
    }).then((base64image: any) => {
      let img = document.createElement('img');
      img.src = base64image;
      if (IsPOBarcode) {
        this.PObarcodeImg = img.src;
      } else {
        this.SObarcodeImg = img.src;
        if (IsEmail == 1) {
          this.getBase64(value, orderNumber)
        } else if (IsEmail == 2) {
          this.Share(value, orderNumber, IsHis, IsPrint, Status);
        }
      }
    });
  }
  SVGToImage(settings) {
    let _settings = {
      svg: null,
      mimetype: "image/png",
      quality: 0.92,
      width: "auto",
      height: "auto",
      outputFormat: "base64"
    };
    for (let key in settings) { _settings[key] = settings[key]; }
    return new Promise(function (resolve, reject) {
      let svgNode;

      // Create SVG Node if a plain string has been provided
      if (typeof (_settings.svg) == "string") {
        // Create a non-visible node to render the SVG string
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = _settings.svg;
        svgNode = SVGContainer.firstElementChild;
      } else {
        svgNode = _settings.svg;
      }

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      let svgXml = new XMLSerializer().serializeToString(svgNode);
      let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

      const image = new Image();

      image.onload = function () {
        // Define the canvas intrinsic size
        canvas.width = 800;
        canvas.height = 400;

        // Render image in the canvas
        context.drawImage(image, 0, 0, 800, 400);

        if (_settings.outputFormat == "blob") {
          // Fullfil and Return the Blob image
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, _settings.mimetype, _settings.quality);
        } else {
          // Fullfil and Return the Base64 image
          resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
        }
      };
      // Load the SVG in Base64 to the image
      image.src = svgBase64;
    });
  }
  getBase64(value: any, orderNumber: string): any {
    this.loader = true;
    const docDefinition = this.CreatePdf(value, orderNumber, false);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      this.base64 = 'data:application/pdf;base64,' + data;
    });
    setTimeout(() => { this.Email() }, 1000);
  }
  Share(value: any, orderNumber: string, IsHis: boolean, IsPrint: boolean, Status: string) {
    if (IsPrint) {
      let x = this.CreatePdf(value, orderNumber, IsHis);
      pdfMake.createPdf(x).print();
      this.CloseFlyOut();
    } else {
      let x = this.CreatePdf(value, orderNumber, IsHis);
      pdfMake.createPdf(x).open();
      this.HistoryFlyOutVal.IsEmail == 2 ? this.CloseFlyOut() : false;
    }
  }
  //Email Section Start
  Email() {
    this.loader = false;
    const obj = {
      type: 2,
      docId: this.HistoryFlyOutVal.id,
      docNo: this.HistoryFlyOutVal.orderNumber,
      comId: Number(this.HistoryFlyOutVal.CompanyId),
      name: this.HistoryFlyOutVal.BillToName,
      files: [{
        name: (11 == 11 ? 'Quote-' : 'SO-') + this.HistoryFlyOutVal.orderNumber + '-' + this.HistoryFlyOutVal.customerPONumber.split('.')[2],
        base64: this.base64,
        type: 'pdf'
      }],
      from: localStorage.getItem("useremail")
    }
    const dialogRef = this.dialog.open(EmailTemplateComponent, {
      height: '712px',
      width: '750px',
      data: obj,
      hasBackdrop: false
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.SwalModal(this.constants.Success, this.constants.EmailSentMessage);
      } else {
        if (result.msg != '') {
          this.SwalModal(this.constants.APIError, result.msg);
        }
      }
      this.CloseFlyOut();
    });
  }
  //Email Section End
  CreatePdf(value: any, orderNumber: string, IsHis: boolean) {
    let pdfValue = JSON.parse(value)
    let SObarcodeImg = this.SObarcodeImg
    let PObarcodeImg = this.PObarcodeImg
    var email = localStorage.getItem("useremail");
    var loggedName = localStorage.getItem("username");
    var pdFgeneratedate = this.datepipe.transform(new Date(), 'MMMM dd, yyyy, hh:mm:ss a');
    this.HistoryFlyOutVal.CompanyDetail.cLogo = this.ISNullOrEmpty(this.HistoryFlyOutVal.CompanyDetail.base64Logo) ? this.constants.dummyCompanyLogo : this.HistoryFlyOutVal.CompanyDetail.base64Logo;
    let docDefinition = {
      pageMargins: [15, 30, 15, 50],
      content: [
        // Previous configuration
        {
          columns: [
            [
              {
                image: this.HistoryFlyOutVal.CompanyDetail.cLogo,
                bold: true,
                width: '*', // Set width to auto
                height: 35, // Set height to a fixed value
                margin: [0, 0, 0, 0],
                fit: [90, 90] // Wrap the image in a container with a fixed width
              },
              { text: '', bold: true, fontSize: 15, margin: [0, 10, 0, 0] },
              {

                table: {
                  heights: [80],
                  margin: [0, 0, 0, 0],
                  body: [
                    [
                      {
                        text: (this.HistoryFlyOutVal.CompanyDetail.companyName) + '\n' +
                          (this.HistoryFlyOutVal.CompanyDetail.addressLine1) + '\n' +
                          ((this.ISNullOrEmpty(this.HistoryFlyOutVal.CompanyDetail.city) ? '' : (this.HistoryFlyOutVal.CompanyDetail.city + ',')) + ' ' + (this.ISNullOrEmpty(this.HistoryFlyOutVal.CompanyDetail.stateId) ? '' : this.HistoryFlyOutVal.CompanyDetail.stateId + ',') + ' ' + (this.ISNullOrEmpty(this.HistoryFlyOutVal.CompanyDetail.zipCode) ? '' : this.HistoryFlyOutVal.CompanyDetail.zipCode)) + '\n' +
                          ('Phone: ' + this.HistoryFlyOutVal.CompanyDetail.phoneNumber) + '\n' +
                          ('Sales Rep Name: ' + localStorage.getItem('username')) + '\n' +
                          ('Email: ' + localStorage.getItem('useremail'))
                        , fontSize: 10, border: [false, false, false, false]
                      }
                    ]
                  ]
                }
              },
              {
                table: {
                  widths: [260],
                  heights: [10, 30],
                  body: [
                    [{ text: 'Bill To:', bold: true }],
                    [
                      {
                        text: (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.Name) ? '' : pdfValue.BillAddress.Name + '\n') : (this.ISNullOrEmpty(pdfValue.BillAddress.name) ? '' : pdfValue.BillAddress.name + '\n')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.AddressLine1) ? '' : pdfValue.BillAddress.AddressLine1 + '\n') : (this.ISNullOrEmpty(pdfValue.BillAddress.addressLine1) ? '' : (pdfValue.BillAddress.addressLine1 + '\n'))) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.City) ? '' : (pdfValue.BillAddress.City + ', ')) : (this.ISNullOrEmpty(pdfValue.BillAddress.city) ? '' : (pdfValue.BillAddress.city + ', '))) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.StateId) ? '' : (this.GetStateCode(pdfValue.BillAddress.StateId)) + ', ') : (this.ISNullOrEmpty(pdfValue.BillAddress.stateId) ? '' : (this.GetStateCode(pdfValue.BillAddress.stateId)) + ', ')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.Zip) ? '' : pdfValue.BillAddress.Zip) : (this.ISNullOrEmpty(pdfValue.BillAddress.zip) ? '' : pdfValue.BillAddress.zip)) + '\n' +
                          ('Email Address: ' + (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.Email) ? '' : pdfValue.BillAddress.Email) : (this.ISNullOrEmpty(pdfValue.BillAddress.email) ? '' : pdfValue.BillAddress.email))) + '\n' +
                          ('Phone #: ' + (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.Phone) ? '' : pdfValue.BillAddress.Phone) : (this.ISNullOrEmpty(pdfValue.BillAddress.phone) ? '' : pdfValue.BillAddress.phone)))
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              },
              {
                text: 'Notes: ' + '  ' + (IsHis ? (this.ISNullOrEmpty(pdfValue.salesOrder.CustomerNotes) ? '' : pdfValue.salesOrder.CustomerNotes) : (this.ISNullOrEmpty(pdfValue.salesOrder.customerNotes) ? '' : pdfValue.salesOrder.customerNotes)),
                bold: false,
                fontSize: 11,
                margin: [0, 20, 0, 10]
              }
            ],
            [
              {
                text: (IsHis && pdfValue.salesOrder.StatusId == 11) ? 'Sales Quote' : 'Sales Order',
                alignment: 'right',
                bold: true,
                fontSize: 19
              }, { text: ' ' },
              {
                alignment: 'right',
                table: {
                  widths: [85, 85, 85],
                  heights: [10, 10, 10],
                  margin: [90, 70, 0, 0],
                  bold: true,
                  body: [
                    [{ text: 'Purchase Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Date', bold: true, alignment: 'center', fontSize: 10 }],
                    [
                      { text: this.HistoryFlyOutVal.purchaseOrder, alignment: 'center', bold: true, fontSize: 10 },
                      { text: (IsHis ? orderNumber.substring(2) : orderNumber), alignment: 'center', bold: true, fontSize: 10 },
                      { text: this.datepipe.transform(new Date(), 'MM/dd/yyyy'), alignment: 'center', fontSize: 10 }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }, { text: '    ' + (this.ISNullOrEmpty(this.HistoryFlyOutVal.purchaseOrder) ? '' : 'Purchase Order Number') + '                     Sales Order Number', fontSize: 10, alignment: 'right', margin: [0, 2, 20, 0] },
              {
                image: SObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'right',
                margin: [0, 0, 0, 0],
              },
              {
                image: PObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'left',
                margin: [0, -40, 10, 0]
              },
              {
                margin: [0, 7, 0, 0],
                table: {
                  heights: [10, 30],
                  widths: [270],
                  alignment: 'right',
                  body: [
                    [{ text: 'Ship To:', bold: true }],
                    [
                      {
                        text: (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.CustomerName) ? '' : pdfValue.ShipAddress.CustomerName + '\n') : (this.ISNullOrEmpty(pdfValue.ShipAddress.customerName) ? '' : pdfValue.ShipAddress.customerName + '\n')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.CName) ? '' : pdfValue.ShipAddress.CName + '\n') : (this.ISNullOrEmpty(pdfValue.ShipAddress.cName) ? '' : pdfValue.ShipAddress.cName + '\n')) +
                          (IsHis ? (pdfValue.ShipAddress.AddressLine1 + '\n') : (pdfValue.ShipAddress.addressLine1 + '\n')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.AddressLine2) ? '' : pdfValue.ShipAddress.AddressLine2 + '\n') : (this.ISNullOrEmpty(pdfValue.ShipAddress.addressLine2) ? '' : pdfValue.ShipAddress.addressLine2 + '\n')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.City) ? '' : (pdfValue.ShipAddress.City + ', ')) : (this.ISNullOrEmpty(pdfValue.ShipAddress.city) ? '' : (pdfValue.ShipAddress.city + ', '))) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.StateId) ? '' : (this.GetStateCode(pdfValue.ShipAddress.StateId)) + ', ') : (this.ISNullOrEmpty(pdfValue.ShipAddress.stateId) ? '' : (this.GetStateCode(pdfValue.ShipAddress.stateId)) + ', ')) +
                          ((IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.Zip) ? '' : pdfValue.ShipAddress.Zip) : (this.ISNullOrEmpty(pdfValue.ShipAddress.zip) ? '' : pdfValue.ShipAddress.zip)) + '\n') +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.CountryId) ? '' : (this.GetCountryCode(pdfValue.ShipAddress.CountryId))) : (this.ISNullOrEmpty(pdfValue.ShipAddress.countryId) ? '' : (this.GetCountryCode(pdfValue.ShipAddress.countryId))))
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }
            ]
          ]
        },
        {
          table: {
            widths: [100, 110, 70, 80, 170],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            body: [
              [{ text: 'Buyer', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 },
              { text: 'Payment Terms', border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0], bold: true, fontSize: 10 }
                , { text: 'FOB Point', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 },
              { text: 'Shipping Term', border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0], bold: true, fontSize: 10 }
                , { text: 'Ship VIa', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 }],
              [
                { text: loggedName, alignment: 'center', fontSize: 10 },
                { text: (IsHis ? (pdfValue.salesOrder.NetTerms != 0 ? pdfValue.salesOrder.PaymentType : '') : (pdfValue.salesOrder.netTerms != 0 ? pdfValue.salesOrder.paymentType : '')), alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(this.HistoryFlyOutVal.CompanyDetail.fobPointName) ? '' : this.HistoryFlyOutVal.CompanyDetail.fobPointName, alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(this.HistoryFlyOutVal.CompanyDetail.shippingTermName) ? '' : this.HistoryFlyOutVal.CompanyDetail.shippingTermName, alignment: 'center', fontSize: 10 },
                { text: (IsHis ? pdfValue.salesOrder.ShippingType : pdfValue.salesOrder.shippingType), alignment: 'center', fontSize: 10 },

              ]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#dadada' : null;
            }
          }
        }, { text: ' ' },
        {
          style: 'contactTable1',
          table: {
            widths: [40, 100, 208, 40, 40, 80],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Item', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Part Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Ordered', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Shipped', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Extension Total', fontSize: 10, bold: true, alignment: 'right' }
              ]
              ,
              ...pdfValue.salesOrder.OrderPart.map((p1, i) => ([
                { text: i + 1, fontSize: 10, alignment: 'center' },
                { text: p1.PartNumber, fontSize: 10, alignment: 'left' },
                { text: p1.PartName, fontSize: 10, alignment: 'left' },
                { text: p1.Quantity, fontSize: 10, alignment: 'center' },
                { text: p1.shipedQty == null ? 0 : p1.shipedQty, fontSize: 10, alignment: 'center' },
                { text: this.currencyPipe.transform(p1.SExtension), fontSize: 10, alignment: 'right' }])),

            ]
          },
        },
        {
          table: {
            widths: [330, 0, 0, 0, 0, 100, 67],
            margin: [0, 0, 0, 0],
            body: [
              [
                {}, {}, {}, {}, {},
                { text: 'Shipping', margin: [0, 0, 0, 0], alignment: 'left', fontSize: 10, bold: true },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.ShippingAmount : pdfValue.salesOrder.shippingAmount), alignment: 'right', bold: true, fontSize: 10 }

              ],
              [
                {}, {}, {}, {}, {},
                { text: 'Sales Tax ( ' + (IsHis ? pdfValue.salesOrder.PartTaxRate : pdfValue.salesOrder.partTaxRate) + '% )', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.PartTaxRateAmt : pdfValue.salesOrder.partTaxRateAmt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Total', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.SubTotalSummaryExt : pdfValue.salesOrder.subTotalSummaryExt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Payment Received', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.PaymentAmt : pdfValue.salesOrder.paymentAmt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Balance Due', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.BalanceDue : this.HistoryFlyOutVal.BalanceDue), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }]
            ]
          },
          layout: {
            fillColor: '#dadada',
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? '#dadada' : '#dadada';
            },
          }
        },
      ],
      footer: function (currentPage, pageCount) {
        if (currentPage === pageCount) {
          return [
            {
              margin: [20, 0, 0, 0],
              table: {
                widths: [480, 100],

                alignment: 'left',
                style: 'footersTableStyle',
                body: [
                  [
                    { text: '**** PLEASE CONFIRM AND ADVISE OF ETA BY EMAIL AT ' + email + '\n**** THANK YOU, ' + loggedName + '\n\n' + pdFgeneratedate, fontSize: 8, bold: true },
                    { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, margin: [0, 10, 50, 80], fontSize: 8, alignment: 'right', bold: true }
                  ],
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                },
              }
            }

          ]
        }
        else {
          return [
            {
              table: {
                widths: [480, 100],
                alignment: 'left',
                style: 'footersTableStyle',
                body: [
                  [
                    { text: '' },
                    { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, margin: [0, 10, 50, 80], fontSize: 8, alignment: 'right', bold: true }
                  ],
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                },
              }
            }
          ];
        }

      },

      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        tableExample: {
          margin: [90, 0, 0, 0]
        },
        contactTable: {
          margin: [0, 5, 0, 0]
        },
        contactTable1: {
          margin: [0, 10, 0, 0]
        }
      }
    };
    return docDefinition;
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value === null || value === undefined || value === '') {
      result = true;
    }
    return result;
  }
  CloseFlyOut() {
    this.CloseHistoryFlyOut.emit();
  }
  SwalModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }
  GetCountryCode(countryId: number) {
    return (this.HistoryFlyOutVal.CountryList.length > 0 && countryId != null && this.HistoryFlyOutVal.CountryList.filter(x => x.id == countryId).length > 0) ? this.HistoryFlyOutVal.CountryList.filter(x => x.id == countryId)[0].countryCode.trim() : ''
  }
  GetStateCode(stateId: number) {
    return (this.HistoryFlyOutVal.StateList.length > 0 && Number(stateId) > 0 && this.HistoryFlyOutVal.StateList.filter(x => x.id == stateId).length > 0) ? this.HistoryFlyOutVal.StateList.filter(x => x.id == stateId)[0].abbreviation.trim() : ''
  }
}
