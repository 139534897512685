import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {
  @Input() placeholder: string = 'Search';
  @Output() searchEvent = new EventEmitter<any>();
  searchddl: string = this.constants.all;
  searchText: string = '';
  
  constructor(private constants: ConstantsService){

  }

  search(text){
    this.searchEvent.emit(text);
  }
}
