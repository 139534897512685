import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { SharedService } from 'src/app/services/shared.service';
import { IInventoryItems, IInventoryItemWithDate } from 'src/app/shared/inventoryItem';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import pdfMake from "pdfmake/build/pdfmake";
import { CurrencyPipe, DatePipe } from '@angular/common'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-report-date-location',
  templateUrl: './inventory-report-date-location.component.html',
  styleUrls: ['./inventory-report-date-location.component.css']
})
export class InventoryReportDateLocationComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  isShowDate: boolean = false;
  isShowLocation: boolean = false;
  start: number = 0;
  limit: number = 15;
  end: number = this.limit + this.start;
  companyId: number = 0;
  CompanyWareHouseList: any = [];
  invMasterList: any;
  loader: boolean = false;
  OnHand: any = 0;
  OnHandTotal: any = 0.00;
  Available: any = 0;
  AvailableTotal: any = 0.00;
  Committed: any = 0;
  CommittedTotal: any = 0.00;
  BackOrdered: any = 0;
  BackOrderedTotal: any = 0.00;
  InvLocationList: any = [];
  invListColumns: string[] = this.constants.invListReportColumns;
  InvSearchItem: string = '';
  data: any;
  inventoryLength: any;
  MasterInvType: number[] = [0];
  InvTypeList: any = [];
  startDate: any;
  endDate: any;
  PartAction: any[] = this.constants.actionList;
  action: number;
  invReportList: any[] = [];
  ReportColumn : string[] = this.constants.ReportColumn; 
  formula: string = 'Inventory Report';

  constructor(
    private inventoryService: InventoryService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    public datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
  ) {
    this.GetInventoryType();
  }

  ngOnInit(): void {
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.CompanyDepedsFn(company);
      this.getMasterTableData(this.companyId, this.start, this.end, false);
    } else {
      this.PopupModal(this.constants.Error, this.constants.companyId);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.start = 0;
      this.limit = 15;
      this.end = this.limit + this.start;
      this.CompanyDepedsFn(data);
    })
  }

  CompanyDepedsFn(comapanyId: number) {
    this.companyId = comapanyId;
    this.GetWareHouseWithChild(this.companyId);
    this.CalculateInventoryQuantity(this.companyId);
    this.GetLocationName();
    this.invMasterList = [];
  }

  GetWareHouseWithChild(companyId: Number) {
    this.companyService.GetWareHouseWithChild(companyId).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.CompanyWareHouseList = data.data;
        }
      }
    )
  }

  CalculateInventoryQuantity(companyId) {
    this.inventoryService.GetCalculateInventoryQuantity(companyId).subscribe(
      (data: any) => {
        this.loader = false;
        const response = data.data;
        if (response != null) {
          this.OnHand = response.onHand;
          this.OnHandTotal = response.onHandTotal;
          this.Available = response.available;
          this.AvailableTotal = response.availableTotal;
          this.Committed = response.committed;
          this.CommittedTotal = response.committedTotal;
          this.BackOrdered = response.backOrdered;
          this.BackOrderedTotal = response.backOrderedTotal;
        }
      },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  GetLocationName() {
    this.sharedService.getLocationLevel(this.companyId, '').subscribe(
      (response) => {
        this.InvLocationList = response;
        if (this.InvLocationList.dataList.length > 0) {
          this.InvLocationList = this.InvLocationList.dataList.filter(x => x.locationTypeId == 1);
        }
      },
      (error) => {
        this.PopupModal(this.constants.APIError, error);
      }
    )
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  GetAllInventory() {
    this.isShowDate = false;
    this.isShowLocation = false;
  }

  GetInvByDate() {
    this.isShowDate = true;
    this.isShowLocation = false;
  }

  GetInvByLoc() {
    this.isShowLocation = true;
    this.isShowDate = false;
  }

  GetInventoryData() {
    // this.getMasterTableData(this.companyId, this.start, this.end, true);
    if (this.isShowDate == false && this.isShowLocation == false) {
      this.getMasterTableData(this.companyId, this.start, this.end, false);
    }
    else if (this.isShowDate == true && this.isShowLocation == false && this.startDate != '') {
      this.GetInventoryDataUsingDate();
    }
  }

  getMasterTableData(companyId, start, end, isUpdate) {
    const obj: IInventoryItems = {
      CompanyId: companyId,
      StartIndex: start,
      EndIndex: 2000,
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType
    }
    this.loader = true;
    this.inventoryService.GetInventoryItems(obj).subscribe(
      (data: any) => {
        this.loader = false;
        this.data = data;
        this.invMasterList = [];
        this.invMasterList = this.invMasterList.concat(data.data);
        this.invMasterList = this.removeDuplicates(this.invMasterList, 'item');
        this.inventoryLength = this.invMasterList.length;
        if (isUpdate) {
          this.updateIndex();
        }
      },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      });
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  updateIndex() {
    this.start = this.end + 1;
    this.end = this.limit + this.start;
  }

  GetInventoryType() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.InvTypeList = data.data;
      }
    )
  }

  GetPartName(event: any) {
    this.InvSearchItem = event;
  }

  GetInventoryTypeValue(id): string {
    if (this.InvTypeList.length > 0 && id > 0) {
      const list = this.InvTypeList.filter(x => x.typeId == Number(id));
      if (list.length > 0) {
        return list[0].typeValue;
      }
    }
    return '';
  }

  GetStartDate(event) {
    this.startDate = event;
  }

  GetEndDate(event) {
    this.endDate = event;
  }

  GetInventoryDataUsingDate() {
    const obj: IInventoryItemWithDate = {
      CompanyId: this.companyId,
      StartDate: this.datepipe.transform(this.startDate, 'MM/dd/yyyy'),
      EndDate: this.datepipe.transform(this.endDate, 'MM/dd/yyyy'),
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType
    }
    this.inventoryService.GetInventoryListWithDate(obj).subscribe((data: any) => {
      this.invMasterList = [];
      this.invMasterList = this.invMasterList.concat(data.data);
      this.invMasterList = this.removeDuplicates(this.invMasterList, 'item');
      this.inventoryLength = this.invMasterList.length;
    },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      });
  }

  GetAction(event: number) {
    this.action = event;
    this.GetInvReportData();
  }

  GetInvReportData() {
    const obj: IInventoryItems = {
      CompanyId: this.companyId,
      StartIndex: this.start,
      EndIndex: 2000,
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType
    }
    this.loader = true;
    this.inventoryService.GetInventoryReport(obj).subscribe(
      (data: any) => {
        this.loader = false;
        this.invReportList = data.data;
        if (this.action == 1) {
          this.CreateInventoryPdf();
        } else if (this.action == 2) {
          this.exportCSV();
        }
      },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      });
  }

  exportCSV() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Inventory Report',
      useTextFile: false,
      useBom: true,
      headers: this.ReportColumn
    };
    if (this.invReportList.length > 0) {
      const fileInfo = new ngxCsv(this.invReportList, this.formula, options);
    }
  }

  CreateInventoryPdf() {
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        { text: "Inventory Report", style: "header", alignment: "center" },
        {
          style: "tableExample",
          table: {
            body: [
              [
                { text: 'Item Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Item Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'UOM', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Inventory Type', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'On Hand', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Available', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Committed', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Total Cost', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Back Ordered', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'B/O Available', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'B/O Committed', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'B/O TotalCost', fontSize: 10, bold: true, alignment: 'left' }
              ]
              ,
              ...this.invReportList.map((p1, i) => ([
                // { text: i + 1 , fontSize: 10, alignment: 'left' },
                { text: p1.itemNumber, fontSize: 10, alignment: 'left' },
                { text: p1.itemDescription, fontSize: 10, alignment: 'left' },
                { text: p1.uom, fontSize: 10, alignment: 'left' },
                { text: p1.inventoryType, fontSize: 10, alignment: 'left' },
                { text: p1.onHand, fontSize: 10, alignment: 'left' },
                { text: p1.available, fontSize: 10, alignment: 'left' },
                { text: p1.committed, fontSize: 10, alignment: 'left' },
                { text: this.currencyPipe.transform(p1.totalCost), fontSize: 10, alignment: 'right' },
                { text: p1.backOrdered, fontSize: 10, alignment: 'left' },
                { text: p1.boAvailable, fontSize: 10, alignment: 'left' },
                { text: p1.boCommitted, fontSize: 10, alignment: 'left' },
                { text: this.currencyPipe.transform(p1.boTotalCost), fontSize: 10, alignment: 'right' }
              ])),
            ]
          }
        },
        { text: this.invMasterList[0].sumTotalCost != '' || undefined ? '$ ' + this.currencyPipe.transform(this.invMasterList[0].sumTotalCost) : '', style: "totalCost", fontSize: 10, bold: true },
        { text: this.invMasterList[0].sumBOTotalCost != '' || undefined ? '$ ' + this.currencyPipe.transform(this.invMasterList[0].sumBOTotalCost) : '', style: "totalBOCost", fontSize: 10, bold: true },
      ], footer: (currentPage, pageCount) => {
        var t = {
          layout: "noBorders",
          margin: [680, 10, 0, 0],
          table: {
            widths: ["*", "*"],
            body: [
              [
                { text: "Page  " + currentPage.toString() + " of " + pageCount, fontSize: 10, alignment: 'right' },
              ]
            ]
          }
        };

        return t;
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 10]
        },
        totalCost: {
          margin: [500, 0, 0, 10]
        },
        totalBOCost: {
          margin: [710, -21, 0, 10]
        }
      }
    };
    // pdfMake.createPdf(docDefinition).download("Inventory Report.pdf");
    // pdfMake.createPdf(docDefinition).open();
    pdfMake.createPdf(docDefinition).print();
  }
}
