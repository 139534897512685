import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService {

  baseUrl = environment.baseUrl;
  constructor(private _http: HttpClient) { }

  GetOrderList(reqObj: any) {
    return this._http.post(this.baseUrl + 'api/Inventory/GetInventoryItems', reqObj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCustomerWithChild(Id: Number) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerWithChild?Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllParts(reqObj) {
    return this._http.post(this.baseUrl + 'api/Order/GetPartList', reqObj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSelectedPartsForSO(reqObj) {
    return this._http.post(this.baseUrl + 'api/Order/GetSelectedPartsForSO', reqObj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddChildCompany(company: any) {
    return this._http.post(this.baseUrl + 'api/Order/AddChildCompany', company,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddBillToCustomer(customer: any, type: string) {
    return this._http.post(this.baseUrl + 'api/Order/AddBillToCustomer?type=' + type, customer,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllState() {
    return this._http.get(this.baseUrl + 'api/Order/GetStateList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  CompletePayment(payment: any) {
    return this._http.post(this.baseUrl + 'api/Payment/InsCompletePayment', payment,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddUpdSalesOrder(salesOrder: any) {
    return this._http.post(this.baseUrl + 'api/Order/AddUpdSalesOrder', salesOrder,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSalesOrderById(Id) {
    return this._http.get(this.baseUrl + 'api/Order/GetSalesOrderById?Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  FulfillmentAutomatic(Id) {
    return this._http.get(this.baseUrl + 'api/AssignInventorytoSOs/FulfillmentAutomatic?Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSalesOrderList(orderRequest: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/GetSalesOrderList', orderRequest,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  ApprovePayment(item: object, orderid: number): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Payment/ADDApprovePayment?OrderId=' + orderid, item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  PaymentLog(SalesOrderId: number) {
    return this._http.get(this.baseUrl + 'api/Payment/InsPaymentLog?SalesOrderId=' + SalesOrderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSalesOrderLog(Id: any) {
    return this._http.get(this.baseUrl + 'api/Order/GetSalesOrderLog?Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //Service to get report data 

  GetSOReportData(companyId, statusId, offsetNo, pageSize, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetSOReportData?companyId=' + companyId + '&statusId=' + statusId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllSalesOrderReportData(companyId, statusId, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetAllSOReportData?companyId=' + companyId + '&statusId=' + statusId + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSOStatusList() {
    return this._http.get(this.baseUrl + 'api/Order/GetSOStatusList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  ValidateAddress(CompanyId: number, providerId: number, value: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/AddressValidation/v1/' + CompanyId + '/' + providerId, value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
