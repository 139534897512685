import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-new-btn',
  templateUrl: './add-new-btn.component.html',
  styleUrls: ['./add-new-btn.component.css']
})
export class AddNewBtnComponent {
  @Input() addNewbtnText: string = 'Add';
  @Output() clickEvent = new EventEmitter<any>();

  AddNew() {
    this.clickEvent.emit();
  }
}
