import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PartService } from 'src/app/services/part/part.service';
import { SharedService } from 'src/app/services/shared.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import Swal from 'sweetalert2';

interface Program {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program[];
}

@Component({
  selector: 'app-part-details',
  templateUrl: './part-details.component.html',
  styleUrls: ['./part-details.component.css']
})
export class PartDetailsComponent implements OnInit {
  loader: boolean = false;
  previousPage: string = 'Back to List';
  currentPage: string = 'Part Details';
  form: FormGroup;
  companyId: number = 0;
  invTypeList: any = [];
  trackingList: any = [];
  statusList: any = this.constants.Status;
  purposeList: any = this.constants.Purpose;
  sourceList: any = this.constants.Source;
  vendorFormCreate: FormGroup;
  selected = '';
  selectedMulti = [];
  programs: Program[] = [];
  filteredVendors: any;
  @ViewChild('vendorDialog', { static: true }) vendorDialog: TemplateRef<any>;
  getStateFromCountry: any[] = [];

  constructor(private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private globalChangeService: GlobalChangeService, private constants: ConstantsService, private partService: PartService, private sharedService: SharedService, private inventoryService: InventoryService, private vendorService: VendorService, public dialog: MatDialog) {
    this.CreateForm();
    this.CreateVendorForm();
    this.GetInventoryType();
    this.GetTrackingList();
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      if (id > 0) {
        this.GetbyId(Number(id));
        this.GetAllCategories();
      }
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.router.navigate(['/part-list'])
    })
  }

  CreateForm() {
    this.form = new FormGroup({
      id: new FormControl(0),
      vendorId: new FormControl(0),
      name: new FormControl(''),
      description: new FormControl(''),
      shortDescription: new FormControl(''),
      cost: new FormControl(0),
      hours: new FormControl(''),
      imagePath: new FormControl(null),
      laborCharge: new FormControl(null),
      search: new FormControl(null),
      customPartId: new FormControl(null),
      customPartImagePath: new FormControl(null),
      notes: new FormControl(null),
      partCategoryId: new FormControl(0),
      companyId: new FormControl(0),
      productId: new FormControl(0),
      isActive: new FormControl(true),
      createdDate: new FormControl(null),
      modifiedDate: new FormControl(null),
      createdBy: new FormControl(null),
      modifiedBy: new FormControl(null),
      partId: new FormControl(0),
      partNo: new FormControl(''),
      vendorName: new FormControl(''),
      partCategoryName: new FormControl(null),
      partQuestions: new FormArray([]),
      partInventory: new FormArray([]),
      partImageUpload: new FormArray([]),
      partImage: new FormControl(null),
      vendorPartCost: new FormControl(0),
      isCustompart: new FormControl(false),
      vendorPartName: new FormControl(''),
      vendorPartNumber: new FormControl(''),
      unitofMeasureId: new FormControl(null),
      minInventory: new FormControl(0),
      skillLevel: new FormControl(0),
      productionRole: new FormControl(''),
      partLocation: new FormControl(''),
      partLeadTime: new FormControl(0),
      reorderAmount: new FormControl(0),
      workflowStep: new FormControl(0),
      companyName: new FormControl(null),
      installationNotes: new FormControl(null),
      totalAllType: new FormControl(0),
      totalTypeInventory: new FormControl(0),
      totalInvLog: new FormControl(0),
      wordpressId: new FormControl(null),
      height: new FormControl(''),
      length: new FormControl(''),
      width: new FormControl(''),
      weight: new FormControl(0),
      dimentionType: new FormControl(''),
      weightType: new FormControl(''),
      autoMarkup: new FormControl(false),
      markupMargin: new FormControl(0),
      isNotActive: new FormControl(false),
      isDiscount: new FormControl(null),
      discount: new FormControl(0),
      apiWordpressDetails: new FormArray([]),
      partProduct: new FormArray([]),
      partVendorList: new FormArray([]),
      multiPartCategory: new FormArray([]),
      totalRows: new FormControl(null),
      nonInventory: new FormControl(false),
      vendorCount: new FormControl(null),
      editrow: new FormControl(null),
      customId: new FormControl(null),
      partTracking: new FormControl(3),
      partLocationName: new FormControl(''),
      locationName: new FormControl(''),
      location: new FormControl(''),
      vendorDiscount: new FormArray([]),
      customerDiscount: new FormArray([]),
      roleBasePricingDiscount: new FormArray([]),
      partSpecifications: new FormArray([]),
      invLocationName: new FormControl(null),
      invLocation: new FormControl(null),
      qty: new FormControl(0),
      totalAmountAll: new FormControl(0),
      customerPonumber: new FormControl(null),
      inVDate: new FormControl(null),
      typeName: new FormControl(null),
      oemDiscount: new FormControl(null),
      shippedBox: new FormControl(null),
      wpData: new FormControl(null),
      lastWPSyncDate: new FormControl(null),
      eCommerceStatus: new FormControl(''),
      relatedProducts: new FormArray([]),
      inventoryType: new FormControl(1),
      availableInventory: new FormControl(0),
      onHandInventory: new FormControl(0),
      committedInventory: new FormControl(0),
      backOrderedInventory: new FormControl(0),
      question: new FormArray([]),
      defaultInventoryLocationId: new FormControl(null),
      
      gtinupc: new FormControl(''),
      purpose: new FormControl(0),
      source: new FormControl(1),
      businessChannel: new FormControl(''),
      reorderQty: new FormControl(0),
      leadUnit: new FormControl(0),
    });
  }

  get vendorDiscountArray(): FormArray{
    return this.form.get('vendorDiscount') as FormArray;
  }

  get customerDiscountArray(): FormArray{
    return this.form.get('customerDiscount') as FormArray;
  }

  get roleDiscountArray(): FormArray{
    return this.form.get('roleBasePricingDiscount') as FormArray;
  }

  GetbyId(id: number) {
    this.loader = true;
    this.partService.GetPartById(id).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.data != null) {
          this.CreateForm();
          this.form.patchValue(data.data);
          this.addvendorDiscount(data.data.vendorDiscount);
          this.addvolDiscount(data.data.customerDiscount);
          this.addrolebasedDiscount(data.data.roleBasePricingDiscount);
        }
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }

  addvendorDiscount(data: any): void {
    if(data.length>0){
      const items = this.form.get('vendorDiscount') as FormArray;
      data.forEach(element => {
          items.push(this.pushVendorDiscount(element));
      });
    }
  }

  addvolDiscount(data: any): void {
    if(data.length>0){
      const items = this.form.get('customerDiscount') as FormArray;
      data.forEach(element => {
        items.push(this.pushVolPricingDiscount(element));
      });
    }
  }

  addrolebasedDiscount(data: any): void {
    if(data.length>0){
      const items = this.form.get('roleBasePricingDiscount') as FormArray;
      data.forEach(element => {
        items.push(this.pushRoleBasedPricingDiscount(element));
      });
    }
  }

  pushVendorDiscount(element: any): FormGroup {
    return this.fb.group({
        id: element.id,
        partId: element.partId,
        qty: element.qty,
        discount: element.discount,
        vendorCost: element.vendorCost,
        isActive: element.isActive,
        createdDate: element.createdDate,
        modifiedDate: element.modifiedDate,
        createdBy: element.createdBy,
        modifiedBy: element.modifiedBy,
        companyId: element.companyId,
        minQty: element.minQty,
        discountType: element.discountType,
        description: element.description
    });
  }

  pushVolPricingDiscount(element: any): FormGroup {
    return this.fb.group({
        id: element.id,
        partId: element.partId,
        minQty: element.minQty,
        qty: element.qty,
        discountType: element.discountType,
        discount: element.discount,
        customerCost: element.customerCost,
        description: element.description,
        isActive: element.isActive,
        createdDate: element.createdDate,
        modifiedDate: element.modifiedDate,
        createdBy: element.createdBy,
        modifiedBy: element.modifiedBy,
        companyId: element.companyId,
        originalPartPrice: element.originalPartPrice,
        wordPressId: element.wordPressId
    });
  }

  pushRoleBasedPricingDiscount(element: any): FormGroup{
    return this.fb.group({
      id: element.id,
      partId: element.partId,
      companyId: element.companyId,
      discountType: element.discountType,
      discountValue: element.discountValue,
      customerType: element.customerType,
      customerValue: element.customerValue,
      cost: element.cost,
      description: element.description,
      isActive: element.isActive,
      createdDate: element.createdDate,
      modifiedDate: element.modifiedDate,
      createdBy: element.createdBy,
      modifiedBy: element.modifiedBy,
      originalPartPrice: element.originalPartPrice,
      wordPressId: element.wordPressId
  });
  }

  Back() {
    this.router.navigate(['/part-list']);
  }
  Cancel() {
  }
  Save() {
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  GetInventoryType() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.invTypeList = data.data;
      }
    )
  }

  GetAllCategories() {
    this.sharedService.GetDropdownCategoryHierarchyModel(this.companyId).subscribe(
      (data : any) => {
        this.programs = data.dataList;
      });
  }

  OnParentCatChange(value: any): void {

  }

  GetTrackingList(){
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if(data.length>0){
          this.trackingList = data;
        }
      }
    )
  }

  onVendorInput(value: any) {
    this.filteredVendors = [];
    if(this.companyId>0 && this.companyId != null && this.companyId != undefined){
      this.vendorService.GetAllVendor(this.companyId, value).subscribe(
        (data: any) => {
            let vendors = data.dataList;
            //old
            let arr = vendors.filter(option =>
              option.vendorName.toLowerCase().includes(value.toLowerCase())
            )
            this.filteredVendors = arr;
        }
      )
    }
  }

  getVendor(obj: any) {
    this.form.patchValue({
      vendorId: obj.id,
      vendorName: obj.vendorName
    });
  }

  AddVendor() {
    this.OpenDialogWithoutRef();
  }

  OpenDialogWithoutRef() {
    this.dialog.open(this.vendorDialog, {
      height: '400px !important',
      width: '200px !important'
    });
  }

  CreateVendorForm() {
    this.vendorFormCreate = new FormGroup({
      vendorName: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      phoneNumber: new FormControl('', [Validators.minLength(11)]),
      addressLine1: new FormControl(''),
      stateId: new FormControl(''),
      CountryId: new FormControl(''),
      zipCode: new FormControl(''),
      //VendorHash: new FormControl(''),
      VendorHash: new FormControl(Math.floor(1000 + Math.random() * 9000).toString()),
      cityId: new FormControl(''),
      companyId: new FormControl(this.companyId)
    })
  }

  GetCountryId($event) {
    this.sharedService.getAllState($event.value).subscribe((data: any) => {
      this.getStateFromCountry = data.dataList;
    })
  }

  AddNewVendor() {
    this.vendorService.addvendor(this.vendorFormCreate.value).subscribe((data: any) => {
      if (data.statusCode == 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Vendor has been saved!",
          showConfirmButton: true
        });
        this.vendorFormCreate.reset();
        this.getVendor(Number(data.data));
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: data.errorMessage
        });
        this.vendorFormCreate.reset();
      }
    })
  }

}