import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms"

export function isNumberValidator(control: FormControl): { [s: string]: boolean } | null {
  const value = control.value;
  if (value === null || value === undefined || isNaN(value)) {
    return { 'isNumber': true };
  }
  return null;
}


export function greaterThanZeroValidator(control: FormControl): { [s: string]: boolean } | null {
  const value = control.value;
  if (value < 0.00001) {
    return { 'greaterThanZero': true };
  }
  return null;
}

export function compareMoveLocQtyData(control: AbstractControl): { [key: string]: any } | null {
  const value1 = ((control.getRawValue().avlLocQty == null || control.getRawValue().avlLocQty == undefined) ? 0 : control.getRawValue().avlLocQty);
  const value2 = ((control.getRawValue().moveLocQty == null || control.getRawValue().moveLocQty == undefined) ? 0 : control.getRawValue().moveLocQty);

  // if (!value1 || !value2) {
  //   return null;
  // }

  if (isNaN(value1) || isNaN(value2)) {
    return { 'isNumber': true };
  }

  if (Number(value1) < Number(value2)) {
    return { 'lessThanAvl': true };
  }

  return null;
}

export function compareMoveChannelQtyData(control: AbstractControl): { [key: string]: any } | null {
  const value1 = ((control.getRawValue().avlChannelQty == null || control.getRawValue().avlChannelQty == undefined) ? 0 : control.getRawValue().avlChannelQty);
  const value2 = ((control.getRawValue().moveChannelQty == null || control.getRawValue().moveChannelQty == undefined) ? 0 : control.getRawValue().moveChannelQty);

  // if (!value1 || !value2) {
  //   return null;
  // }

  if (isNaN(value1) || isNaN(value2)) {
    return { 'isNumber': true };
  }

  if (Number(value1) < Number(value2)) {
    return { 'lessThanAvl': true };
  }

  return null;
}


