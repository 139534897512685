<section class="content-section-2" id="new-layout">
    <div class="loading lodingtext" *ngIf="loader">
        <!-- <p>{{lodingtext}}</p> -->
    </div>
    <!-- --------------Bredcumps start here------------- -->
    <div class="top-bredcumps">
        <div class="cstmbreadcum paddingmng-right">
            <div class="page_title_cstms">
                <ul class="link_current">
                    <li>
                        <a type="button" class="po-list-bredcumps">Purchase Order List</a>
                    </li>
                </ul>
            </div>
            <div class="top-button d-flex justify-content-between align-items-center gap-3">

            </div>
        </div>
    </div>
    <!-- --------------Bredcumps end here------------- -->
    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section">
                <!-- --------------------top-section start here--------- -->
                <div class=" top-section mb-3">
                    <div class="left-section">
                        <form [formGroup]="formSearch" class="d-inline-block form-section">
                            <div class="row justify-content-center mb-3">
                                <div class="col-md-6">
                                    <div class="search_filter-alltype select-arrow-change-icon">
                                        <mat-select formControlName="selected" class="customInput">
                                            <mat-option value='All' selected>All</mat-option>
                                        </mat-select>
                                        <input type="text" formControlName="searchText" class="searchalltype"
                                            placeholder="Search by Purchase Order Number or Vendor Name"
                                            (input)="onSearch()">
                                        <button class="btnsearch_alltype" type="button" (click)="onSearch()">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Filter Type</mat-label>
                                        <mat-select formControlName="filterType">
                                            <mat-option *ngFor="let type of FilterData" [value]="type.id"
                                                style="width: 210px">
                                                {{type.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 mb-md-0 mb-sm-3 select-arrow-change-icon">
                                    <ng-container *ngIf="formSearch.value.filterType === 1">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Status</mat-label>
                                            <mat-select formControlName="status" multiple
                                                (selectionChange)=GetStatus($event)>
                                                <mat-option style="width: 227px" *ngFor="let data of statuslist"
                                                    [value]="data.statusId"
                                                    [disabled]="data.statusId == 17">{{data.statusName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="formSearch.value.filterType === 2">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Vendor</mat-label>
                                            <mat-select formControlName="vendor" multiple
                                                (selectionChange)=GetVendor($event)>
                                                <mat-option style="width: 227px" *ngFor="let type of vendors"
                                                    [value]="type.id">{{type.vendorName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="formSearch.value.filterType === 3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <span class="material-icons">
                                                search
                                            </span>
                                            <input class="w-100" formControlName="vendorValue" matInput type="text"
                                                autocomplete="off" placeholder="Search by Vendor Part Number"
                                                (input)="onInput($event)" #input>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="formSearch.value.filterType === 4">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Time Period</mat-label>
                                            <mat-select formControlName="timeperiod"
                                                (selectionChange)=GetDateValue($event)>
                                                <mat-option style="width: 227px" *ngFor="let item of DateFilterData"
                                                    [value]="item.id">{{item.value}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                                <div class="col-md-2 col-6 mb-sm-3 align-items-center"
                                    *ngIf="formSearch.value.filterType === 4">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>From</mat-label>
                                        <input matInput [min]="minFromDate" [max]="maxFromDate"
                                            [matDatepicker]="frompicker" (dateInput)="fromDateChange('input', $event)"
                                            formControlName="prevdate">
                                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                                        <mat-datepicker #frompicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 col-6 mb-sm-3" *ngIf="formSearch.value.filterType === 4">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>To</mat-label>
                                        <input matInput [min]="minToDate" [max]="maxToDate" [matDatepicker]="topicker"
                                            (dateInput)="toDateChange('input', $event)" formControlName="curdate">
                                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                                        <mat-datepicker #topicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col-12  d-flex align-items-center justify-content-end">
                                    <button mat-button color="primary" class="btn cancel-btn update-btn me-2"
                                        (click)="getFilterStatusData()">Apply</button>

                                        <div class="new-order-btn">
                                            <button mat-raised (click)="createNewOrder()"
                                                class="save-button quick-bg d-flex align-items-center btn save-btn update-btn">
                                                <mat-icon class="mr-1 text-light fs-20">add</mat-icon>
                                                <span class="text-light quick-order-text">New Order</span>
                                            </button>
                                        </div>
                                </div>
                               
                                <!-- <div class="col-md-1 col-6 d-flex align-items-center">
                                    <button mat-button color="primary" class="btn cancel-btn update-btn"
                                        (click)="getFilterStatusData()">Apply</button>
                                </div>
                                <div class="right-side col-md-1 col-6">
                                    <div class="new-order-btn">
                                        <button mat-raised (click)="createNewOrder()"
                                            class="save-button quick-bg d-flex align-items-center btn save-btn update-btn">
                                            <mat-icon class="mr-1 text-light fs-20">add</mat-icon>
                                            <span class="text-light quick-order-text">New Order</span>
                                        </button>
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>
                </div>
                <!-- --------------------top-section end here--------- -->
                <!-- ------------------table section--------------- -->

                <mat-table [dataSource]="dataSource" (scroll)="onScroll($event)"
                    class="table-material table-responsive">
                    <ng-container matColumnDef="menu">
                        <mat-header-cell *matHeaderCellDef> Menu </mat-header-cell>
                        <mat-cell *matCellDef="let row; let i = index;" data-label="Menu"> <mat-icon
                                class="icon_fix">more_vert</mat-icon></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="issuedDate">
                        <mat-header-cell *matHeaderCellDef> Issued Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Issued Date"> {{row.issueDate == null ||
                            row.issueDate == '' ? 'N/A' : row.issueDate | date:
                            "MMM-dd-yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="poNumber">
                        <mat-header-cell *matHeaderCellDef> PO Number </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="PO Number"> {{row.poNumber}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vendorName">
                        <mat-header-cell *matHeaderCellDef> Vendor Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Vendor Name">
                            {{row.vendorName}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="contactName">
                        <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Contact Name">
                            {{row.contactName}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Status">
                            {{GetStatusKey(row.status)}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="EstArrDate">
                        <mat-header-cell *matHeaderCellDef> Est.<br>Arrival Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Est. Arrival Date"> {{row.estArrDate |
                            date:"MMM-dd-yyyy" }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="payterms">
                        <mat-header-cell *matHeaderCellDef> Payment Terms </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Payment Terms">
                            {{GetTermKey(row.paymentTerm)}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fobpoint">
                        <mat-header-cell *matHeaderCellDef> FOB Point </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="FOB Point">
                            {{GetFOBKey(row.fobPoint)}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="shippingTerms">
                        <mat-header-cell *matHeaderCellDef> Shipping Terms </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="Shipping Terms">
                            {{GetShippingTermKey(row.shippingTerm)}}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                        class="header-style table-responsive-header"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;let i= index;"
                        (click)="GetPOById(row)"></mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</section>