import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';

@Component({
  selector: 'app-parts-report',
  templateUrl: './parts-report.component.html',
  styleUrls: ['./parts-report.component.css']
})
export class PartsReportComponent implements OnInit{
partListColumn:string [] = this.constants.partList;
currentPage: string = 'Part Report';

constructor(
  private constants: ConstantsService,
){
}

ngOnInit(): void {
  
}

}
