import { Component, Input, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-part-tiles',
  templateUrl: './part-tiles.component.html',
  styleUrls: ['./part-tiles.component.css','../../inventory-details/inventory-details.component.css']
})
export class PartTilesComponent implements OnInit {
  @Input() companyId: number = 0;
  OnHand: any = 0;
  OnHandTotal: any = 0.00;
  Available: any = 0;
  AvailableTotal: any = 0.00;
  Committed: any = 0;
  CommittedTotal: any = 0.00;
  BackOrdered: any = 0;
  BackOrderedTotal: any = 0.00;

  constructor(private inventoryService: InventoryService, private constants: ConstantsService){

  }

  ngOnInit(): void {
    this.CalculateInventoryQuantity(this.companyId);
  }

  CalculateInventoryQuantity(companyId: number) {
    this.inventoryService.GetCalculateInventoryQuantity(companyId).subscribe(
      (data: any) => {
        const response = data.data;
        if (response != null) {
          this.OnHand = response.onHand;
          this.OnHandTotal = response.onHandTotal;
          this.Available = response.available;
          this.AvailableTotal = response.availableTotal;
          this.Committed = response.committed;
          this.CommittedTotal = response.committedTotal;
          this.BackOrdered = response.backOrdered;
          this.BackOrderedTotal = response.backOrderedTotal;
        }
      },
      (error) => {
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
