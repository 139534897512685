import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../constants.service';
import { FormBuilder } from '@angular/forms';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-location-ware-house',
  templateUrl: './location-ware-house.component.html',
  styleUrls: ['./location-ware-house.component.css']
})
export class LocationWareHouseComponent implements OnInit {
  loader: boolean = false;
  IsLocationList: boolean = false;
  LocationDS: any;
  LocationType: any = [];
  LocationDetails: any = [];
  StatusList = [{ type: 'Active', value: true }, { type: 'In-Active', value: false }];
  FilterType: string = 'LocationGenerator'

  constructor(private fb: FormBuilder,
    public constants: ConstantsService,
    public sharedService: SharedService,
  ) {

  }
  ngOnInit(): void {
    this.GetLocationList();
    this.GetLocationTypeList();
  }
  GetLocationList() {
    this.loader = true;
    this.sharedService.getLocationDetails(19, '')
      .subscribe({
        next: (data: any) => {
          this.LocationDS = data.dataList
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetLocationTypeList() {
    this.sharedService.GetMasterLocationTypeList()
      .subscribe({
        next: (data: any) => {
          this.LocationType = data.data;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetLocationDetails() {
    this.loader = true;
    this.sharedService.getLocationLevelList(19, 2, 'Shipping')
      .subscribe({
        next: (data: any) => {
          this.LocationDetails = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  selectedRow(row: any) {
    this.IsLocationList = true;
  }
  ChangeType(value: any) {
    this.FilterType = value
  }
  Cancel() {

  }
  Save() {

  }
  BackToList() {
    this.IsLocationList = false;
    this.FilterType = 'LocationGenerator';
  }
  DeleteWareHouseLocation(value: any) {

  }
  GenerateLocation() {
    this.FilterType = 'LocationDetails';
    this.GetLocationDetails();
  }
}
