import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { throttleTime } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-part-fly-out',
  templateUrl: './order-part-fly-out.component.html',
  styleUrls: ['./order-part-fly-out.component.css']
})
export class OrderPartFlyOutComponent implements OnInit {
  loader: boolean = false;
  partsForm: FormGroup;
  @Output() IsSalesOrderFlyOut = new EventEmitter<any>();
  @Input() CompanyId: Number
  @Input() pageName: String
  PartsList: any = [];
  SelectedList: any = [];
  @Input() VendorId: any;
  @Input() partIds: any
  start: number = 200;
  IsViewSelected: boolean = false;
  SOPartList: any = [];
  POPartList: any = [];

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    private constants: ConstantsService,
    private POService: PurchaseOrderService,
    private salesOrderService: SalesOrderService,
  ) {
    this.partsForm = new FormGroup({
      parts: this.fb.array([]),
    });
  }
  ngOnInit(): void {
    if (this.pageName == 'SalesOrder') {
      this.getMasterTableData(true, '', [])
    } else if (this.pageName == 'PurchaseOrder') {
      this.getVendorPartPO(this.start, true, '', []);
    }
  }
  GetPartsList() {
    this.parts.clear();
    this.PartsList.forEach((element: any) => {
      this.parts.push(new FormGroup({
        Id: new FormControl(element.id == undefined ? 0 : element.id),
        PartId: new FormControl(element.partId),
        Select: new FormControl({ value: this.FillCheckBox(element.partId), disabled: this.partIds.filter(x => x.PartId == element.partId).length > 0 ? true : false }),
        Quantity: new FormControl(this.FillQnty(element.partId)),
        partNumber: new FormControl(element.partNumber == undefined ? element.partNo : element.partNumber),
        partName: new FormControl(element.partName == undefined ? element.name : element.partName),
        onHand: new FormControl(element.onHandInventory),
        available: new FormControl(element.availableInventory),
        bo: new FormControl(element.backOrderedInventory),
        uom: new FormControl(element.dimentionType),
        unitPrice: new FormControl(element.cost)
      }))
    });
    this.loader = false;
  }
  FillQnty(Id: number) {
    let Qnty = undefined;
    if (this.partIds.filter(x => x.PartId == Id).length > 0) {
      if (this.pageName == 'SalesOrder') {
        Qnty = this.partIds.filter(x => x.PartId == Id)[0].Quantity;
      } else if (this.pageName == 'PurchaseOrder') {
        Qnty = this.partIds.filter(x => x.PartId == Id)[0].quantity;
      }
    } else if (this.SelectedList.filter(x => x.PartId == Id).length > 0) {
      Qnty = this.SelectedList.filter(x => x.PartId == Id)[0].Quantity
    }
    return Qnty;
  }
  FillCheckBox(Id: number) {
    let IsChecked = false;
    if (this.partIds.filter(x => x.PartId == Id).length > 0) {
      IsChecked = true;
    } else if (this.SelectedList.filter(x => x.PartId == Id).length > 0) {
      IsChecked = true;
    }
    return IsChecked;
  }
  get parts(): FormArray {
    return this.partsForm.get('parts') as FormArray
  }
  ChangePartQunty(event: any, i: number) {
    if (event.target.checked) {
      this.parts.value[i].Quantity = 1;
      this.parts.controls[i].markAsUntouched()
      this.partsForm.patchValue({ parts: this.parts.value })
      this.SelecetdList(i, 'S');
    } else {
      this.parts.value[i].Quantity = undefined;
      this.parts.controls[i].markAsUntouched()
      this.partsForm.patchValue({ parts: this.parts.value })
      this.SelecetdList(i, 'U');
    }
  }
  ChangeQnty(value: any, i: number) {
    if (value.value != '' && Number(value.value) > 0) {
      this.parts.value[i].Quantity = Number(value.value);
      this.parts.value[i].Select = true;
      this.partsForm.patchValue({ parts: this.parts.value })
      this.SelecetdList(i, 'S');
    } else {
      this.parts.value[i].Quantity = undefined;
      this.parts.value[i].Select = false;
      this.partsForm.patchValue({ parts: this.parts.value })
      this.SelecetdList(i, 'U');
    }
  }

  SelecetdList(i: number, type: string) {
    if (this.SelectedList.filter(x => x.PartId == this.parts.value[i].PartId && this.parts.value[i].Select == true).length == 0 && type == 'S') {
      this.SelectedList.push(this.parts.value[i]);
      this.pageName == 'SalesOrder' ? this.GetSOPartList(this.parts.value[i].PartId) : this.GetPOPartList(this.parts.value[i].PartId);
    } else if (this.SelectedList.filter(x => x.PartId == this.parts.value[i].PartId && this.parts.value[i].Select == true).length > 0 && type == 'S') {
      this.SelectedList.filter(x => x.PartId == this.parts.value[i].PartId)[0].Quantity = this.parts.value[i].Quantity;
    }
    if (this.SelectedList.filter(x => x.PartId == this.parts.value[i].PartId && this.parts.value[i].Select == false).length > 0 && type == 'U') {
      this.SelectedList = this.SelectedList.filter(x => x.PartId != this.parts.value[i].PartId);
      this.pageName == 'SalesOrder' ? this.RemoveSOPartList(this.parts.value[i].PartId) : this.RemovePOPartList(this.parts.value[i].PartId);
      this.IsViewSelected == true ? this.ViewSelected(true) : false;
    }
  }
  GetSOPartList(partId: Number) {
    this.SOPartList = this.SOPartList.concat(this.PartsList.filter(x => x.partId == partId));
    this.SOPartList = this.removeDuplicates(this.SOPartList, "partId")
  }
  RemoveSOPartList(partId: Number) {
    this.SOPartList = this.SOPartList.filter(x => x.partId != partId);
    this.SOPartList = this.removeDuplicates(this.SOPartList, "partId")
  }
  GetPOPartList(partId: Number) {
    this.POPartList = this.POPartList.concat(this.PartsList.filter(x => x.partId == partId));
    this.POPartList = this.removeDuplicates(this.POPartList, "partId")
  }
  RemovePOPartList(partId: Number) {
    this.POPartList = this.POPartList.filter(x => x.partId != partId);
    this.POPartList = this.removeDuplicates(this.POPartList, "partId")
  }
  ViewSelected(value: any) {
    this.IsViewSelected = value;
    if (value) {
      this.SelectedList = this.SelectedList.length > 0 ? this.removeDuplicates(this.SelectedList, "PartId") : [];
      this.parts.clear();
      this.SelectedList.forEach(p => {
        this.parts.push(new FormGroup({
          Id: new FormControl(p.Id),
          PartId: new FormControl(p.PartId),
          Select: new FormControl(true),
          Quantity: new FormControl(p.Quantity),
          partNumber: new FormControl(p.partNumber),
          partName: new FormControl(p.partName),
          onHand: new FormControl(p.onHand),
          available: new FormControl(p.available),
          bo: new FormControl(p.bo),
          uom: new FormControl(p.uom),
          unitPrice: new FormControl(p.unitPrice)
        }))
      });
    } else {
      this.parts.clear();
      if (this.pageName == 'SalesOrder') {
        this.PartsList.forEach(i => {
          this.parts.push(new FormGroup({
            Id: new FormControl(i.id),
            PartId: new FormControl(i.partId),
            Select: new FormControl({ value: this.FillCheckBox(i.partId), disabled: this.partIds.filter(x => x.PartId == i.partId).length > 0 ? true : false }),
            Quantity: new FormControl(this.FillQnty(i.partId)),
            partNumber: new FormControl(i.partNumber),
            partName: new FormControl(i.partName),
            onHand: new FormControl(i.onHandInventory),
            available: new FormControl(i.availableInventory),
            bo: new FormControl(i.backOrderedInventory),
            uom: new FormControl(i.dimentionType),
            unitPrice: new FormControl(i.cost)
          }))
        });
      } else if (this.pageName == 'PurchaseOrder') {
        this.GetPartsList();
      }
    }
  }

  ClosePartsFlyOut() {
    this.IsSalesOrderFlyOut.emit({ PartsList: [], IsAddPartFlyOut: false });
  }

  SaveFlyOut() {
    let result = [];
    if (this.pageName == 'SalesOrder') {
      result = this.SOPartList.filter(item => this.SelectedList.some(y => y.PartId === item.partId && y.Select));
      result.forEach(r => {
        r.quantity = this.SelectedList.filter(x => x.PartId == r.partId)[0].Quantity;
      });
      this.GetSelectedPartsForSO(result);
    } else if (this.pageName == 'PurchaseOrder') {
      result = this.POPartList.filter(item => this.SelectedList.some(y => y.PartId === item.partId && y.Select));
      result.forEach(r => {
        r.quantity = this.SelectedList.filter(x => x.PartId == r.partId)[0].Quantity;
      });
      this.IsSalesOrderFlyOut.emit({ PartsList: result, IsAddPartFlyOut: false });
    }
  }

  CategoryIds(value) {
    if (this.pageName == 'SalesOrder') {
      this.getMasterTableData(true, '', value)
    } else if (this.pageName == 'PurchaseOrder') {
      this.getVendorPartPO(this.start, true, '', value);
    }
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  // // for lazyload first table
  SOPartListScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      if (this.pageName == 'SalesOrder') {
        this.getMasterTableData(true, '', []);
      } else if (this.pageName == 'PurchaseOrder') {
        this.getVendorPartPO(this.start, true, '', []);
      }
    }
  }
  getMasterTableData(isUpdate, partName: string, PartCatIds: []) {
    this.loader = this.start == 200 ? true : false;
    let partReq = {
      companyId: Number(this.CompanyId),
      vendorId: 0,
      partname: partName,
      offsetNo: 0,
      pageSize: this.start,
      PartCatIds: PartCatIds
    }
    this.salesOrderService.getAllParts(partReq).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.PartsList = data.dataList;
        this.PartsList = this.removeDuplicates(this.PartsList, "partId")
        this.GetPartsList();
        if (isUpdate) {
          this.updateIndex();
        }
      },
      error: error => {
        this.loader = false;
        this.SwalModal(this.constants.APIError, error);
      },
      complete: () => {
        this.loader = false;
      }
    });
  }
  getVendorPartPO(start, isUpdate, partName: string, PartCatIds: []) {
    this.loader = this.start == 200 ? true : false;
    let partReq = {
      companyId: Number(this.CompanyId),
      partname: partName,
      vendorId: this.VendorId,
      offsetNo: 0,
      pageSize: this.start,
      PartCatIds: PartCatIds
    }
    this.POService.GetPOPartListByVendor(partReq).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.PartsList = data.data;
        this.PartsList = this.removeDuplicates(this.PartsList, "partId")
        if (isUpdate) {
          this.updateIndex();
        }
        this.GetPartsList();
      },
      error: error => {
        this.loader = false;
        this.SwalModal(this.constants.APIError, error);
      },
      complete: () => {
        this.loader = false;
      }
    });
  }

  updateIndex() {
    this.start = this.PartsList.length + 100;
  }

  SwalModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  Search(value: any) {
    if (this.pageName == 'SalesOrder') {
      this.getMasterTableData(true, value != '' ? value.trim() : '', [])
    } else if (this.pageName == 'PurchaseOrder') {
      this.getVendorPartPO(0, true, value != '' ? value.trim() : '', [])
    }
  }
  GetSelectedPartsForSO(result) {
    this.loader = true;
    this.salesOrderService.GetSelectedPartsForSO(result).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.IsSalesOrderFlyOut.emit({ PartsList: data.dataList, IsAddPartFlyOut: false });
      },
      error: error => {
        this.loader = false;
        this.SwalModal(this.constants.APIError, error);
      },
      complete: () => {
        this.loader = false;
      }
    });
  }
  GetUOM(Id: number): string {
    return this.constants.UnitLength.filter(u => u.id == Id).length > 0 ? this.constants.UnitLength.filter(u => u.id == Id)[0].name : 'in';
  }
}
