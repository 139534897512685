<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<section class="content-section-2">
    <div class="top-bredcumps">
        <div class="cstmbreadcum paddingmng-right">
            <div class="page_title_cstms">
                <ul class="link_current">
                    <li>
                        <span>Inventory Report</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content-section-1">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2">
                    <!-- [formGroup]="reportFormforSO" -->
                    <form>
                        <!-- <div class="left-img">
                            <img src="http://localhost:4200/assets/image/logo.jpg" alt=""> 
                            <h5 class="mt-2">mark@911vehicle.com</h5>
                            <h5>911vehicle.com</h5>
                        </div> -->
                        <div class="p-2 blue-txt text-center shadow">
                            <h4 style="margin:0px !important; line-height: 34px;">Total #Inventory's {{inventoryLength}}</h4>
                        </div>
                        <div class="mt-2">
                            <mat-card class="example-card map-box-custom" appearance="outlined" style="height: 222px;">
                                <mat-card-content style="padding: 0px 5px;">
                                    <div class="form-group dflex">
                                        <input class="form-control-input" type="radio" name="allDateRange" checked="checked"
                                            id=allInv value="" (click)="GetAllInventory()">
                                        <label class="form-control-label">All Inventory</label>
                                    </div>
                                    <div class="form-group dflex mb-0">
                                        <input class="form-control-input" type="radio" name="allDateRange" id=dateInv
                                            value="" (click)="GetInvByDate()">
                                        <label class="form-control-label">Inventory using Date</label>
                                    </div>
                                    <div class="form-group dflex mb-0">
                                        <input class="form-control-input" type="radio" name="allDateRange" id=locationInv
                                            value="" (click)="GetInvByLoc()">
                                        <label class="form-control-label">Inventory using Location</label>
                                    </div>
                                    <div class="d-flex mt-2 flex-column" *ngIf="isShowLocation">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <input type="text" (input)="GetPartName($event.target.value)"
                                                style="margin: 4px;" matInput class="customInput mr-1">
                                        </mat-form-field>
                                    </div>
    
                                    <div class="d-flex mt-2 flex-column" *ngIf="isShowDate">
                                        <input type="date" style="margin: 4px;" (input)="GetStartDate($event.target.value)"
                                            matInput class="customInput mr-1">
                                        <input type="date" style="margin: 4px;" (input)="GetEndDate($event.target.value)"
                                            matInput class="customInput mr-1">
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined"
                            style="height: 57px; padding-top: 8px;">
                            <mat-card-content style="padding: 0;">
                                <button class="form-control" (click)="GetInventoryData()">Search <span> <i
                                            class="fas fa-search cpointer"></i></span> </button>
                            </mat-card-content>
                        </mat-card>
    
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined"
                            style="height: 76px; padding-top: 8px;">
                            <mat-card-content style="padding: 0;">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Action</mat-label>
                                    <mat-select>
                                        <mat-option *ngFor="let item of PartAction" (click)="GetAction(item.id)"
                                            [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                    </form>
                </div>
    
                <div class="col-md-10 right-blk">
                    <div class="row three-map-blk">
                        <div class="col-md-4">
                            <mat-card class="example-card map-box-custom" appearance="outlined">
                                <mat-card-header>
                                    <mat-card-title>Labor Revenue and Part Revenue</mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-md-4">
                            <mat-card class="example-card map-box-custom" appearance="outlined">
                                <mat-card-header>
                                    <mat-card-title>GrossProfit</mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-md-4">
                            <mat-card class="example-card map-box-custom" appearance="outlined">
                                <mat-card-header>
                                    <mat-card-title>Count Of</mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                </mat-card-content>
                            </mat-card>
                        </div>
    
                        <!-- <label style="margin-left: 500px;">Inventory Report</label> -->
                        <label style="width:100%;text-align: center;">Inventory Report</label>
                        <div class="table-scroll mat-elevation-z8 master-table box-shadow-none">
                            <mat-table [dataSource]="invMasterList" class="table-inventory">
                                <ng-container matColumnDef="item">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element; let i = index;" data-label="Item"
                                        class="inventory-mat-cell"> {{i+1}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="itemNumber">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item Number
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Item Number" class="inventory-mat-cell">
                                        {{element.itemNumber}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="itemDescription">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item Description
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Item Description"
                                        class="inventory-mat-cell">
                                        {{element.itemDescription}} </mat-cell>
                                </ng-container>
                                <!-- <ng-container matColumnDef="uom">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> UOM
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="UOM" class="inventory-mat-cell">
                                        {{element.uom}}
                                    </mat-cell>
                                </ng-container> -->
                                <ng-container matColumnDef="inventoryType">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Inventory Type
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Inventory Type"
                                        class="inventory-mat-cell">
                                        {{GetInventoryTypeValue(element.inventoryType)}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="onHand">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> On Hand
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="On Hand" class="inventory-mat-cell">
                                        {{element.onHand}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="available">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Available
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Available" class="inventory-mat-cell">
                                        {{element.available}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="committed">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Committed
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Committed" class="inventory-mat-cell">
                                        {{element.committed}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="totalCost">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Total Cost
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Total cost" class="inventory-mat-cell">
                                        {{element.totalCost | currency}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="backOrdered">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Back Ordered
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Back Ordered"
                                        class="inventory-mat-cell">
                                        {{element.backOrdered}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="boAvailable">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> B/O Available
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="B/O Available"
                                        class="inventory-mat-cell">
                                        {{element.boAvailable}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="boCommitted">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> B/O Committed
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="B/O Committed"
                                        class="inventory-mat-cell">
                                        {{element.boCommitted}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="boTotalCost">
                                    <mat-header-cell *matHeaderCellDef class="inventory-table-header"> B/O TotalCost
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="B/O TotalCost"
                                        class="inventory-mat-cell">
                                        {{element.boTotalCost | currency}} </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="invListColumns sticky: true"
                                    class="header-style inventory-header"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: invListColumns;let i= index;"
                                    class="material-inventory-row"></mat-row>
                            </mat-table>
                        </div>
                        <!-- *ngIf="purchageorderList!=null" -->
                        <div *ngIf="invMasterList.length > 0" class="table-foot-info" style="height:40px">
                            <tr>
                                <td colspan="8"><span style="margin-left: 22px; margin-top: 4px;">Total:</span></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="padding: 0;"><span class="InvReport">{{invMasterList[0].sumTotalCost ==
                                        0 ? '$0.00':invMasterList[0].sumTotalCost |currency}}</span> </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="padding: 0;"><span class="InvReportTotal">{{invMasterList[0].sumBOTotalCost==
                                        0 ? '$0.00':invMasterList[0].sumBOTotalCost |currency}}</span> </td>
                            </tr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>