import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assemblies-list',
  templateUrl: './assemblies-list.component.html',
  styleUrls: ['./assemblies-list.component.css']
})
export class AssembliesListComponent implements OnInit {
  loader: boolean = false;
  previousPage: string = '';
  currentPage: string = 'Assemblies List';

  constructor() {

  }
  ngOnInit(): void {

  }
}
