<div class="container-fluid">
  <div class="loading" *ngIf="loader">Loading&#8230;</div>
  <div class="row">
    <div class="login-body">
      <div class="login-content shadow">
        <div  class="text-center">
          <span class="text-danger mb-3" *ngIf="errorMessage.length>0">
            {{errorMessage}}
          </span>
          <h2 class="mb-4">Login</h2>
        </div>

        <form [formGroup]="loginForm">
          <div class="input-group mb-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="userId" placeholder="Enter email*" />
            </mat-form-field>
          </div>
          <div class="input-group mb-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" type="password" placeholder="Enter Password"
                placeholder="Password*" />
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-7">
              <!-- <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label f_size_12" for="exampleCheck1">Keep me logged in</label>
              </div> -->
            </div>
            <div class="col-5 text-right f_size_12">
              <a (click)="opemPasswordPopUp(content)">Forgot Password </a>
            </div>
          </div>
          <button type="submit" (click)="logIn()" class="btn red-sq-btn m-tb-40">Login</button>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" id='forgasswordotP'>
  <div class="modal-header blue-bg">
    <h5 class="text-white font-weight-normal ">Forgot Password </h5>
    <button class="close btn" aria-label="Close" (click)="d('Cross click')" #closeBtn (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">

      <p class="mt-1">{{emailDetails}}</p>
      <div class="form-group required">
        <input type="text" class="form-control1 customInput EmailValidation" placeholder="Enter Email*" required
          autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" maxlength="50" formControlName="emailId"
          (keypress)="RemovedEmailMessage($event.target)">
        <div *ngIf="((form.controls.emailId.value=='' || form.controls.emailId.value == undefined) && (validation))"
          class="text-danger input-error">
          Enter Email.
        </div>
        <div
          *ngIf="((!form.controls.emailId.valid && form.controls.emailId.value!='' && form.controls.emailId.value!=undefined)&&(validation))"
          class="text-danger input-error">
          Enter valid Email.</div>
        <div *ngIf="((UserDoseNotExist) && (validation))" class="text-danger input-error">
          User does not Exist.</div>


      </div>
    </form>

  </div>

  <div class="modal-footer text-center">
    <button type="button" class="btn roundBtn green-bg" (click)="sendEmail();">Send
      Email</button>
    <button type="button" class="btn roundBtn red-bg" id="close-btn" (click)="c('Close click')">Close</button>
  </div>

</ng-template>

<div class="modal" id="wrong_msg">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/close.png" alt="">
          <h1>Alert</h1>
          <p>Something went wrong</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="msgitem()" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>